import React, { Component } from 'react';
import Quagga from 'quagga';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

class BarcodeScanner2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCameraLoaded: false,
      showError: false,
      errorMessage: '',
    };
    this.scannerRef = React.createRef();
    this.lineRef = React.createRef();
  }

  componentDidMount() {
    if (this.scannerRef.current) {
      Quagga.init({
        inputStream: {
          type: 'LiveStream',
          target: this.scannerRef.current,
          constraints: {
            width: 640,
            height: 480,
            facingMode: 'environment', // Utiliza la cámara trasera
          },
        },
        decoder: {
          readers: ['code_128_reader', 'ean_reader', 'ean_8_reader'], // Agrega los lectores necesarios
        },
        locate: true,
      }, (err) => {
        if (err) {
          console.error(err);
          return;
        }
        Quagga.start();
        this.setState({ isCameraLoaded: true });
      });

      Quagga.onDetected(this.handleDetected);
    }
  }

  componentWillUnmount() {
    Quagga.offDetected(this.handleDetected);
    Quagga.stop();
  }

  handleDetected = (data) => {
    const code = data.codeResult.code;
    
    if (/^\d{5}$/.test(code) || /^\d{10}$/.test(code)) {
      this.props.setSerialNumberFromScan(data);
       // Hacer que el dispositivo vibre
       if (navigator.vibrate) {
        navigator.vibrate(200); // Vibrar por 200ms
      }
    } else {
      this.setState({ showError: true, errorMessage: 'Ese no es el número de serie del dispositivo' });
    }
  }

  handleClose = () => {
    this.setState({ showError: false, errorMessage: '' });
  }

  render() {
    return (
      <div>
        <div className="video-container">
          <div ref={this.scannerRef} />
          {this.state.isCameraLoaded && (
            <div className="scanner-line" ref={this.lineRef}></div>
          )}
        </div>
        <Snackbar
          open={this.state.showError}
          autoHideDuration={6000}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={this.handleClose} severity="error">
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default BarcodeScanner2;
