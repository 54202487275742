import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import config from './config';

import '../src/Environments/TranslateConfig';

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.apiGateway.REGION
  },
  Storage: {
  },
  API: {
    endpoints: [
      {
        name: "4GFlota",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
    ]
  }
});
ReactDOM.render(
  <Router>
     <App />
  </Router>,
  document.getElementById('root')
);
// serviceWorker.unregister();
