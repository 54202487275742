import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from "react-tiger-transition";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import IconButton from "@material-ui/core/IconButton";
import { withStyles } from '@material-ui/core/styles';

import Config from '../../config';

import DialogView from './Dialog';

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: 700,
        margin: "auto",
        marginTop: 25
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: "100%",
    },
    rootFormControl: {
        minWidth: "100%",
        '& .MuiFormHelperText-root': {
            marginTop: "0px !important"
        },
    },

    selectEmpty: {
        color: "#aaa"
    },
    rootButton: {
        color: "#32a852",
        padding: 7
    },
    rootButtonDelete: {
        color: "#eb4034",
        padding: 7
    },
    listStyles: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    },
    listFont: {
        '& .MuiListItemText-secondary': {
            fontSize: theme.typography.fontSize,
        },
        '& .MuiListItemText-primary': {
            fontSize: "16px !important"
        }
    },
    labelCheckBox: {
        '& .MuiFormControlLabel-label': {
            fontSize: "18px !important",
            color: "#505050"
        }
    }




});

class FormAddDeviceConfiguration extends Component {
    state = {
        valueParameter: null,
        valueNameFw: this.props.deviceConfigurationSelected !== "" ? this.props.deviceConfigurationSelected.Nombre : "",
        valueSelect: "",
        valueTextField: "",
        valueDeviceSelected: this.props.deviceConfigurationSelected !== "" ? this.props.deviceConfigurationSelected.deviceTypeId : -1,
        addParametersList: [],
        ParametersInfo: this.props.parametersDevicesConfiguration.length > 0 ? this.props.parametersDevicesConfiguration : [],
        parameterList: this.props.deviceConfigurationSelected !== "" ? this.props.deviceConfigurationSelected.listParameters.ParametersInfo : [],
        dialogViewShow: false,
        deviceName: "",
        required: false,
        parameterSend: "",
        dialogErrorParameterSend: false

    }

    componentDidMount() {
        if (this.props.deviceConfigurationSelected !== "") {
            let copyParametersInfo = [...this.props.parametersDevicesConfiguration];

            this.props.deviceConfigurationSelected.listParameters.ParametersInfo.forEach(element => {
                let index = copyParametersInfo.findIndex(item => item.data === element.Nombre);
                copyParametersInfo.splice(index, 1);
            });


            this.setState({
                ParametersInfo: copyParametersInfo
            })
        }
    }

    onHandleClickAddNewParameter() {
        const valueParameter = this.state.valueParameter;
        if (this.state.valueTextField !== "" && this.state.valueParameter !== null) {
            if (this.state.required === true && this.state.parameterSend === "") {
                this.setState({
                    dialogErrorParameterSend: true
                });
                return;
            } else {
                let copyParametersInfo = [...this.state.ParametersInfo];
                // let result = copyParametersInfo.filter(item => item.dataId === this.state.valueParameter);
                let newJsonParameterList = {
                    id: this.state.valueTextField,
                    Nombre: valueParameter.data,
                    Multiplicador: valueParameter.multiplier,
                    Unidad: valueParameter.unit,
                    Descripciones: [],
                    Obligatorio: this.state.required,
                    TramaEnvio: this.state.parameterSend
                }
                // console.log(result);
    
    
                let index = copyParametersInfo.findIndex(item => item.dataId === this.state.valueParameter.dataId);
                copyParametersInfo.splice(index, 1);
    
                this.setState({
                    ParametersInfo: copyParametersInfo,
                    parameterList: [...this.state.parameterList, newJsonParameterList],
                    valueTextField: "",
                    valueParameter: null
                });
            }
            
        }

    }

    handleChangeDeviceSelect(e) {

        let device = this.props.typesDevicesConfiguration.filter(item => item.deviceTypeId === e.target.value);
        this.setState({
            valueDeviceSelected: e.target.value,
            deviceName: device[0].deviceTypeName

        });
    }

    handleOnChangeSelect(e) {
        this.setState({
            valueParameter: e.target.value
        });
    }

    handleOnChangeTextField(e) {
        this.setState({
            valueTextField: e.target.value
        });
    }

    onHandleClickToDelete(nombre) {
        let copyParametersList = [...this.state.parameterList];
        let copyParametersInfo = [...this.state.ParametersInfo];
        // let result = copyParametersList.filter(item => item.id === id);

        let index = copyParametersList.findIndex(item => item.data === nombre);
        let result = copyParametersList.splice(index, 1);

        let jsonToPush = {
            data: result[0].Nombre,
            dataId: "",
            dataType: "",
            description: result[0].Descripciones,
            multiplier: result[0].Multiplicador,
            unit: result[0].Unidad,
        }

        // result[0].id = "";
        copyParametersInfo.push(jsonToPush);
        this.setState({
            parameterList: copyParametersList,
            ParametersInfo: copyParametersInfo
        });
    }

    async onHandleToSendParameters() {
        if (this.state.parameterList.length > 0) {
            let parameterList = [...this.state.parameterList]
            parameterList = { ParametersInfo: parameterList }
            let request = {
                DeviceTypeId: this.state.valueDeviceSelected,
                DeviceTypeName: this.state.deviceName,
                FirmwareVersion: this.state.valueNameFw,
                ParametersInfo: parameterList
            }
            request.ParametersInfo = JSON.stringify(request.ParametersInfo);


            const settings = {
                method: 'POST',
                headers: {
                    // 'accessToken': this.props.location.state.AccessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)

            };
            const fetchResponse = await fetch(`${Config.apiGateway.URL}Devices/Parameters`, settings);
            if (fetchResponse.ok) {
                this.props.getDataTopassConfigurationDevices("", "add");
            }
        } else {
            this.setState({
                dialogViewShow: true,
                textForDialog: "¡Debe añadir al menos un parámetro!"
            });
        }

    }

    async onHandleToUpdateParameters() {
        if (this.state.parameterList.length > 0) {
            // let device = this.props.typesDevicesConfiguration.filter(item => item.deviceTypeId === this.state.valueDeviceSelected);
            let parameterList = [...this.state.parameterList]
            parameterList = { ParametersInfo: parameterList }
            let request = {
                DeviceTypeId: this.state.valueDeviceSelected,
                // DeviceTypeName: device[0].deviceTypeName,
                FirmwareVersion: this.state.valueNameFw,
                ParametersInfo: parameterList
            }
            request.ParametersInfo = JSON.stringify(request.ParametersInfo);


            const settings = {
                method: 'PUT',
                headers: {
                    // 'accessToken': this.props.location.state.AccessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)

            };
            const fetchResponse = await fetch(`${Config.apiGateway.URL}Devices/Parameters`, settings);
            if (fetchResponse.ok) {
                this.props.getDataTopassConfigurationDevices("", "update");
            }
        } else {
            this.setState({
                dialogViewShow: true,
                textForDialog: "¡Debe añadir al menos un parámetro!"
            });
        }

    }

    onHandleChangevalueNameFw(e) {
        this.setState({
            valueNameFw: e.target.value
        });
    }

    handleOnclickAcceptDialogView = () => {
        // let copyRows = [...this.state.rows];
        this.setState({
            dialogViewShow: false
        });

        // let newRows = copyRows.filter(item => item.id !== this.state.selectedToDelete.id);
        // this.setState({
        //     dialogViewShow: false
        // }, () => {
        //     this.setState({
        //         rows: newRows,
        //         selectedToDelete: {},

        //     });
        // })
    }

    onCloseDialogView = () => {
        this.setState({
            dialogViewShow: false,

        });
    }

    cancelAddConfiguration = () => {
        this.props.getDataTopassConfigurationDevices("", "cancel");
    }

    handleOnChangeRequiredField = () => {
        this.setState({
            required: !this.state.required
        }, () => {
            if(this.state.required === false) {
                this.setState({
                    parameterSend: ""
                })
            }
        });
    }

    onHandleChangevalueParameterSend = (e) => {
        this.setState({
            parameterSend: e.target.value
        })
    }
    handleOnclickAcceptDialogErrorParameterSend = () => {
        this.setState({
            dialogErrorParameterSend: false
        })
    }

    validateDataToAddParameter() {
        let isValid = false;

        if (this.state.required === true && this.state.parameterSend === "") {
            this.setState({
                dialogErrorParameterSend: true
            });
            isValid = false;
        }

    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container justify="center" spacing={2}>
                    <Grid item md={6} lg={6} xs={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                style={{ textAlign: "left", fontSize: 16 }}
                                id="demo-simple-select-outlined-label"
                                value={this.state.valueDeviceSelected}
                                className={this.state.valueDeviceSelected === -1 ? classes.selectEmpty : ""}
                                disabled={this.props.deviceConfigurationSelected === "" ? false : true}
                                onChange={this.handleChangeDeviceSelect.bind(this)}
                            >

                                <MenuItem value={-1}>Seleccione el tipo de dispositivo</MenuItem>
                                {this.props.typesDevicesConfiguration.map((element, index) => {
                                    return (
                                        <MenuItem key={index} value={element.deviceTypeId}>{element.deviceTypeName}</MenuItem>
                                    )
                                })}

                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item md={6} lg={6} xs={12}>
                        <FormControl className={classes.rootFormControl} autoComplete="off">
                            <TextField id="outlined-basic" variant="outlined"
                                placeholder="Indique un nombre"
                                onChange={this.onHandleChangevalueNameFw.bind(this)}
                                value={this.state.valueNameFw}
                                disabled={this.props.deviceConfigurationSelected === "" ? false : true}
                            />
                        </FormControl>

                    </Grid>
                    {/* <Grid item xs={12}>
                        <Divider variant="middle"/>
                    </Grid> */}


                    <Grid item md={6} lg={6} xs={12}>
                        <FormControl variant="outlined" style={{ marginTop: 20 }} className={classes.rootFormControl} autoComplete="off">
                            <TextField id="outlined-basic" variant="outlined"
                                inputProps={{ inputMode: 'numeric' }}
                                placeholder="Indique un parámetro"
                                type="number"
                                onChange={this.handleOnChangeTextField.bind(this)}
                                value={this.state.valueTextField}

                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={6} lg={6} xs={12}>
                        <FormControl variant="outlined" style={{ marginTop: 20 }} className={classes.formControl}>
                            {/* <Select
                                style={{ textAlign: "left", fontSize: 16 }}

                                id="demo-simple-select-outlined-label"
                                value={this.state.valueParameter}
                                className={this.state.valueParameter === -1 ? classes.selectEmpty : ""}
                                onChange={this.handleOnChangeSelect.bind(this)}
                            >
                                <MenuItem style={{ color: "#aaa" }} value={-1}>Seleccione el parámetro a asociar</MenuItem>
                                {this.state.ParametersInfo.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.dataId}>{item.data}</MenuItem>
                                    )
                                })}
                            </Select> */}
                            <Autocomplete
                                id="combo-box-parameters"
                                options={this.state.ParametersInfo}
                                value={this.state.valueParameter === "" ? null : this.state.valueParameter}
                                renderOption={(option) => (
                                    <Typography style={{ fontSize: 14 }}>{option.data}</Typography>
                                )}
                                onChange={(event, newValue) => {
                                    this.setState({ valueParameter: newValue })
                                }}
                                noOptionsText={<Typography style={{ fontSize: 14 }}>No se han encontrado opciones</Typography>}
                                // getOptionLabel={(option) => option.deviceTypeName ? option.deviceTypeName:option}

                                getOptionLabel={(option) => option.data}
                                // inputValue={inputValue}
                                // onInputChange={(event, newInputValue) => {
                                //     setInputValue(newInputValue);
                                // }}
                                className={this.state.valueParameter === null ? classes.selectEmpty : ""}
                                style={{ textAlign: "left", fontSize: 16 }}
                                renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Seleccione el parámetro a asociar" />}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item md={12} lg={12} xs={12}>
                        <FormControlLabel
                            className={classes.labelCheckBox}
                            control={
                                <Checkbox
                                    checked={this.state.required}
                                    onChange={this.handleOnChangeRequiredField}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                            label="Obligatorio"
                        />

                    </Grid>
                    <Grid item md={12} lg={12} xs={12}>
                        <FormControl className={classes.rootFormControl} autoComplete="off">
                            <TextField id="outlined-basic" variant="outlined"
                                placeholder="Indique una trama de envío"
                                onChange={this.onHandleChangevalueParameterSend.bind(this)}
                                value={this.state.parameterSend}
                                disabled={!this.state.required}
                            />
                        </FormControl>

                    </Grid>


                    <Grid item xs={12}>
                        <div className="hr-theme-slash-2">
                            <div className="hr-line"></div>
                            <div className="hr-icon">
                                <IconButton onClick={this.onHandleClickAddNewParameter.bind(this)} className={classes.rootButton}>
                                    <AddCircleOutlineOutlinedIcon />
                                </IconButton>
                            </div>
                            <div className="hr-line"></div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <div className={classes.listStyles}>
                            <List dense disablePadding>
                                {this.state.parameterList.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ListItem className={classes.listFont}>

                                                <ListItemText

                                                    primary={item.Nombre + " - " + item.id}
                                                    secondary={
                                                        <React.Fragment>

                                                            {item.Obligatorio === true ? "Si — " + item.TramaEnvio : "No"}
                                                        </React.Fragment>
                                                    }
                                                />

                                                <ListItemSecondaryAction>
                                                    <IconButton onClick={this.onHandleClickToDelete.bind(this, item.Nombre)} edge="end" aria-label="delete">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider />
                                        </React.Fragment>

                                    )
                                })}

                            </List>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Link to="/dashboard/configuracion-dispositivos/" transition='glide-right'>
                            <Button
                                style={{ width: "100%", height: 52, fontSize: 13 }}
                                variant="outlined"
                                color={"secondary"}
                                size="medium"
                                onClick={this.cancelAddConfiguration.bind(this)}
                            >
                                <b>Cancelar</b>
                            </Button>
                        </Link>

                    </Grid>


                    <Grid item xs={6}>
                        {this.props.deviceConfigurationSelected === "" ?
                            this.state.parameterList.length <= 0 && this.state.valueNameFw === null || this.state.valueNameFw === "" && this.state.valueNameFw === null || this.state.valueNameFw === "" ?
                                <Button
                                    style={{ width: "100%", height: 52, fontSize: 13 }}
                                    variant="outlined"
                                    color={"primary"}
                                    size="medium"
                                    disabled={this.state.parameterList.length > 0 && this.state.valueNameFw !== "" && this.state.valueDeviceSelected !== "" ? false : true}
                                    // style={{ marginLeft: 10 }}
                                    onClick={this.onHandleToSendParameters.bind(this)}
                                >
                                    <b>Aceptar</b>
                                </Button>
                                :
                                <Link to="/dashboard/configuracion-dispositivos/" transition='glide-right'>
                                    <Button
                                        style={{ width: "100%", height: 52, fontSize: 13 }}
                                        variant="outlined"
                                        color={"primary"}
                                        size="medium"
                                        disabled={this.state.parameterList.length > 0 && this.state.valueNameFw !== "" && this.state.valueDeviceSelected !== "" ? false : true}
                                        // style={{ marginLeft: 10 }}
                                        onClick={this.onHandleToSendParameters.bind(this)}
                                    >
                                        <b>Aceptar</b>
                                    </Button>
                                </Link>
                            :
                            <Link to="/dashboard/configuracion-dispositivos/" transition='glide-right'>
                                <Button
                                    style={{ width: "100%", height: 52, fontSize: 13 }}
                                    variant="outlined"
                                    color={"primary"}
                                    size="medium"
                                    disabled={this.state.parameterList.length > 0 && this.state.valueNameFw !== "" ||  this.state.valueNameFw !== null && this.state.valueDeviceSelected !== "" || this.state.valueDeviceSelected !== null ? false : true}
                                    // style={{ marginLeft: 10 }}
                                    onClick={this.onHandleToUpdateParameters.bind(this)}
                                >
                                    <b>Aceptar</b>
                                </Button>
                            </Link>
                        }
                    </Grid>


                    {/* <Grid item xs={6}>

                    </Grid> */}
                </Grid>
                <DialogView handleOnclickAcceptDialogView={this.handleOnclickAcceptDialogView} textForDialog={this.state.textForDialog} onOpenDialogView={this.onOpenDialogView} onCloseDialogView={this.onCloseDialogView} dialogViewShow={this.state.dialogViewShow} />
                
                <div>
                    <Dialog
                        open={this.state.dialogErrorParameterSend}
                        // onClose={this.handleOnClickShowAndHide.bind(this)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"AVISO"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {/* ¿Desea eliminar la configuración? */}
                                <label>Debe indicar una trama de envío si el campo obligatorio está chequeado.</label>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={this.handleOnclickAcceptDialogErrorParameterSend}
                                color="primary">
                                Aceptar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div >
            </div >
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(FormAddDeviceConfiguration));
