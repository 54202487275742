import React, { Component } from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText, IconButton, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { Link } from "react-tiger-transition";

const styles = theme => ({
  card: {
    maxWidth: "100%",
    margin: theme.spacing(2),
    height: '100%',
  },
  list: {
    maxHeight: '200px', // Ajusta esta altura según sea necesario
    overflowY: 'auto',
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
  },
  statusText: {
    fontWeight: 'bold',
  }
});

class CardList extends Component {
  handleScroll = (e) => {
    e.stopPropagation();
  };

  formatDate(date) {
    if (!date) return "-/-/-";
    let time = new Date(date);
    let day = (time.getDate() < 10 ? "0" + time.getDate() : time.getDate());
    let month = ((time.getMonth() + 1) < 10 ? "0" + (time.getMonth() + 1) : (time.getMonth() + 1));
    let hour = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours());
    let minutes = (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
    let seconds = (time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds());
    return day + "/" + month + "/" + time.getFullYear() + " " + hour + ":" + minutes + ":" + seconds;
  }

  getStatus(lastEvent) {
    if (!lastEvent) return "";
    const { startDate, endDate } = lastEvent;
    if (startDate && endDate) {
      return "Cerrado";
    } else if (startDate && !endDate) {
      return "Abierto";
    } else {
      return "";
    }
  }

  render() {
    const { classes, title, items, sensorId, handleOnclickInputDigitalUpdateSensors } = this.props;
    const lastEvent = items.length > 0 ? items[items.length - 1] : null;
    const status = this.getStatus(lastEvent);

    return (
      <Card className={classes.card}>
        <CardContent>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography
              style={{ maxWidth: '250px', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto' }}
              variant="h5"
            >
              {title}
            </Typography>
            {this.props.sensorId === 'din2' &&

              <IconButton onClick={() => handleOnclickInputDigitalUpdateSensors(sensorId)} component={Link} transition='glide-left' to={{
                pathname: '/instaladores/checkeo/inputDigital/update',
              }}>
                <SyncAltIcon />
              </IconButton>
            }

          </Box>
          {items.length > 0 && status && (
            <Typography variant="body1" className={classes.statusText}>
              Estado: {status}
            </Typography>
          )}
          <List className={classes.list}>
            {items.length > 0 ? items.map((item, index) => (
              <ListItem key={index} className={classes.listItem}>
                <ListItemText
                  primary={
                    <div className={classes.listItemText}>
                      <Typography variant="body1">Inicio: {this.formatDate(item.startDate)}</Typography>
                      <Typography variant="body1">Fin: {this.formatDate(item.endDate)}</Typography>
                    </div>
                  }
                />
              </ListItem>
            )) :
              null}
          </List>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(CardList);
