import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  bg: {
    backgroundColor: theme.palette.background.paper,
  },
  customGrid: {
    padding: 0, // Elimina el padding
    display: 'flex', // Utiliza flexbox para centrar el contenido

  },
}));



// function generate(element) {
//   return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27].map((value) =>
//     React.cloneElement(element, {
//       key: value,
//     }),
//   );
// }

const AlertsWarningsMobile = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.bg}>
        <React.Fragment>


        </React.Fragment>

        <List dense={true} style={{ padding: "10px" }}>
          <h3 style={{ paddingLeft: "16px" }}>Alertas y Avisos</h3>
          <Grid className='custom-styles-grid' container spacing={0}>
            <Grid style={{ paddingLeft: "15px !important" }} className='custom-styles-grid-1' item xs={5} md={6}>
              DISPOSITIVO / TIPO
            </Grid>
            <Grid className='custom-styles-grid-date' style={{ paddingLeft: "3px !important" }} item xs={7} md={6}>
              FECHA / MOTIVO
            </Grid>
          </Grid>
          {props.rows.length > 0 && props.rows.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <ListItem>
                  <Grid className='custom-styles-grid-list' container spacing={2}>
                    <Grid className='custom-styles-grid-list' item xs={5} md={6}>
                      <ListItemText
                        primary={item.device}
                        secondary={item.type}
                      />
                    </Grid>
                    <Grid className='custom-styles-grid-list' item xs={7} md={6}>
                      <ListItemText
                        primary={item.date}
                        secondary={item.subType}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider className='custom-divider' />
              </React.Fragment>

            )
          })}
        </List>
      </div>
    </div>

  )
}

export default AlertsWarningsMobile