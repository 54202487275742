import React, { useState, useEffect } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { Typography, Box, Container, CssBaseline } from '@material-ui/core';
import './styles.css';
import { makeStyles } from '@material-ui/core/styles';
import AlertsWarningsMobile from './AlertsWarningsMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
// const top100Films = [
//   { title: 'Todos' },
//   { title: 'Alertas' },
//   { title: 'Avisos', },
//   { title: 'No revisadas', },
//   { title: 'Revisadas', },
// ];



// const rows = [
//   { id: 1, Dispositivo: "AAN-911 LINCARSA", Notificacion: "Alerta", tipo: "Sensores", Elemento: "(104) maletin", Motivo: "Activado", Fecha: "15/09/2023 6:59:23" },
//   { id: 2, Dispositivo: "BJT-731/T071 Trans", Notificacion: "Alerta", tipo: "Sensores", Elemento: "Botón de pánico", Motivo: "Desactivado", Fecha: "15/09/2023 6:38:17" },
//   { id: 3, Dispositivo: "1107032098", Notificacion: "Alerta", tipo: "Sensores", Elemento: "Botón de pánico", Motivo: "Activado", Fecha: "15/09/2023 6:59:23" },
//   { id: 4, Dispositivo: "1107032099", Notificacion: "Alerta", tipo: "Sensores", Elemento: "-", Motivo: "Activado", Fecha: "15/09/2023 6:59:23" },
//   { id: 5, Dispositivo: "1107032081", Notificacion: "Alerta", tipo: "Sensores", Elemento: "-", Motivo: "Desactivado", Fecha: "15/09/2023 6:59:23" },
// ];


const columns = [
  { field: 'device', headerName: 'Dispositivo', sortable: false, minWidth: 110, disableColumnFilter: true, },
  { field: 'type', headerName: 'Tipo', sortable: false, minWidth: 110, disableColumnFilter: true, },
  { field: 'subType', headerName: 'Motivo', sortable: false, minWidth: 110, disableColumnFilter: true, },
  { field: 'date', headerName: 'Fecha', sortable: false, minWidth: 110, disableColumnFilter: true, },


];
const AlertsWarnings = (props) => {
  const [columnsWithWidth, setColumnsWithWidth] = useState([]);
  const [rows, setRows] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    let DateJs = new Date();
    let DateToApi = "";

    if (props.location.state.dateNotification === undefined) {
      // DateToApi = DateJs.toISOString();
      DateToApi = dateToTicks();
    } else {
      DateToApi = props.location.state.dateNotification;
    }

    const fetchData = async () => {
      try {
        const settings = {
          method: 'GET',
          headers: {
            'accessToken': props.location.state.AccessToken,
          },
        };

        const responseServices = await fetch(`${props.location.state.ApiURL}Notifications?userId=${props.location.state.userDataInfo.userId}&dateTicks=${DateToApi}`, settings);

        if (responseServices.ok) {
          const data = await responseServices.json();
          data.forEach((element, index) => {
            element.id = index; // Usar un valor único, como el índice
            element.date = dateFormat(element.date);
          });
          return data;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      return [];
    };

    const calculateColumnWidths = (data) => {
      const updatedColumns = columns.map((column) => {
        const maxLength = Math.max(...data.map((row) => String(row[column.field]).length));
        const minWidth = Math.max(150, maxLength * 10);
        return {
          ...column,
          width: minWidth,
        };
      });
      setColumnsWithWidth(updatedColumns);
    };

    fetchData().then(data => {
      setRows(data);
      calculateColumnWidths(data);
      document.getElementById("pin").style.display = "none";
    });

  }, [props.location.state.AccessToken, props.location.state.ApiURL, props.location.state.dateNotification, props.location.state.userDataInfo.userId]);

  function isMobileDevice() {
    const mobileKeywords = ['Android', 'iPhone', 'iPad', 'Windows Phone'];
    const userAgent = navigator.userAgent;

    if (mobileKeywords.some(keyword => userAgent.includes(keyword))) {
      return true;
    }

    // Comprueba si la ventana `window` tiene una propiedad `ontouchstart`
    if ('ontouchstart' in window) {
      return true;
    }

    // Otras comprobaciones específicas para dispositivos móviles o WebView, si es necesario

    return false; // No se detectó un dispositivo móvil
  }

  const dateFormat = (dateISO) => {
    // Extraer componentes de la fecha (día, mes, año, hora, minutos y segundos)
    const date = new Date(dateISO);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Crear la cadena de fecha formateada
    return `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`;
  }

  const dateToTicks = () => {
    // Obtén la fecha actual
    const fechaJS = new Date();

    // Establece la hora a 00:00:00 del día de ayer
    fechaJS.setDate(fechaJS.getDate() - 1);
    fechaJS.setHours(1, 0, 0, 0);

    // Obtiene la fecha en milisegundos
    const fechaEnMilisegundos = fechaJS.getTime();

    const ticksPorMilisegundo = 10000;
    const ticksEntreEpochs = 621355968000000000;

    // Realiza la conversión a ticks de C#
    const fechaEnTicks = fechaEnMilisegundos * ticksPorMilisegundo + ticksEntreEpochs;
    return fechaEnTicks;
  }

  const DataGridTitle = () => {
    return (
      <Box >
        <Typography style={{ width: "100%", padding: 15, display: "flex", justifyContent: "center", alignItems: "center" }} variant="h5">Alertas y Avisos</Typography>
        <div className={classes.root}>
          {/* <Grid style={{ marginBottom: 20, justifyContent: "center", alignItems: "center" }} spacing={2} container className={classes.root}>
            <Grid item md={4} xs={12} >
              <paper>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={top100Films}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}

                        checked={selected}
                      />
                      {option.title}
                    </React.Fragment>
                  )}
                  style={{ minWidth: 230, margin: "auto" }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Notificaciones" />
                  )}
                />
              </paper>

            </Grid>
            <Grid item md={4} xs={12}>
              <paper>
                <TextField style={{ minWidth: 230, margin: "auto" }} variant="outlined" label="Buscar" />
              </paper>
            </Grid>
          </Grid> */}
        </div>

        {/* <Button
          style={{ marginLeft: "8px" }}
          variant="outlined"
          color="default"
          className={classes.button}
          startIcon={<CheckBoxIcon style={{ marginLeft: "-7px" }} htmlColor='#bf2349' />}
        >
          Marcar todas revisadas
        </Button> */}
      </Box>
    )
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {isMobileDevice() ?
        <div>
          <AlertsWarningsMobile rows={rows} />
        </div>
        :
        <Container maxWidth="lg" style={{ marginTop: 20 }}>
          <DataGrid

            style={{ height: 475, }}
            rows={rows}

            columns={columnsWithWidth}
            pageSize={5}
            rowsPerPageOptions={[5]}
            //checkboxSelection
            disableSelectionOnClick
            components={{ Toolbar: DataGridTitle }}
          />
        </Container>
      }


    </React.Fragment>
  )
}

export default AlertsWarnings