import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Navigation, Route, Link, glide } from "react-tiger-transition";
import { BrowserRouter as Router } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {
    DataGrid,
    GridToolbarContainer,
    GridOverlay,
    esES,
} from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { green, red } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import IconButton from "@material-ui/core/IconButton";
import DialogViewGroup from './DialogViewGroup';
import config from '../../../config';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import DialogView from './Dialog';

// import { Link, glide } from "react-tiger-transition";



const theme = createTheme(
    {
        typography: {
            htmlFontSize: 10
        },
        overrides: {
            MuiCssBaseline: {
                "@global": {
                    html: {
                        fontSize: "62.5%"
                    }
                }
            }
        }
    },
    esES,
);

// const styles = theme => ({
//     root: {
//         fontSize: 14,
//         zIndex: 500,
//         '& > * + *': {
//             fontSize: 14,
//         },
//         '& .MuiInputBase-root': {
//             fontSize: "1.6rem !important"
//         },
//         '& .MuiMenuItem-root': {
//             fontSize: "14px !important"
//         }
//     },
//     toolbarAdd: {
//         color: green[500],
//         // backgroundColor: green[500],
//         '&:hover': {
//             backgroundColor: green[100],
//         },
//         fontSize: 14,
//         borderColor: green[500]
//         // background: "#54bf71",
//     },
//     toolbarDelete: {
//         color: red[500],
//         // backgroundColor: red[500],
//         '&:hover': {
//             backgroundColor: red[100],
//         },
//         fontSize: 14,
//         marginLeft: 10,
//         borderColor: red[500]
//         // background: "#54bf71",
//     },
//     gridContainer: {
//         '& .MuiGrid-root': {
//             padding: "3px !important",
//             background: "unset !important",
//             width: "100% !important"
//         }
//     },
//     snackBar: {

//         '& .MuiAlert-message': {
//             fontSize: 14
//         }
//     },
// });

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: 700,
        margin: "auto",
        marginTop: 25,
        fontSize: 14,
        zIndex: 500,

    },
    rootDatagrid: {
        fontSize: 14,
        zIndex: 500,
        '& > * + *': {
            fontSize: 14,
        },
        '& .MuiInputBase-root': {
            fontSize: "1.6rem !important"
        },
        '& .MuiMenuItem-root': {
            fontSize: "14px !important"
        }

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: "100%",
    },
    rootFormControl: {
        minWidth: "100%",
        '& .MuiFormHelperText-root': {
            marginTop: "0px !important"
        },
    },

    selectEmpty: {
        color: "#aaa"
    },
    rootButton: {
        color: "#32a852",
        padding: 7
    },
    rootButtonDelete: {
        color: "#eb4034",
        padding: 7
    },
    listStyles: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    },
    listFont: {
        '& .MuiListItemText-secondary': {
            fontSize: theme.typography.fontSize,
        },
        '& .MuiListItemText-primary': {
            fontSize: "16px !important"
        }
    },
    toolbarAdd: {
        color: green[500],
        // backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[100],
        },
        fontSize: 14,
        borderColor: green[500]
        // background: "#54bf71",
    },
    toolbarDelete: {
        color: red[500],
        // backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[100],
        },
        fontSize: 14,
        marginLeft: 10,
        borderColor: red[500]
        // background: "#54bf71",
    },
    gridContainer: {
        '& .MuiGrid-root': {
            padding: "3px !important",
            background: "unset !important",
            width: "100% !important"
        }
    },
    snackBar: {

        '& .MuiAlert-message': {
            fontSize: 14
        }
    },



});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ControlTemporalAccessGroup extends Component {
    state = {
        rows: [],
        columns: [
            { field: 'nombreGrupo', headerName: 'Nombre Grupo', width: 130, flex: 1 },

            {
                field: 'acciones', headerName: 'Acciones', width: 300, sortable: false, filterable: false,
                renderCell: (GridCellParams) => {
                    return (

                        <div>
                            <strong>

                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    startIcon={<DeleteOutlineOutlinedIcon />}
                                    style={{ width: 100 }}
                                    onClick={this.onOpenDialogView.bind(this, GridCellParams)}
                                >
                                    Eliminar
                                </Button>
                            </strong>
                            <strong >
                                <Link to="/dashboard/gestion-grupos-acceso-temporal/gestion" transition='glide-left'>
                                    <Button

                                        variant="outlined"
                                        color="primary"
                                        startIcon={<EditOutlinedIcon />}
                                        style={{ marginLeft: 15, width: 100 }}
                                        onClick={this.edit.bind(this, GridCellParams)}
                                    >
                                        Editar
                                    </Button>
                                </Link>
                            </strong>
                            <Divider />
                        </div>

                    )
                }
            },
        ],
        dialogViewShow: false,
        selectedToDelete: {},
        textForDialog: "",
        isLoading: true,
        valueNameGroup: "",
        valueUserName: "",
        valueUserEmail: "",
        userList: [],
        valueTransportSelected: -1,
        dialogViewShow: false,
        isEdit: false,
        valueNameGroupEdit: "",
        valueUserNameEdit: "",
        valueUserEmailEdit: "",
        idToEdit: "",
        userListEdit: [],
        snackBarAlertIsOpen: false,
        messageToSnackBarAlert: "",
        isAlertShow: false,
        showButtonAlertError: false

    }

    componentDidMount() {

        setTimeout(async () => {
            await this.getGroupsFromApi();
        }, 1000);
        // {
        //     id: 1, nombreGrupo: "I.E.S Gran Canaria - Tercero A", userList: [{id: 1,
        //         Nombre: "Jose1",
        //         Email: "jalberich@inelcan.com"}]
        // },
        // this.setState({
        //     isLoading: false,
        //     rows: groupsResponse !== undefined ? groupsResponse : []
        // });

    }



    handleOnClickAddNewGroup() {
        this.props.history.push({
            pathname: '/creacion-grupos-acceso-temporal',
            state: {
                locale: this.props.location.state.locale,
                userDataInfo: this.props.location.state.userDataInfo,
                ApiURL: this.props.location.state.ApiURL,
                AccessToken: this.props.location.state.userDataInfo.accessToken
            },
            isAuthenticated: this.props.isAuthenticated,
            companyName: this.props.companyName,
            accessToken: this.props.location.state.AccessToken,
        });
    }

    CustomToolbar() {
        const { classes } = this.props;
        return (
            <GridToolbarContainer className={classes.gridContainer}>
                <Grid item xs={12}>
                    {/* <Link to="/dashboard/group-form/" transition='glide-left'> */}
                    <ListItem button component={Link} transition='glide-left' style={{ width: 130, background: "transparent", userSelect: "none" }} to={{
                        pathname: '/dashboard/gestion-grupos-acceso-temporal/gestion',
                        // state: this.props.location.state !== undefined ? this.props.location.state : "",
                        // companyName: this.state.companyName
                    }}> <Button
                        variant="outlined"
                        className={classes.toolbarAdd}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                    // onClick={this.handleOnClickAddNewGroup.bind(this)}

                    >
                            Añadir
                        </Button></ListItem>

                    {/* </Link> */}


                    {/* <Button
                        variant="outlined"
                        className={classes.toolbarAdd}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        // onClick={this.handleOnClickAddNewGroup.bind(this)}
                    >
                        Añadir
                        </Button> */}



                    {/* <Button
                        variant="outlined"
                        className={classes.toolbarDelete}
                        startIcon={<DeleteOutlineOutlinedIcon />}

                    >
                        Eliminar
            </Button> */}
                </Grid>
            </GridToolbarContainer>

        );
    }

    CustomLoadingOverlay() {
        return (
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress color={"secondary"} />
                </div>
            </GridOverlay>
        );
    }

    handleCloseModal = () => {
        this.setState({
            openModal: false
        });
    }
    // handleOnClickAddNewGroup() {
    //     this.setState({
    //         openModal: true
    //     });
    // }

    onHandleClickAddNewUser() {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.state.isEdit) {

            if (this.state.valueUserNameEdit !== "" && this.state.valueUserEmailEdit !== "") {
                let emailCorrectFormatBool = re.test(String(this.state.valueUserEmailEdit).toLowerCase());
                if (!emailCorrectFormatBool) {
                    this.setState({
                        snackBarAlertIsOpen: true,
                        messageToSnackBarAlert: "El formato del email indicado no es correcto."
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                snackBarAlertIsOpen: false,
                                messageToSnackBarAlert: ""
                            })
                        }, 4000);
                    })
                } else {
                    let newJsonUser = {
                        // id: this.state.valueEmail,
                        description: this.state.valueUserNameEdit,
                        email: this.state.valueUserEmailEdit,
                    }
                    let result = this.state.userList.filter(element => element.email === this.state.valueUserEmailEdit);
                    console.log(result);
                    if (result.length > 0) {
                        this.setState({
                            snackBarAlertIsOpen: true,
                            messageToSnackBarAlert: "Ese email ya ha sido introducido anteriormente."
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    snackBarAlertIsOpen: false,
                                    messageToSnackBarAlert: ""
                                })
                            }, 4000);
                        })
                    } else {
                        this.setState({
                            // ParametersInfo: copyParametersInfo,
                            userList: [...this.state.userList, newJsonUser],
                            valueUserEmailEdit: "",
                            valueUserNameEdit: ""
                        });
                    }
                }

            } else {
                this.setState({
                    snackBarAlertIsOpen: true,
                    messageToSnackBarAlert: "Debe rellenar el nombre de usuario y el email, para poder añadir."
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            snackBarAlertIsOpen: false,
                            messageToSnackBarAlert: ""
                        })
                    }, 4000);

                })
            }
        } else
            if (this.state.valueUserName !== "" && this.state.valueUserEmail !== "") {
                let emailCorrectFormatBool = re.test(String(this.state.valueUserEmail).toLowerCase());
                if (!emailCorrectFormatBool) {
                    this.setState({
                        snackBarAlertIsOpen: true,
                        messageToSnackBarAlert: "El formato del email indicado no es correcto."
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                snackBarAlertIsOpen: false,
                                messageToSnackBarAlert: ""
                            })
                        }, 4000);

                    })
                } else {
                    let newJsonUser = {
                        // id: this.state.valueEmail,
                        description: this.state.valueUserName,
                        email: this.state.valueUserEmail,
                    }

                    let result = this.state.userList.filter(element => element.email === this.state.valueUserEmail);
                    if (result.length > 0) {
                        this.setState({
                            snackBarAlertIsOpen: true,
                            messageToSnackBarAlert: "Ese email ya ha sido introducido anteriormente."
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    snackBarAlertIsOpen: false,
                                    messageToSnackBarAlert: ""
                                })
                            }, 4000);
                        })
                    } else {
                        this.setState({
                            // ParametersInfo: copyParametersInfo,
                            userList: [...this.state.userList, newJsonUser],
                            valueTextField: "",
                            valueParameter: null,
                            valueUserName: "",
                            valueUserEmail: ""
                        });
                    }



                }
            } else {
                this.setState({
                    snackBarAlertIsOpen: true,
                    messageToSnackBarAlert: "Debe rellenar el nombre de usuario y el email, para poder añadir."
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            snackBarAlertIsOpen: false,
                            messageToSnackBarAlert: ""
                        })
                    }, 4000);

                })
            }
    }

    async addNewGroupToApi(groupToCreate) {
        let response = "";
        const settings = {
            method: 'POST',
            headers: {
                'accessToken': this.props.location.state !== undefined ? this.props.location.accessToken : this.state.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(groupToCreate)
        };

        // IsActive(bool), Email, Observations
        let responseCreateGroup = await fetch(`${config.apiGateway.URL}AccessControl/Transport/Group`, settings);
        // let responseServices = await fetch(config.apiGateway.URL + "/AccessControl?IsActive=false&Email=''&Observations=", settings);

        if (responseCreateGroup.ok) {
            if (responseCreateGroup !== null || responseCreateGroup !== undefined) {
                try {

                    response = await responseCreateGroup.json();
                    if (response) {
                        this.setState({
                            isAlertShow: true,
                            textForAlert: "El grupo se ha creado satisfactoriamente.",
                            severityState: "success"
                        });
                    } else {
                        this.setState({
                            isAlertShow: true,
                            textForAlert: "Ha ocurrido un error a la hora de crear el registro de grupo.",
                            severityState: "error"
                        });
                    }

                } catch (error) {
                    response = null;
                    this.setState({
                        isAlertShow: true,
                        textForAlert: "Ha ocurrido un error a la hora de crear el registro de grupo.",
                        severityState: "error"
                    });
                }
            }
            // console.log(response);
            // response = null;


        } else {
            // alert("HTTP-Error: " + responseServices.status);
            // this.goToPageErr();
            return;
        }
    }

    async getGroupsFromApi() {
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                'accessToken': this.props.location.state !== undefined ? this.props.location.accessToken : this.props.location.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        };
        let responseCreateGroup = await fetch(`${config.apiGateway.URL}AccessControl/Transport/Group`, settings);


        if (responseCreateGroup.ok) {
            if (responseCreateGroup !== null || responseCreateGroup !== undefined) {
                try {
                    response = await responseCreateGroup.json();
                    if (response !== undefined || response !== null) {
                        let newRows = [];
                        response.forEach(element => {
                            let newObjectToRow = {
                                id: element.id,
                                nombreGrupo: element.name,
                                userList: element.users
                            };
                            newRows.push(newObjectToRow);
                        });

                        this.setState({
                            rows: newRows,
                            isLoading: false,

                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    isAlertShow: false,
                                    textForAlert: "",
                                    severityState: ""
                                });
                            }, 4000);
                        });
                        // return newRows;
                    }

                } catch (error) {
                    this.setState({
                        rows: [],
                        isLoading: false
                    });
                }
            }
            // console.log(response);
            // response = null;


        } else {
            // alert("HTTP-Error: " + responseServices.status);
            return;
        }
    }

    async deleteGroupApi(id) {
        let response = "";
        const settings = {
            method: 'DELETE',
            headers: {
                'accessToken': this.props.location.state !== undefined ? this.props.location.accessToken : this.props.location.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        };
        let responseCreateGroup = await fetch(`${config.apiGateway.URL}AccessControl/Transport/Group?id=${id}`, settings);


        if (responseCreateGroup.ok) {
            if (responseCreateGroup !== null || responseCreateGroup !== undefined) {
                try {
                    response = await responseCreateGroup.json();
                    if (response) {
                        this.setState({
                            isAlertShow: true,
                            textForAlert: "El grupo se ha eliminado satisfactoriamente.",
                            severityState: "success",
                            dialogViewShow: false,
                            isLoading: true,
                        });
                    } else {
                        this.setState({
                            isAlertShow: true,
                            textForAlert: "Ha ocurrido un error a la hora de eliminar el registro del grupo.",
                            severityState: "error",
                            dialogViewShow: false,
                            isLoading: true,
                        });
                    }

                } catch (error) {
                    response = null;
                    this.setState({
                        isAlertShow: true,
                        textForAlert: "Ha ocurrido un error a la hora de eliminar el registro del grupo.",
                        severityState: "error",
                        dialogViewShow: false,
                        isLoading: true,
                    });
                }
            }
            // console.log(response);
            // response = null;


        } else {
            // alert("HTTP-Error: " + responseServices.status);
            return;
        }
    }
    async updateGroupApi(request) {
        let response = "";
        const settings = {
            method: 'PUT',
            headers: {
                'accessToken': this.props.location.state !== undefined ? this.props.location.accessToken : this.props.location.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        };
        let responseCreateGroup = await fetch(`${config.apiGateway.URL}AccessControl/Transport/Group`, settings);


        if (responseCreateGroup.ok) {
            if (responseCreateGroup !== null || responseCreateGroup !== undefined) {
                try {
                    response = await responseCreateGroup.json();
                    if (response) {
                        this.setState({
                            isAlertShow: true,
                            textForAlert: "El grupo se ha actualizado satisfactoriamente.",
                            severityState: "success"
                        });
                    } else {
                        this.setState({
                            isAlertShow: true,
                            textForAlert: "Ha ocurrido un error a la hora de actualizar el registro de grupo.",
                            severityState: "error"
                        });
                    }

                } catch (error) {
                    response = null;
                    this.setState({
                        isAlertShow: true,
                        textForAlert: "Ha ocurrido un error a la hora de actualizar el registro de grupo.",
                        severityState: "error"
                    });
                }
            }
            // console.log(response);
            // response = null;


        } else {
            // alert("HTTP-Error: " + responseServices.status);
            return;
        }
    }

    onHandleChangevalueGroupName(e) {

        let result = this.state.rows.filter(element => element.nombreGrupo === e.target.value);


        if (this.state.isEdit) {

            if (result.length > 0) {
                if (result[0].id !== this.state.idToEdit) {
                    this.setState({
                        valueNameGroupNameEdit: e.target.value,
                        showButtonAlertError: true
                    });
                } else {
                    this.setState({
                        valueNameGroupNameEdit: e.target.value,
                        showButtonAlertError: false
                    });
                }

            } else {
                this.setState({
                    valueNameGroupNameEdit: e.target.value,
                    showButtonAlertError: false
                });
            }

        } else {
            if (result.length > 0) {
                this.setState({
                    valueNameGroupName: e.target.value,
                    showButtonAlertError: true
                });
            } else {
                this.setState({
                    valueNameGroupName: e.target.value,
                    showButtonAlertError: false
                })
            }
        }
    }

    handleOnChangeValueUserName(e) {
        if (this.state.isEdit) {
            this.setState({
                valueUserNameEdit: e.target.value
            });
        } else {
            this.setState({
                valueUserName: e.target.value
            });
        }

    }
    handleOnChangeValueEmail(e) {

        if (this.state.isEdit) {
            this.setState({
                valueUserEmailEdit: e.target.value
            });
        } else {
            this.setState({
                valueUserEmail: e.target.value
            });
        }
    }

    async onHandleClickAddNewGroup() {
        let request = {};

        if (this.state.showButtonAlertError) {
            this.setState({
                isAlertShow: true,
                textForAlert: "Ese nombre de grupo ya está registrado, pruebe con otro.",
                severityState: "warning",
            }, () => {
                setTimeout(() => {
                    this.setState({
                        isAlertShow: false,
                        textForAlert: "",
                        severityState: "",
                    })
                }, 4000);
            })
        } else {
            if (this.state.isEdit) {
                request = {
                    Id: this.state.idToEdit,
                    Name: this.state.valueNameGroupNameEdit,
                    Users: this.state.userList
                }
                await this.updateGroupApi(request);
            } else {
                request = {
                    Name: this.state.valueNameGroupName,
                    Users: this.state.userList
                }
                await this.addNewGroupToApi(request)
            }

            this.setState({
                isLoading: true,
                isEdit: false,
                valueNameGroupName: "",
                valueNameGroupEdit: "",
                valueUserEmail: "",
                valueUserEmailEdit: "",
                valueUserName: "",
                valueUserEmailEdit: "",
                userList: [],
                rows: [],


            }, () => {
                setTimeout(async () => {
                    await this.getGroupsFromApi();
                }, 1000);

            });
        }



    }

    onOpenDialogView = (GridCellParams) => {
        // console.log(e);
        this.setState({
            dialogViewShow: true,
            selectedToDelete: GridCellParams.row,
            textForDialog: "¿Desea eliminar el grupo?",
            idGroupToDelete: GridCellParams.row.id,

        });
    }

    onCloseOrOpenDialogViewGroup = () => {
        this.setState({
            dialogViewShow: false
        })
    }

    handleOnclickAcceptDialogViewGroup = async () => {
        let copyRows = [...this.state.rows];
        let newRows = copyRows.filter(item => item.id !== this.state.selectedToDelete.id);
        await this.deleteGroupApi(this.state.idGroupToDelete);

        this.setState({
            isAlertShow: true,
            textForAlert: "El grupo se ha eliminado satisfactoriamente.",
            severityState: "success",
            isLoading: false,
            rows: newRows,
        }, () => {
            setTimeout(() => {
                this.setState({
                    isAlertShow: false,
                    textForAlert: "",
                    severityState: "",
                })
            }, 3000)

        });
    }

    edit(e) {

        this.setState({
            idToEdit: e.row.id,
            valueNameGroupNameEdit: e.row.nombreGrupo,
            userList: e.row.userList,
            isEdit: true
        });
    }

    onHandleClickToDelete(email) {

        let copyUserList = [...this.state.userList];
        // let result = copyParametersList.filter(item => item.id === id);

        let index = copyUserList.findIndex(item => item.email === email);
        let result = copyUserList.splice(index, 1);

        if (index === -1) {
            this.setState({
                userList: []
            });
        } else {
            this.setState({
                userList: copyUserList
            });
        }
        // // result[0].id = "";
        // copyParametersInfo.push(jsonToPush);

    }

    cancelAddNewGroup() {
        this.setState({
            isEdit: false,
            valueNameGroupName: "",
            valueNameGroupEdit: "",
            valueUserEmail: "",
            valueUserEmailEdit: "",
            valueUserName: "",
            valueUserEmailEdit: "",
            userList: []
        });
    }
    inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    handleOnClickGoBack() {
        let isInIframe = this.inIframe();

        if (!isInIframe) {
            this.props.history.push({
                pathname: '/dashboard/accesotemporal/',
                state: {
                    locale: this.props.location.state.locale,
                    userDataInfo: this.props.location.state.userDataInfo,
                    ApiURL: this.props.location.state.ApiURL,
                    AccessToken: this.props.location.accessToken
                },
                isAuthenticated: this.props.isAuthenticated,
                companyName: this.props.companyName,
                accessToken: this.props.location.AccessToken,
            });
        } else {
            this.props.history.push({
                pathname: '/accesotemporal/',
                state: {
                    locale: this.props.location.state.locale,
                    userDataInfo: this.props.location.state.userDataInfo,
                    ApiURL: this.props.location.state.ApiURL,
                    AccessToken: this.props.location.accessToken
                },
                isAuthenticated: this.props.isAuthenticated,
                companyName: this.props.companyName,
                accessToken: this.props.location.AccessToken,
            });
        }

    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{ height: "calc(100% - 100px)", width: '89%', margin: "auto", marginTop: 20 }}>
                {
                    this.state.isAlertShow ?
                        <div style={{ marginBottom: "12px" }}>
                            <Alert severity={this.state.severityState}>{this.state.textForAlert}</Alert>
                        </div>
                        :
                        null
                }
                <AppBar className="item1" position="static">
                    <Toolbar>
                        <div >
                            <Button
                                style={{ background: "transparent", boxShadow: "none", color: "#fff" }}
                                variant="contained"
                                onClick={this.handleOnClickGoBack.bind(this)}
                                className={classes.button}
                                startIcon={<ArrowBackIcon />}
                            >
                                Atrás
                            </Button>


                        </div>
                    </Toolbar>
                </AppBar>
                <h2 className="item2" style={{ textAlign: "center", color: "#bf2349" }}>Creación de grupos</h2>
                <Router>
                    <Navigation>
                        <Route exact path={this.inIframe() ? "/gestion-grupos-acceso-temporal/" : "/dashboard/gestion-grupos-acceso-temporal/"} >



                            <ThemeProvider theme={theme}>
                                <DataGrid
                                    className={classes.rootDatagrid}
                                    rows={this.state.rows}
                                    columns={this.state.columns.map((column) => ({
                                        ...column,
                                        disableClickEventBubbling: true,
                                    }))}
                                    autoHeight={true}
                                    checkboxSelection={false}
                                    pageSize={10}
                                    // disableColumnMenu
                                    components={{
                                        Toolbar: this.CustomToolbar.bind(this),
                                        LoadingOverlay: this.CustomLoadingOverlay.bind(this),
                                        // Toolbar: GridToolbar,
                                    }}
                                    loading={this.state.isLoading}

                                />
                            </ThemeProvider>
                            <DialogViewGroup handleOnclickAcceptDialogViewGroup={this.handleOnclickAcceptDialogViewGroup} onCloseOrOpenDialogViewGroup={this.onCloseOrOpenDialogViewGroup} textForDialog={this.state.textForDialog} dialogViewShow={this.state.dialogViewShow} />
                        </Route>
                        <Route exact path="/dashboard/gestion-grupos-acceso-temporal/gestion" >
                            <div>
                                <div className={classes.root}>
                                    <Grid container justify="center" spacing={2}>

                                        <Grid item xs={12}>
                                            <FormControl className={classes.rootFormControl} autoComplete="off">
                                                <TextField id="outlined-basic" variant="outlined"
                                                    placeholder="Indique un nombre de grupo"
                                                    onChange={this.onHandleChangevalueGroupName.bind(this)}
                                                    value={this.state.isEdit ? this.state.valueNameGroupNameEdit : this.state.valueNameGroupName}
                                                // disabled={this.props.deviceConfigurationSelected === "" ? false : true}
                                                />
                                            </FormControl>

                                        </Grid>
                                        {/* <Grid item xs={12}>
                        <Divider variant="middle"/>
                    </Grid> */}

                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" style={{ marginTop: 20 }} className={classes.rootFormControl} autoComplete="off">
                                                <TextField id="outlined-basic" variant="outlined"
                                                    inputProps={{ inputMode: 'numeric' }}
                                                    placeholder="Nombre usuario"
                                                    type="text"
                                                    onChange={this.handleOnChangeValueUserName.bind(this)}
                                                    value={this.state.isEdit ? this.state.valueUserNameEdit : this.state.valueUserName}

                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" style={{ marginTop: 20 }} className={classes.rootFormControl} autoComplete="off">
                                                <TextField id="outlined-basic" variant="outlined"
                                                    inputProps={{ inputMode: 'numeric' }}
                                                    placeholder="Email usuario"
                                                    type="text"
                                                    onChange={this.handleOnChangeValueEmail.bind(this)}
                                                    value={this.state.isEdit ? this.state.valueUserEmailEdit : this.state.valueUserEmail}

                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div className="hr-theme-slash-2">
                                                <div className="hr-line"></div>
                                                <div className="hr-icon">
                                                    <IconButton onClick={this.onHandleClickAddNewUser.bind(this)} className={classes.rootButton}>
                                                        <AddCircleOutlineOutlinedIcon />
                                                    </IconButton>
                                                </div>
                                                <div className="hr-line"></div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <div className={classes.listStyles}>
                                                <List dense disablePadding>
                                                    {this.state.userList.map((item, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <ListItem className={classes.listFont}>

                                                                    <ListItemText

                                                                        primary={item.email}
                                                                        secondary={item.description}
                                                                    />
                                                                    <ListItemSecondaryAction>
                                                                        <IconButton onClick={this.onHandleClickToDelete.bind(this, item.email)} edge="end" aria-label="delete">
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                                <Divider />
                                                            </React.Fragment>

                                                        )
                                                    })}

                                                </List>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Link to={this.inIframe() ? "/gestion-grupos-acceso-temporal/" : "/dashboard/gestion-grupos-acceso-temporal/"} transition='glide-right'>
                                                <Button
                                                    style={{ width: "100%", height: 52, fontSize: 13 }}
                                                    variant="outlined"
                                                    color={"secondary"}
                                                    size="medium"
                                                    onClick={this.cancelAddNewGroup.bind(this)}
                                                >
                                                    <b>Cancelar</b>
                                                </Button>
                                            </Link>

                                        </Grid>


                                        <Grid item xs={6}>
                                            {this.state.showButtonAlertError ?
                                                <Button
                                                    style={{ width: "100%", height: 52, fontSize: 13 }}
                                                    variant="outlined"
                                                    color={"primary"}
                                                    size="medium"
                                                    disabled={this.state.userList.length > 0 && this.state.valueNameGroupNameEdit !== "" || this.state.userList.length > 0 && this.state.valueNameGroupName !== "" ? false : true}
                                                    // style={{ marginLeft: 10 }}
                                                    onClick={this.onHandleClickAddNewGroup.bind(this)}
                                                >
                                                    <b>Aceptar</b>
                                                </Button> :
                                                <Link to={this.inIframe() ? "/gestion-grupos-acceso-temporal/" : "/dashboard/gestion-grupos-acceso-temporal/"} transition='glide-right'>
                                                    <Button
                                                        style={{ width: "100%", height: 52, fontSize: 13 }}
                                                        variant="outlined"
                                                        color={"primary"}
                                                        size="medium"
                                                        disabled={this.state.userList.length > 0 && this.state.valueNameGroupNameEdit !== "" || this.state.userList.length > 0 && this.state.valueNameGroupName !== "" ? false : true}
                                                        // style={{ marginLeft: 10 }}
                                                        onClick={this.onHandleClickAddNewGroup.bind(this)}
                                                    >
                                                        <b>Aceptar</b>
                                                    </Button>
                                                </Link>
                                            }

                                        </Grid>


                                        {/* <Grid item xs={6}>

                    </Grid> */}
                                    </Grid>
                                    {/* <DialogView handleOnclickAcceptDialogView={this.handleOnclickAcceptDialogView} textForDialog={this.state.textForDialog} onOpenDialogView={this.onOpenDialogView} onCloseDialogView={this.onCloseDialogView} dialogViewShow={this.state.dialogViewShow} /> */}
                                </div >
                            </div>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                open={this.state.snackBarAlertIsOpen}
                                // onClose={handleClose}
                                message={this.state.messageToSnackBarAlert}
                            />
                        </Route>

                    </Navigation>
                </Router>






            </div>
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(ControlTemporalAccessGroup));