import React, { Component } from 'react';
import config from '../../../config';
import { Modal, Backdrop, Fade } from '@material-ui/core/';
import { TextField } from '@material-ui/core/';
import DatePicker from "react-datepicker";
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Select, { components } from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import BeatLoader from 'react-spinners/BeatLoader';
// import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';

import "react-datepicker/dist/react-datepicker.css";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import { withStyles } from "@material-ui/core/styles";

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isSameDay, startOfToday, endOfDay } from 'date-fns';


const styles = theme => ({
    rootList: {
      width: '50%',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 100,
      border: "1px solid #D3D3D3",
      borderRadius: "5px"
    },
    rootList2: {
      width: '50%',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 100,
      border: "1px solid #D3D3D3",
      borderRadius: "5px",
      padding: 21,
      paddingLeft: 0
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
    root: {
      flexGrow: 1,
      minWidth: "50%",
      // '& .MuiTextField-root': {
      //     margin: theme.spacing(1),
      //     width: '25ch',
      // },
      margin: "auto",
      padding: "15px",
      overflow: "scroll"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    containerTextField: {
      '& .MuiTextField-root': {
        // margin: theme.spacing(1),
        width: "100%",
  
      },
      '& .MuiInputBase-root': {
        fontSize: "14px"
      },
      '& .MuiInputBase-input': {
        height: "0.7rem"
      }
    },
    containerTextFieldArea: {
      '& .MuiTextField-root': {
        // margin: theme.spacing(1),
        width: "100%",
  
      },
      '& .MuiInputBase-root': {
        fontSize: "14px"
      }
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          fontSize: "20px"
        },
        input: {
          padding: "16px 14px",
          height: 56,
          boxSizing: "border-box"
        }
      },
      '& .MuiInputLabel': {
        root: {
          fontSize: "20px"
        },
        outlined: {
          transform: "translate(14px, 19px) scale(1)"
        }
      }
    }
  });

const spinnerCss = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginLeft: '-30px'
}

const groupStyles = {
    // borderRadius: '5px',
    // background: '#fff',
    color: '#fff',
    background: "#bf2349",
    padding: '5px 0px',
    display: 'flex',
};

const GroupHeading = props => (
    <div style={{ ...groupStyles, display: 'flex', alignItems: 'center' }}>
      <components.GroupHeading {...props} />
      <Tooltip title={"groupIcon"} >
        <GroupOutlinedIcon styles={{ color: "#fff", fontSize: "12px" }} />
      </Tooltip>
    </div>
  );

class ModalView extends Component {
    state = {
        isLoading: true,
        selectedObj: {},
        selectedDate: new Date(),
        startDate: new Date(),
        endDate: new Date(),
        maxDate: this.addDaysToDateMax.bind(this),
        email: "",
        accessToken: "",
        groupedOptions: [],

        errorEmail: false,
        errorEmailText: "",
        errorDateStart: false,
        errorDateTextStart: "",
        errorDateEnd: false,
        errorDateTextEnd: "",
        selectedVehicles: [],
        description: "",
        marginTopTextArea: "30px",

        dateAndHourStart: new Date(),
        dateAndhourEnd: new Date(),

        listVehiclesNames: [],

        maxMinTimeSelect: "",
        minTime: isSameDay(new Date(), new Date()) ? new Date() : startOfToday(),
        boolDisabled: true,
        isEmpty: false,
        selectedValue: "email",
        groupsToNewAccess: [],
        groupSelectedToNewAccess: [],
        listGroupName: []
    }

    async componentDidMount() {
        document.getElementById("pin").style.display = "none";
        let isEmpty = this.isEmpty(this.props.selectedObj);
    
        if (!isEmpty) {
            if (Object.prototype.toString.call(this.props.selectedObj[0].inicio) !== '[object Date]') {
                let dateAndHourStart = this.formatDateAndHour(this.props.selectedObj[0].inicio);
                let dateAndhourEnd = this.formatDateAndHour(this.props.selectedObj[0].fin);
                let resultValidate = this.validateEmail(this.props.selectedObj[0].email);
    
                let newState = {
                    dateAndHourStart: dateAndHourStart,
                    dateAndhourEnd: dateAndhourEnd,
                    isEmpty: false,
                    isGroupFromView: resultValidate ? false : true,
                    selectedValue: resultValidate ? null : "grupo"
                };
    
                this.setState(newState);
            }
    
            this.setState({
                selectedObj: this.props.selectedObj[0],
                marginTopTextArea: "45px"
            });
        }
    
        let resultVehiclesName = [];
        let listGroupWithVehicles = [];
        let groupTags = [];
        let listGroupName = [];
    
        if (!isEmpty) {
            this.props.assetsInfo.forEach(element => {
                this.props.selectedObj[0].vehiculosIds.forEach(item => {
                    if (item === element.assetsInfoId) {
                        resultVehiclesName.push({...element}); // Crear copias de elementos
                    }
                });
            });
    
            this.props.groupsNewAccessList.forEach(element => {
                if (element.label === this.props.selectedObj[0].email) {
                    listGroupName.push({...element}); // Crear copias de elementos
                }
            });
    
            this.props.assetsTags.forEach(assetTag => {
                const clonedAssetTag = {...assetTag}; // Crear copias de objetos
                clonedAssetTag.assetsTagInfo = [];
    
                resultVehiclesName.forEach(assetInfo => {
                    if (assetInfo.assetsTagsId === clonedAssetTag.assetsTagsId) {
                        clonedAssetTag.assetsTagInfo.push({
                            value: assetInfo.assetsInfoId, label: assetInfo.assetsInfoName
                        });
                    }
                });
    
                if (clonedAssetTag.assetsTagInfo.length > 0) {
                    listGroupWithVehicles.push({
                        "label": clonedAssetTag.assetsTagName,
                        "options": [...clonedAssetTag.assetsTagInfo] // Crear copias de arrays
                    });
                }
            });
        } else {
            this.props.assetsTags.forEach(assetTag => {
                const clonedAssetTag = {...assetTag}; // Crear copias de objetos
                clonedAssetTag.assetsTagInfo = [];
    
                this.props.assetsInfo.forEach(assetInfo => {
                    if (assetInfo.assetsTagsId === clonedAssetTag.assetsTagsId) {
                        clonedAssetTag.assetsTagInfo.push({
                            value: assetInfo.assetsInfoId, label: assetInfo.assetsInfoName
                        });
                    }
                });
    
                groupTags.push({
                    "label": clonedAssetTag.assetsTagName,
                    "options": [...clonedAssetTag.assetsTagInfo] // Crear copias de arrays
                });
            });
        }
    
        const groups = this.props.groupsNewAccessList;

    
        this.setState({
            groupedOptions: this.props.listVehiclesGroups.length > 0 ? this.props.listVehiclesGroups : [...groupTags], // Crear copia de array
            isLoading: false,
            marginTopTextArea: "45px",
            listVehiclesNames: [...listGroupWithVehicles], // Crear copia de array
            maxDate: new Date(),
            isEmpty: true,
            groupsToNewAccess: groups !== undefined ? [...groups] : [], // Crear copia de array
            listGroupName: [...listGroupName] // Crear copia de array
        });
    }
    

    async getAssetsTags() {

        let responseGroups = await fetch(`${config.apiGateway.URL}AssetsTags`, {
            headers: {
                'accessToken': this.props.accessToken
            }
        });

        if (responseGroups.ok) { // if HTTP-status is 200-299
            let jsonGroups = await responseGroups.json();
            // localStorage.setItem("jsonGroups", JSON.stringify(jsonGroups));
            return jsonGroups;

        } else {
            alert("HTTP-Error: " + responseGroups.status);
            return;
        }
    }

    async getAssetsInfo() {
        let responseInfo = await fetch(`${config.apiGateway.URL}AssetsInfo`, {
            headers: {
                'accessToken': this.props.accessToken
            }
        });

        if (responseInfo.ok) {
            let jsonInfo = await responseInfo.json();
            localStorage.setItem("jsonInfo", JSON.stringify(jsonInfo));
            // console.log(jsonInfo);
            return jsonInfo;

        } else {
            alert("HTTP-Error: " + responseInfo.status);
            return;
        }
    }

    formatDateAndHour(dateObj) {
        let dateAndHour = dateObj.split(" ");
        let date = dateAndHour[0].split("/");
        date = date[1] + "/" + date[0] + "/" + date[2];

        let formatDateAndHour = date + " " + dateAndHour[1];
        let resultFormatDateAndHour = new Date(formatDateAndHour);

        return resultFormatDateAndHour;
    }

    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    formatDate(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        let day = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
        let month = ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1));
        // var ampm = hours >= 12 ? 'pm' : 'am';
        // hours = hours % 12;
        hours = hours === 0 ? hours += "0" : hours;
        // hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ':' + '00';
        // return date.getFullYear() + "-" + month + "-" + day + " " + strTime
        return date.getFullYear() + "-" + month + "-" + day + " " + strTime
    }

    handleDateStart(e) {
        let boolDisabled = true;
        // if (this.state.email !== "" && this.state.selectedVehicles.length > 0 && this.validateDateEnd() === true) {
        //     boolDisabled = false
        // } else {
        //     boolDisabled = true;
        // }


        this.setState({ startDate: e, minTime: e, endDate: e, maxDate: e }, () => {
            if (this.state.selectedValue === "email") {
                if (this.state.email !== "" && this.state.selectedVehicles.length > 0 && !this.validateDateStart()) {
                    boolDisabled = false
                } else {
                    boolDisabled = true;
                }
            } else {

                if (!this.isEmpty(this.state.groupSelectedToNewAccess.length) && this.state.selectedVehicles.length > 0 && !this.validateDateStart()) {
                    boolDisabled = false
                } else {
                    boolDisabled = true;
                }
            }

            this.setState({ boolDisabled: boolDisabled });
        });
    }

    handleDateEnd(e) {

        let endDate = e;
        let startDate = this.state.startDate;

        let boolDisabled = true;


        let newEndDate = "";
        // console.log(e);

        if (endDate > startDate) {
            newEndDate = startOfToday();
        } else if (isSameDay(new Date(), new Date())) {
            newEndDate = this.state.startDate;
        }
        // console.log(newEndDate);

        this.setState({ endDate: e, minTime: newEndDate, boolDisabled: boolDisabled }, () => {

            if (this.state.selectedValue === "email") {
                if (this.state.email !== "" && this.state.selectedVehicles.length > 0 && this.state.endDate >= this.state.startDate) {
                    boolDisabled = false
                } else {
                    boolDisabled = true;
                }
            } else {
                if (!this.isEmpty(this.state.groupSelectedToNewAccess.length) && this.state.selectedVehicles.length > 0 && this.state.endDate >= this.state.startDate) {
                    boolDisabled = false
                } else {
                    boolDisabled = true;
                }

            }
            this.setState({ boolDisabled: boolDisabled });
        });
    }

    handleOnclickAcceptNewAccess = () => {
        // console.log("entra");
        this.validateData();
        // this.props.onClickHandleModalView();
    }

    handleOnclicCancelViewAccess = () => {
        this.props.onClickHandleModalView();
    }

    handleOnChangeEmail = (e) => {
        let boolDisabled = true;
        if (e.target.value.length <= 0) {
            boolDisabled = true;
            this.setState({ email: e.target.value, boolDisabled: boolDisabled });
            return;
        }
        if (e.target.value !== "" && this.state.selectedVehicles.length > 0 && this.state.startDate >= this.state.endDate) {
            boolDisabled = false
        } else {
            boolDisabled = true;
        }
        this.setState({ email: e.target.value }, () => {
            if (this.state.selectedValue === "email") {
                if (this.state.email !== "" && this.state.selectedVehicles.length > 0 && this.state.endDate >= this.state.startDate) {
                    boolDisabled = false
                } else {
                    boolDisabled = true;
                }
            } else {
                if (!this.isEmpty(this.state.groupSelectedToNewAccess.length) && this.state.selectedVehicles.length > 0 && this.state.endDate >= this.state.startDate) {
                    boolDisabled = false
                } else {
                    boolDisabled = true;
                }

            }
            this.setState({ boolDisabled: boolDisabled });
        });
    }

    handleOnChangeSelectGroupNewAccess = (newValue) => {
        this.setState({
            groupSelectedToNewAccess: newValue
        })
    }

    handleOnchangeSelectVehices = (newValue) => {
        let boolDisabled = true;
        let listIds = [];
        if (newValue === null) {
            newValue = [];
        } else {

            newValue.forEach(element => {
                listIds.push(element.value);
            });

        }

        // if (this.state.email === "") {
        //     boolDisabled = true;
        // } else if(this.state.selectedVehicles.length === 0){
        //     boolDisabled = true;
        // }else if(this.validateDateEnd() === false){
        //     boolDisabled = true;
        // } else {
        //     boolDisabled = false;
        // }

        this.setState({
            selectedVehicles: listIds,
        }, () => {
            if (this.state.selectedValue === "email") {
                if (this.state.email !== "" && this.state.selectedVehicles.length > 0 && this.state.endDate > this.state.startDate) {
                    boolDisabled = false
                } else {
                    boolDisabled = true;
                }
            } else {
                if (!this.isEmpty(this.state.groupSelectedToNewAccess.length) && this.state.selectedVehicles.length > 0 && this.state.endDate >= this.state.startDate) {
                    boolDisabled = false
                } else {
                    boolDisabled = true;
                }


            }

            this.setState({ boolDisabled: boolDisabled });
        });
    }

    handleOnChangeDescription = (e) => {
        this.setState({
            description: e.target.value
        });
    }

    validateData() {
        if (this.state.selectedValue === "email") {
            var error = "";
            if (!this.validateEmail(this.state.email)) {
                error = "Su email no tiene un formato correcto.";

                this.setState({
                    errorEmailText: error,
                    errorEmail: true
                });
                return;
            }

            this.setState({
                errorEmailText: "",
                errorEmail: false
            });
        }

        this.saveData();
    }

    validateDateStart() {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;

        if (endDate > startDate) {
            return true;
        } else if (endDate === startDate) {
            return true;
        } else {
            return false;
        }
    }

    validateDateEnd() {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        if (endDate > startDate) {
            return true;
        } else {
            return false;
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    async saveData() {
        let objectToSave = {};
        if (this.state.selectedValue === "email") {
            objectToSave = {
                Email: this.state.email,
                Observations: this.state.description,
                StartDate: this.state.startDate,
                EndDate: this.state.endDate,
                Vehicles: this.state.selectedVehicles,
            };
        } else {
            objectToSave = {
                Email: this.state.email,
                Observations: this.state.description,
                StartDate: this.state.startDate,
                EndDate: this.state.endDate,
                Vehicles: this.state.selectedVehicles,
                GroupId: this.state.groupSelectedToNewAccess.value
            };
        }


        const settings = {
            method: 'POST',
            headers: {
                'accessToken': this.props.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(objectToSave)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };

        const fetchResponse = await fetch(`${config.apiGateway.URL}AccessControl`, settings);
        if (fetchResponse.ok) {
            if (fetchResponse !== null) {
                try {
                    await fetchResponse.json();
                } catch (error) {
                    console.log(error);
                }
            }
        } 
        // else {
        //     this.goToPageErr();
        //     // alert("HTTP-Error: " + fetchResponse.status);
        // }

        let statusResult = {
            statusText: "success",
            statusMessage: ["Acceso(s) añadido(s) correctamente."],
            statusNumPedido: []
        }

        this.props.handleOnCloseModalAndShowAlert(statusResult);
    }

    maxMinTime() {
        // let r = ((new Date(), 30), 17);
        this.setState({
            dateAndhourEnd: ((new Date(), 30), 17)
        });
    }

    goToPageErr = async () => {
        // this.props.userHasAuthenticated(false);
        // console.log("entra");
        this.props.history.push({
            pathname: '/ExpireKeyErr/',
            state: {
                userDataInfo: '',
            }
        });
    }

    validateDataDisabled() {
        let bool = false;
        if (this.state.selectedVehicles.length < 0 && this.state.email === "" && this.validateDateEnd() !== false) {
            bool = false;

        } else if (this.state.selectedVehicles.length > 0 && this.state.email !== "" && this.validateDateEnd() === false) {
            bool = true;
        }
    }

    addDaysToDateMax() {

        let starDate = this.state.startDate;
        // Milisegundos de 3 días mas 
        // (días * 24 horas * 60 minutos * 60 segundos * 1000 milésimas de segundo) 
        let dateWithFourDaysMore = starDate.getTime() + (4 * 24 * 60 * 60 * 1000);
        let dateWithFourDaysMoreFormated = new Date(dateWithFourDaysMore);
        return dateWithFourDaysMoreFormated;
    }

    handleOnChangeRadioButtons(e) {
        this.setState({
            selectedValue: e.target.value
        });
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        // if (this.state.email === "") {
        //     //     boolDisabled = true;
        // } else if(this.state.selectedVehicles.length === 0){
        //     boolDisabled = true;
        // } else if(this.validateDateEnd() === false){
        //     boolDisabled = true;
        // } else {
        //     boolDisabled = false;
        // }

        return (

            <div className={classes.root} >
                {this.state.isLoading ?
                    <div className="loadingSpinner">
                        <BeatLoader css={spinnerCss} size={15} margin={4} color={"#bf2349"} />
                    </div>
                    :

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className="modalCustom"
                        open={this.props.modalViewShow}
                        style={{ overflow: "scroll" }}
                        onClose={this.props.onClickHandleModalView.bind(this)}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}>
                        <Fade id="fade" in={this.props.modalViewShow}>

                            <Grid style={{ background: "#fff", padding: "15px", paddingBottom: "30px", margin: "auto !important" }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <div className={classes.paper}>{t('titleModal')}</div>
                                </Grid>
                                {this.props.selectedObj.length !== 1 ?
                                    <Grid item xs={12} md={12}>
                                        <FormControlLabel
                                            value="email"
                                            control={<Radio value="email" checked={this.state.selectedValue === "email"} onChange={this.handleOnChangeRadioButtons.bind(this)} color="#bf2349" />}
                                            label="Email"
                                            labelPlacement="start"
                                            disabled={this.props.selectedObj.length > 0 ? true : false}
                                        />
                                        <FormControlLabel
                                            style={{ marginLeft: 20 }}
                                            value="grupo"
                                            control={<Radio value="grupo" onChange={this.handleOnChangeRadioButtons.bind(this)} checked={this.state.selectedValue === "grupo"} color="#bf2349" />}
                                            label="Grupo"
                                            labelPlacement="start"
                                            disabled={this.props.selectedObj.length > 0 ? true : false}

                                        />
                                    </Grid> :
                                    null
                                }

                                {this.state.selectedValue === "email" ?

                                    <Grid className={classes.containerTextField} item xs={12} md={6}>

                                        <div>
                                            <label className="labelModalView">Email</label>
                                            <TextField
                                                // style={{ width: "100%", height: "49px" }}

                                                placeholder="Email"
                                                variant="outlined"
                                                size="medium"
                                                value={this.state.selectedObj.email}
                                                disabled={this.props.selectedObj.length > 0 ? true : false}
                                                onChange={this.handleOnChangeEmail.bind(this)}
                                                error={this.state.errorEmail}
                                                id="outlined-error"
                                                helperText={this.state.errorEmailText}
                                                required
                                                margin="none"
                                            />
                                        </div>


                                    </Grid>
                                    :
                                    this.props.selectedObj.length === 1 ?
                                        <Grid item xs={12} md={6}>
                                            <label className="labelModalView labelSelect">Listado de grupos</label>
                                            <List dense={true} className={classes.rootList}>
                                                {this.state.listGroupName.map(element =>
                                                    <ListItem>
                                                        <ListItemText style={{ padding: 14, paddingLeft: 0 }}
                                                            primary={element.label}

                                                        />
                                                    </ListItem>
                                                )}

                                            </List>
                                        </Grid>

                                        :

                                        <Grid className={classes.containerTextField} item xs={12} md={6}>

                                            <div>
                                                <label className="labelModalView">Grupo</label>
                                                <Select
                                                    // defaultValue={this.state.colourOptions[1]}
                                                    options={this.state.groupsToNewAccess}

                                                    styles={{
                                                        control: base => ({
                                                            ...base,
                                                            height: 44,
                                                            minHeight: 44
                                                        }),

                                                    }}
                                                    onChange={this.handleOnChangeSelectGroupNewAccess.bind(this)}
                                                    placeholder="Listado de grupos"
                                                    // components={{ ValueContainer }}
                                                    isSearchable={false}
                                                />
                                            </div>


                                        </Grid>
                                }

                                <Grid item xs={12} md={6}>
                                    <label style={{color:"#000"}} className="labelModalView labelSelect">{t('modalView.listVehicles')}</label>
                                    {this.props.selectedObj.length === 1 ?
                                        <List dense={true} className={classes.rootList} subheader={<li />}>
                                            {this.state.listVehiclesNames.map(tagGroup => (
                                                <li key={`section-${tagGroup.label}`} className={classes.listSection}>
                                                    <ul className={classes.ul}>
                                                        <ListSubheader>{tagGroup.label}</ListSubheader>
                                                        {tagGroup.options.map(item => (
                                                            <ListItem style={{color:"#000"}} key={`item-${tagGroup.label}-${item.label}`}>
                                                                <ListItemText style={{color:"#000"}} primary={item.label} />
                                                            </ListItem>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ))}
                                        </List>
                                        :
                                        <Select
                                            // defaultValue={this.state.colourOptions[1]}
                                            options={this.state.groupedOptions}
                                            components={{ GroupHeading }}
                                            styles={{
                                                control: base => ({
                                                    ...base,

                                                    minHeight: 44
                                                }),
                                                groupHeading: base => ({
                                                    ...base,
                                                    flex: '1 1',
                                                    background: 'transparent',
                                                    color: '#ffffff',
                                                    margin: 0,
                                                    alignItems: "center",
                                                    fontWeight: 'bold',
                                                    justifyContent: "center"
                                                  }),
                                                  
                                               
                                            }}
                                            isMulti
                                            onChange={this.handleOnchangeSelectVehices.bind(this)}
                                            placeholder={t('modalView.listVehicles')}
                                            
                                            isSearchable={true}
                                        />
                                    }

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <label className="labelModalView">{t('modalView.dateStart')}</label>
                                    {this.state.isEmpty ?
                                        <DatePicker
                                            className="inputDate"
                                            selected={!this.isEmpty(this.props.selectedObj) ? this.state.dateAndHourStart : this.state.startDate}
                                            onChange={this.handleDateStart.bind(this)}
                                            showTimeSelect
                                            // filterTime={filterPassedTime}
                                            dateFormat="dd/MM/yyyy HH:mm:ss"
                                            minDate={new Date()}
                                            timeFormat="HH:mm:ss"
                                            disabled={this.props.selectedObj.length > 0 ? true : false}
                                        />
                                        :
                                        <DatePicker
                                            className="inputDate"
                                            selected={!this.isEmpty(this.props.selectedObj) ? this.state.dateAndHourStart : this.state.startDate}
                                            onChange={this.handleDateStart.bind(this)}
                                            showTimeSelect
                                            // filterTime={filterPassedTime}
                                            dateFormat="dd/MM/yyyy HH:mm:ss"
                                            timeFormat="HH:mm:ss"
                                            disabled={this.props.selectedObj.length > 0 ? true : false}
                                        />
                                    }



                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <label className="labelModalView" >{t('modalView.dateEnd')}</label>
                                    {this.state.isEmpty ?
                                        <DatePicker

                                            className="inputDate"
                                            selected={!this.isEmpty(this.props.selectedObj) ? this.state.dateAndhourEnd : this.state.endDate}
                                            onChange={this.handleDateEnd.bind(this)}
                                            showTimeSelect
                                            // filterTime={filterPassedTime}
                                            dateFormat="dd/MM/yyyy HH:mm:ss"
                                            timeFormat="HH:mm:ss"
                                            disabled={this.props.selectedObj.length > 0 ? true : false}
                                            minDate={this.state.startDate}
                                            maxDate={this.state.startDate !== undefined ? this.addDaysToDateMax() : new Date()}
                                            minTime={this.state.minTime}
                                            maxTime={endOfDay(new Date())}
                                        // minTime={this.state.dateAndhourEnd}
                                        >

                                        </DatePicker>
                                        :
                                        <DatePicker
                                            className="inputDate"
                                            selected={!this.isEmpty(this.props.selectedObj) ? this.state.dateAndhourEnd : this.state.endDate}
                                            // filterTime={filterPassedTime}
                                            dateFormat="dd/MM/yyyy HH:mm:ss"
                                            timeFormat="HH:mm:ss"
                                            disabled={this.props.selectedObj.length > 0 ? true : false}

                                        // minTime={this.state.dateAndhourEnd}
                                        >

                                        </DatePicker>
                                    }
                                </Grid>
                                <Grid item xs={12} md={12} className={classes.overrides}>
                                    {/* <div className="observationTextAreaContainer">
                                        <label className="labelModalView observationTextAreaContent" htmlFor="textAreaModal">{t('placeholders.observations')}</label>
                                        <TextareaAutosize onChange={this.handleOnChangeDescription.bind(this)} disabled={this.props.selectedObj.length === 1 ? true : false} value={this.state.selectedObj.observaciones} maxLength={255} style={{ marginTop: this.state.marginTopTextArea, height: "80%" }} className="observationTextAreaContent textArea" id="textAreaModal" aria-label="empty textarea" placeholder={t('placeholders.observations')} />

                                    </div> */}
                                    <label className="labelModalView observationTextAreaContent" htmlFor="textAreaModal">{t('placeholders.observations')}</label>
                                    <TextField
                                        onChange={this.handleOnChangeDescription.bind(this)}
                                        disabled={this.props.selectedObj.length === 1 ? true : false}
                                        value={this.state.selectedObj.observaciones} 
                                        placeholder={t('placeholders.observations')}
                                        id="outlined-multiline-static"
                                        maxLength={255}
                                        // label="Observaciones"
                                        multiline
                                        rows={4}
                                        // defaultValue=""
                                        variant="outlined"
                                        InputProps={{ style: { fontSize: 14, marginTop: 10 } }}


                                    />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <button onClick={this.handleOnclicCancelViewAccess.bind(this)} className="btn btn-default btnTemporalAccess">{t('modalView.btnCancel')}</button>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <button onClick={this.handleOnclickAcceptNewAccess.bind(this)} disabled={this.state.boolDisabled} className="btn btn-default btnTemporalAccess">{t('modalView.btnAccept')}</button>
                                </Grid>
                            </Grid>
                        </Fade>
                    </Modal>

                }
            </div>
        )
    }
}

const ValueContainer = ({ children, getValue, ...props }) => {
    let maxToShow = 2;
    var length = getValue().length;
    let displayChips = React.Children.toArray(children).slice(0, maxToShow);
    let title = getValue()
        .slice(maxToShow)
        .map((x) => x.label)
        .join(", ");
    let shouldBadgeShow = length > maxToShow;
    let displayLength = length - maxToShow;
    return (
        <components.ValueContainer {...props}>
            {!props.selectProps.inputValue && displayChips}
            {!!shouldBadgeShow && (
                <div className="root" title={title}>
                    {/* {`+ ${displayLength} Opcione${length !== 1 ? "s" : ""} seleccionadas`} */}
                    {`+ ${displayLength} `}
                </div>
            )}
        </components.ValueContainer>
    );
};
export default withStyles(styles, { withTheme: true })(ModalView);
// export default ModalView;
