import React from 'react';
import { TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import CarsLVCAN from '../../../containers/files/CarsLVCAN200_list_2023_06_29_en.json';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  table: {
    minWidth: 200,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const allOptions = CarsLVCAN;

let ListWithoutRepeats = () => allOptions.Cars.filter((option, index, self) =>
  index === self.findIndex((t) => (
    t.Brand === option.Brand
  ))
);
let ListWithoutRepeatsModels = () => allOptions.Cars.filter((option, index, self) =>
  index === self.findIndex((t) => (
    t.Model === option.Model
  ))
);

const DialogFullScreenLVCAN = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [valueModel, setValueModel] = React.useState(null);
  const [inputValueModel, setInputValueModel] = React.useState('');
  const [AllValuesModels, setAllValuesModels] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  const handleOnChange = (event, newValue) => {
    setValue(newValue);
    if (newValue !== null) {
      let filtered = ListWithoutRepeatsModels().filter(x => x.Brand === newValue.Brand);
      setAllValuesModels(filtered);
      setRows([]);
      setValueModel(null)
    } else {
      setAllValuesModels([]);
      setRows([]);
      setValueModel(null)
    }
  }

  const handleOnChangeModel = (event, newValue) => {
    setValueModel(newValue)
    if (newValue !== null) {
      let rows = [];
      let filtered = allOptions.Cars.filter(x => x.Model === newValue.Model);
      for (let index = 0; index < filtered.length; index++) {
        const element = filtered[index];
        let id = "id" + Math.random().toString(16).slice(2)
        rows.push(createData(id, element.year, element.program));
      }
      setRows(rows);
    } else {
      setRows([]);
    }
  }
  const createData = (id, year, program) => {
    return { id, year, program };
  }
  return (
    <div>
      <Dialog fullScreen open={props.isOpen} onClose={props.isClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.isClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              CONSULTA DE DATOS LVCAN200
            </Typography>
          </Toolbar>
        </AppBar>
        <Container style={{ marginTop: 50 }} maxWidth="md">
          <Autocomplete
            id="combo-box-demo"
            value={value}
            onChange={(event, newValue) => {
              handleOnChange(event, newValue)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={ListWithoutRepeats()}
            getOptionLabel={(option) => option.Brand}
            style={{ width: 330, margin: "auto" }}
            renderInput={(params) => <TextField {...params} label="Seleccione una marca" variant="outlined" />}
          />
          <Autocomplete
            id="combo-box-demo"
            value={valueModel}
            onChange={(event, newValue) => {
              handleOnChangeModel(event, newValue)
            }}
            inputValue={inputValueModel}
            onInputChange={(event, newInputValue) => {
              setInputValueModel(newInputValue);
            }}
            options={AllValuesModels}
            getOptionLabel={(option) => option.Model}
            style={{ width: 330, margin: "auto", marginTop: 20 }}
            renderInput={(params) => <TextField {...params} label="Seleccione un modelo" variant="outlined" />}
          />
          <TableContainer style={{ marginTop: 20 }} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <TableCell>Año</TableCell>
                  <TableCell >Nº Programa</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.year}
                    </TableCell>
                    <TableCell >{row.program}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Dialog>
    </div>
  )
}

export default DialogFullScreenLVCAN
