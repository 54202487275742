import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Link, } from "react-tiger-transition";

import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

import Config from '../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const top100Films = [
  { title: 'No Iniciado' },
  { title: 'Completado' },
]

function TaskOrderActivity(props) {
  const [activities, setActivities] = React.useState(null);
  const classes = useStyles();

  //const [filteredData, setFilteredData] = React.useState(activities);
  const [selectedStatus, setSelectedStatus] = React.useState(top100Films[0]);
  const selectedOt = JSON.parse(localStorage.getItem("selectedOt"));
  const [open, setOpen] = React.useState(false);


  React.useEffect(() => {
    if (props.location.status !== undefined) {
      setSelectedStatus(props.location.status);
    }
    const handlePopstate = () => {
      // Aquí puedes realizar las acciones que desees cuando el usuario navega hacia atrás
      // Puede ser redirigir a una página específica, mostrar un mensaje, etc.
    };

    window.addEventListener('popstate', handlePopstate);



    const GetActivities = async () => {
      try {
        //const token = 'TU_TOKEN_JWT'; // Reemplaza con tu token JWT
        const method = `Installers/Activities?purchaseOrder=${selectedOt.OrderId}`;
        const response = await fetch(`${Config.apiGateway.URL}${method}`, {
          method: 'GET',
          // headers: {
          //   'Authorization': `Bearer ${token}`
          // }
        });

        if (response.ok) {
          const jsonData = await response.json();
          jsonData.forEach(element => {
            if (element.status === "Completed") {
              element.status = "Completado"
            } else if (element.status === "Not Started") {
              element.status = "No Iniciado"
            }
          });
          setActivities(jsonData);
        } else {
          console.error('Error en la respuesta:', response.status);
        }
      } catch (error) {
        console.error('Error en la llamada:', error);
      }
    }
    GetActivities();
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };

  }, [props, selectedOt.OrderId]);
  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    if (status !== null) {

      // const filtered = status.title.length === 0
      //   ? activities
      //   : activities.filter(item => status.title === item.status);

      // console.log(filtered)
      // setFilteredData(filtered);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(!open);
  };

  function clickHandle(item) {
    if (item.status !== "Completado") {
      localStorage.setItem('activityIdCRM', item.activityid);
      const userDataInfo = JSON.parse(localStorage.getItem("userDataInfo"));
      localStorage.setItem("coming", "taskOrderActivity");
      localStorage.setItem("serialNumber", item.subject);
      props.history.push({
        pathname: '/dashboard/instaladores',
        state: {
          coming: "taskOrderActivity",
          serialNumber: item.subject,
          selectedOt: props.selectedOt === false ? props.location.selectedOt : props.selectedOt,
          status: selectedStatus,
          userDataInfo: userDataInfo,
          activityid: item.activityid
        }
      });
    } else {
      handleClose("", "");
    }

  }
  return (
    <div >
      <List style={{ overflowY: "hidden" }} subheader={<ListSubheader>
        {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}

        <ListItem button component={Link} key={"11"} transition='glide-right' to={{
          pathname: '/dashboard/ordenes-trabajo/detail/',

        }} >
          <ListItemIcon>

            <ArrowBackIosRoundedIcon />
          </ListItemIcon>
          <ListItemIcon>

            <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" />

          </ListItemIcon>

        </ListItem>
        <p style={{ position: "absolute", top: "13px", right: "22px" }}> Actividades</p>

      </ListSubheader>
      }>
      </List>
      <Autocomplete
        value={selectedStatus}
        id="combo-box-demo"
        onChange={(event, newValue) => handleStatusFilter(newValue)}
        options={top100Films}
        getOptionLabel={(option) => option.title}
        style={{ width: 300, margin: "auto" }}
        renderInput={(params) => <TextField {...params} label="Estado" variant="outlined" />}
      />
      {activities !== null && selectedStatus != null &&
        <List component="nav" aria-label="main mailbox folders">
          {activities.filter(item => selectedStatus.title.includes(item.status))
            .map((item) => (
              <div key={item.activityid}>
                <ListItem button onClick={() => clickHandle(item)}
                >
                  <ListItemText primary={item.subject} />
                  <Divider orientation="vertical" flexItem />
                  {item.status === "Completado" ?
                    <CheckIcon style={{ color: "white", background: "#32a852", borderRadius: "4px", margin: 10 }} />
                    :
                    <CloseIcon style={{ color: "white", background: "#eb4034", borderRadius: "4px", margin: 10 }} />
                  }

                  <Divider orientation="vertical" flexItem />
                  {item.status === "No Iniciado" &&
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete">
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  }

                </ListItem>
                <Divider />
              </div>

            ))}
        </List>

      }
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          No puede realizar un chequeo a un dispositivo ya chequeado.
        </Alert>
      </Snackbar>
    </div>



  )
}

export default TaskOrderActivity