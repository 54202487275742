import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import config from '../../config';
import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  title: {
    color: '#bf2349', // Color del título
    marginBottom: theme.spacing(2), // Espacio inferior
    textAlign: 'center',
  },
  subTitle: {
    color: '#000', // Color del título
    marginBottom: theme.spacing(2), // Espacio inferior
    textAlign: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 350,
  },
  formControlDescription: {
    margin: theme.spacing(1),
    minWidth: 100,
    width: '100%',
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)', // Ajuste para el texto flotante en foco
      color: '#bf2349', // Cambiar el color del texto flotante en foco
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 8,
    background: '#bf2349', // Cambiar el color de fondo de los botones
    '&:hover': {
      background: '#bf2349', // Cambiar el color de fondo cuando se hace hover
    },
  },
  input: {
    display: 'none',
  },
  focusedInput: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#bf2349', // Cambiar el color del borde cuando el input está en foco
      },
      '&.Mui-focused fieldset': {
        borderColor: '#bf2349', // Cambiar el color del borde cuando el input está en foco
      },
    },
  },
});

class BuzonSugerencias extends Component {
  state = {
    selectedOption: '',
    description: '',
    image: null,
    image64: null,
    name: '',
    email: '',
    phone: '',
    enviado: false, // Agregar un estado para controlar si el formulario ha sido enviado
    emailError: '',
    phoneError: '',
    loading: false,
    snackbarOpen: false,
    textToAlert: ''
  };

  handleSnackbarOpen = (text) => {
    this.setState({ snackbarOpen: true, textToAlert: text});
  };
  
  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snackbarOpen: false });
  };

  handleSelectChange = event => {
    this.setState({ selectedOption: event.target.value });
  };

  handleDescriptionChange = event => {
    const description = event.target.value;
    if (description.length <= 500) {
      this.setState({ description });
    }
  };

  handleImageChange = event => {
    const image = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Al cargar la imagen, conviértela a base64
      //console.log(reader.result);
      this.setState({ image64: reader.result, image: image });
    };

    reader.readAsDataURL(image);
  };

  handleNameChange = event => {
    this.setState({ name: event.target.value });
  };

  handleEmailChange = event => {
    const email = event.target.value;
    this.setState({ email });
    // Validar el correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailError = !emailRegex.test(email) ? 'Por favor, introduce una dirección de correo electrónico válida.' : '';
    this.setState({ emailError });
  };

  handlePhoneChange = event => {
    const phone = event.target.value;
    this.setState({ phone });
    // Validar el número de teléfono
    const phoneRegex = /^[0-9]{9}$/; // Expresión regular para 9 dígitos
    const phoneError = !phoneRegex.test(phone) ? 'Por favor, introduce un número de teléfono válido de 9 dígitos.' : '';
    this.setState({ phoneError });
  };

  handleSubmit = event => {
    event.preventDefault();
    
    const { selectedOption, description, name, email, phone } = this.state;

    // Verificar si todos los campos obligatorios están llenos
    if (!selectedOption || !description || !name || !email || !phone) {
      this.handleSnackbarOpen("Por favor, complete todos los campos obligatorios antes de enviar.");
      //alert('Por favor, complete todos los campos obligatorios antes de enviar.');
      return;
    }

    // Verificar si hay errores de validación en email y teléfono
    if (this.state.emailError || this.state.phoneError) {
      //alert('Por favor, corrija los errores en el formulario antes de enviar.');
      this.handleSnackbarOpen("Por favor, corrija los errores en el formulario antes de enviar.");
      return;
    }
    this.setState({ loading: true });
    this.saveData();
  };

  handleEnviarOtraSugerencia = () => {
    // Reiniciar el estado del formulario para enviar otra sugerencia
    this.setState({
      selectedOption: '',
      description: '',
      image: null,
      name: '',
      email: '',
      phone: '',
      enviado: false, // Cambiar el estado para indicar que se está enviando otra sugerencia
      emailError: '',
      phoneError: '',
    });
  };

  async saveData() {
    const { selectedOption, description, name, email, phone, image64 } = this.state;

    // Crear un objeto con los datos del formulario
    const objectToSend = {
      tipo: selectedOption,
      descripcion: description,
      nombre: name,
      email: email,
      telefono: phone,
      adjuntos: []
    };

    if (image64) {
      //objectToSend.adjuntos.push(image64.split(',')[1]);
      objectToSend.adjuntos.push(image64);
    }

    const jsonData = JSON.stringify(objectToSend);


    const settings = {
      method: 'POST',
      headers: {
        'accessToken': this.props.location.state.AccessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: jsonData

      // `[${newArrayBody.map(service => '[' + service + ']')}]`
    };

    const fetchResponse = await fetch(`${config.apiGateway.URL}Suggestions`, settings);
    if (fetchResponse.ok) {
      if (fetchResponse !== null) {
        try {
          await fetchResponse.json();
          this.setState({ enviado: true, loading: false });

        } catch (error) {
          console.log(error);
        }
      }
    } else {
      // La respuesta no es exitosa
      if (fetchResponse.status === 400) {
        // Manejar el bad request aquí
        this.setState({ loading: false });
        alert(await fetchResponse.json());
      } else {
        // Manejar otros códigos de estado aquí
        console.log('Otro error:', fetchResponse.status);
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { selectedOption, description, name, email, phone, image, enviado, emailError, phoneError, loading, snackbarOpen, textToAlert } = this.state;

    const remainingCharacters = 500 - description.length;

    return (
      <Container maxWidth="md">
        {loading && (
          <Backdrop open={loading} style={{ zIndex: 9999 }}>
            <CircularProgress style={{ color: '#bf2349' }} />
          </Backdrop>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000} // Duración en milisegundos
          onClose={this.handleSnackbarClose} // Función para manejar el cierre del Snackbar
        >
          <Alert onClose={this.handleSnackbarClose} severity="error">
            {textToAlert}
          </Alert>
        </Snackbar>
        {enviado ? (
          <div style={{ textAlign: 'center' }}>
            <h1 style={{ marginTop: 20, fontFamily: 'Arial' }} className={classes.title}>
              Buzon de sugerencias
            </h1>
            <h3 className={classes.subTitle} style={{ marginTop: 40 }}>
              El formulario se ha enviado exitosamente. ¿Desea enviar otra sugerencia?
            </h3>

            <Button
              onClick={this.handleEnviarOtraSugerencia}
              variant="contained"
              color="primary"
              style={{ width: 300, margin: 'auto', marginTop: 10 }}
              className={classes.button}
            >
              Sí, enviar otra sugerencia
            </Button>
          </div>
        ) : (
          <form className={classes.root} onSubmit={this.handleSubmit}>
            <h1 className={classes.title}>Buzon de sugerencias</h1>
            <TextField
              select
              label="Seleccionar *"
              value={selectedOption}
              onChange={this.handleSelectChange}
              variant="outlined"
              className={`${classes.formControlDescription} ${classes.focusedInput}`}
            >
              <MenuItem value="Novedad">Novedad</MenuItem>
              <MenuItem value="Mejora">Mejora</MenuItem>
            </TextField>
            <TextField
              label="Descripción *"
              multiline
              minRows={4}
              value={description}
              onChange={this.handleDescriptionChange}
              variant="outlined"
              className={`${classes.formControlDescription} ${classes.focusedInput}`}
              fullWidth
              inputProps={{ maxLength: 500 }}
            />
            <div style={{ textAlign: 'right', color: remainingCharacters <= 10 ? 'red' : 'inherit' }}>
              {remainingCharacters} caracteres restantes
            </div>
            <input
              accept="image/*,.pdf,.zip"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={this.handleImageChange}
              style={{ display: 'none' }}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="contained-button-file" style={{ marginRight: '8px' }}>
                <Button variant="contained" color="primary" component="span" className={classes.button}>
                  Adjuntar Archivo (solo .zip, .pdf, imágenes)
                </Button>
              </label>
              {image && <span>{image.name}</span>}
            </div>
            <TextField
              label="Nombre *"
              value={name}
              onChange={this.handleNameChange}
              variant="outlined"
              className={`${classes.formControlDescription} ${classes.focusedInput}`}
              fullWidth
            />
            <TextField
              label="Correo *"
              value={email}
              onChange={this.handleEmailChange}
              variant="outlined"
              className={`${classes.formControlDescription} ${classes.focusedInput}`}
              fullWidth
              error={Boolean(emailError)} // Establece el estado de error del TextField
              helperText={emailError} // Muestra el mensaje de error debajo del TextField
            />
            <TextField
              label="Teléfono *"
              value={phone}
              onChange={this.handlePhoneChange}
              variant="outlined"
              className={`${classes.formControlDescription} ${classes.focusedInput}`}
              fullWidth
              error={Boolean(phoneError)} // Establece el estado de error del TextField
              helperText={phoneError} // Muestra el mensaje de error debajo del TextField
            />
            <Button type="submit" variant="contained" color="primary" style={{ width: 100 }} className={classes.button}>
              Enviar
            </Button>
          </form>
        )}
      </Container>
    );
  }
}


export default withRouter(compose(
  withTranslation(),
  withStyles(styles, { withTheme: true }),
)(BuzonSugerencias));
