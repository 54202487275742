import React, { Component } from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
    border: 1px solid lightgrey;
    border-radius: 2px;
    padding: 8px;
    margin-bottom: 8px
`;


export default class HeadersTableList extends Component {

    render() {
        return (
            <Draggable draggableId={this.props.headers.id} index={this.props.index}>
                {(provided, snapshot) => {
                    return (
                        <Container
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            
                              style={{
                                userSelect: "none",
                                padding: 10,
                                margin: "0 0 8px 0",
                                minHeight: "30px",
                                backgroundColor: snapshot.isDragging
                                  ? "#757575"
                                  : "#999999",
                                color: "white",
                                ...provided.draggableProps.style
                              }}
                            className="dndContent">{this.props.headers.content}

                        </Container>
                    )

                }}

            </Draggable>
        )
    }
}
