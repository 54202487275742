import React, { Component } from 'react';
import Config from '../../config';

class CheckToken extends Component {

    componentDidMount() {
        this.checkTokenValidation();
    }

    async checkTokenValidation() {
        let token = this.props.match.params.token;
        const settings = {
            method: 'GET',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            headers: {
                accessToken: token
            },
        };
        let response = "";
        // https://4yahqnkrsh.execute-api.eu-west-1.amazonaws.com/Prod/api/UserLogin/AccessControlToken?token=9EF71295B5519C68CF2164D26C1D88E8
        let responseServices = await fetch(`${Config.apiGateway.URL}UserLogin/AccessControlToken?token=${token}`, settings);
        // // let responseServices = await fetch(config.apiGateway.URL + "/AccessControl?IsActive=false&Email=''&Observations=", settings);

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                // console.log(responseServices);
                try {
                    response = await responseServices.json();
                    this.goToMap(response);
                } catch (error) {
                    response = null;
                }
            }
        } else {
            this.goToPageErr();
        }
    }

    goToMap = async (data) => {
        this.props.userHasAuthenticated(true);
        if (data.email === "") {
            data.email = localStorage.getItem("emailForMap");
            
        }
        //console.log(localStorage.getItem("emailForMap"));
        this.props.history.push({
            pathname: '/map/true',
            state: {
                userDataInfo: data,
                ApiURL: Config.apiGateway.URL
            },
            ApiURL: Config.apiGateway.URL,
            accessToken: data.accessToken,
            origin:true
        });
    }

    goToPageErr = async () => {
        this.props.userHasAuthenticated(false);
        // console.log("entra");
        this.props.history.push({
            pathname: '/ExpireKeyErr/',
            state: {
                userDataInfo: '',
            }
        });
    }

    render() {
        return (
            <div>
                
            </div>
        )
    }
}

export default CheckToken;
