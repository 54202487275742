import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { Navigation, Route } from "react-tiger-transition";
import { BrowserRouter as Router } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import TaskOrderDetail from './TaskOrderDetail';
import Button from '@material-ui/core/Button';

import TaskOrders from './TaskOrders';
import TaskOrderActivity from './TaskOrderActivity';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  root2: {
    // width: 'fit-content',
    justifyContent: "center",

    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));


function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const TaskOrdersRoutes = (props) => {
  const classes = useStyles();
  const [selectedOt, setSelectedOt] = React.useState(false);

  const clickHandle = (item) => {

    setSelectedOt(item);
    localStorage.setItem('selectedOt', JSON.stringify(item));
  }

  return (
    <div className={classes.root} style={{ padding: 30, height: "100%" }}>
      <Router className={classes.root}>
        <Navigation>
          <Route exact path='/dashboard/ordenes-trabajo/'>

            <Grid style={{ margin: "auto", marginTop: 15 }} item xs={12} md={6}>
              <List style={{ overflowY: "hidden" }} subheader={<ListSubheader>
                {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}

                <ListItem button component={Button} transition='glide-right'
                >

                  <ListItemIcon>

                    <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" />

                  </ListItemIcon>

                </ListItem>
                <p style={{ position: "absolute", top: "13px", right: "22px" }}> Mis OTs</p>

              </ListSubheader>
              } className={classes.root2}>
              </List>

              <TaskOrders clickHandle={clickHandle} {...props} />
            </Grid>

          </Route>
          <Route exact path='/dashboard/ordenes-trabajo/detail/'>

            <Grid style={{ margin: "auto", marginTop: 15 }} item xs={12} md={6}>


              <TaskOrderDetail selectedOt={selectedOt} {...props} />
            </Grid>

          </Route>
          <Route exact path='/dashboard/ordenes-trabajo/detail/Activity'>

            <Grid style={{ margin: "auto", marginTop: 15 }} item xs={12} md={6}>


              <TaskOrderActivity selectedOt={selectedOt} {...props} />
            </Grid>

          </Route>
        </Navigation>
      </Router>
    </div>
  )
}

export default TaskOrdersRoutes;
