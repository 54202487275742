import React from 'react';
import { withRouter } from 'react-router-dom';
import DialogCANBus from './DialogCANBus'
import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';

import DialogFullScreenLVCAN from './DialogFullScreenLVCAN';

export const SchemeCANBus = () => {
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [isOpenDialogLVCAN, setIsOpenDialogLVCAN] = React.useState(false);
  const OnclickCopyAndRedirectButton = () => {
    setIsOpenDialog(!isOpenDialog);
    // console.log(isOpenDialog);
    // navigator.clipboard.writeText("VFCbAyLe5XBNqS4h");
    // setTimeout(() => {
    //   window.open("https://drive.teltonika.lt/d/a2a94e4b967f4493b611/", "_blank");
    // }, 100);
  }

  const openDialogLVCAN = () => {
    setIsOpenDialogLVCAN(!isOpenDialogLVCAN)
  }
  return (
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "10px" }}>
      <Button style={{ width: "220px", borderRadius: "20px", marginTop: "40px", fontSize: "1.3rem" }} variant="outlined" onClick={OnclickCopyAndRedirectButton}>Acceder a datos</Button>
      <Button style={{ width: "220px", borderRadius: "20px", marginTop: "40px", fontSize: "1.3rem" }} variant="outlined" onClick={openDialogLVCAN}>Consulta de datos</Button>
      <DialogCANBus isOpenProps={isOpenDialog} isCloseProps={() => setIsOpenDialog(false)} />
      <DialogFullScreenLVCAN isOpen={isOpenDialogLVCAN} isClose={() => setIsOpenDialogLVCAN(false)} />
    </div>
  )
}

export default withRouter(SchemeCANBus);
