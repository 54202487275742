import React, { Component } from 'react';
// import imageBackground from '../../../containers/img/4GMobile_ico.png'
import './ExpireKeyErr.css';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
class ExpireKeyErr extends Component {

    componentDidMount() {
        // if (document.getElementById("wrapper") !== undefined) {
        //     document.getElementById("wrapper").className += "bg";
        // }
        
        


    }
    // e04f5f
    render() {
        const { t } = this.props;
        let languageText = "";
        if (this.props.location.state !== undefined ) {
            if (this.props.location.state.hasOwnProperty("locale")) {
                
                let locale = this.props.location.state.locale;
                languageText = locale.split("=");
              
                this.props.i18n.changeLanguage(languageText[1]);
            }
        }
        
        return (
            <div className="">
                <div className="NotFound">
                    <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
                        <div className="page-error-content">
                            <div className="error-code mb-4 animated zoomInDown"></div>
                            <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
                                {/* solicitud fuera de la ventana horaria permitida para su visualización */}
                                {t('alerts.errTokenVerify')}
                            </h2>
                            {/* <img className="imgBorder" src={imageBackground} alt="image" /> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withTranslation()(ExpireKeyErr));
