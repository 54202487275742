import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Link } from "react-tiger-transition";
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import PullToRefresh from 'rmc-pull-to-refresh';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const styles = theme => ({
    root2: {
        width: '100%',
        paddingTop: "15px",
        margin: "auto",
        backgroundColor: theme.palette.background.paper,
        '& .MuiTextField-root2': {
            margin: theme.spacing(1),
            width: '25ch',
        }
    },
    listFont: {
        '& .MuiListItemText-secondary': {
            fontSize: theme.typography.fontSize,
        },
        '& .MuiListItemText-primary': {
            fontSize: "16px !important"
        }
    },
});

class CheckTachograph extends Component {
    state = {
        refreshing: false
    }


    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    onHandleOnClickChangeState() {
        this.props.verifyState();
    }
    handleOnClickTachoCheck = () => {
        this.props.getTacografoCodec12Send("tachocheck");
    }


    handleOnclickTachoDownload = () => {
        this.props.getTacografoSend("tachocheck");
    }


    // añadir un método que maneje el onclic del botón para envíar la descarga trasera o frontal.

    refreshTacografoCodec12Check = () => {
        this.setState({
            refreshing: true
        }, async () => {
            await this.props.getTacografoCheck();
        })

        this.setState({
            refreshing: false
        });
        // let response = "";
        // const settings = {
        //     method: 'GET',
        //     headers: {
        //         'accessToken': this.props.location.state.AccessToken,
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     }
        //     // body: JSON.stringify(objectToDelete)

        // };
        // const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/Codec12?deviceCheckControlId=${this.isEmpty(this.state.responseCheckControl.deviceCheckControlId) ? this.state.responseCheckControl.deviceCheckControlId : this.state.deviceCheckControlId}`, settings);




        // this.props.refreshGetTacografoCodec12Check();
    }
    formatDate(date) {
        let time = new Date(date);
        let day = (time.getDate() < 10 ? "0" + time.getDate() : time.getDate());
        let month = ((time.getMonth() + 1) < 10 ? "0" + (time.getMonth() + 1) : (time.getMonth() + 1));
        let hour = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours());
        let minutes = (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
        let seconds = (time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds());
        // console.log(day + "/" + month + "/" + time.getFullYear() + " " + hour + ":" + minutes + ":" + seconds);
        return day + "/" + month + "/" + time.getFullYear() + " " + hour + ":" + minutes + ":" + seconds;
    }
    onHandleOnclickBackButtonTacografo = () => {
        this.props.onHandleOnclickBackButton();
    }


    render() {
        const { classes } = this.props;
        return (
            <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                <PullToRefresh
                    style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}
                    direction="down"
                    refreshing={this.state.refreshing}
                    onRefresh={this.refreshTacografoCodec12Check.bind(this)}
                    indicator={
                        {
                            activate: <ArrowDownwardOutlinedIcon className="rotate" />,
                            deactivate: <div></div>,
                            release: <div><CircularProgress color="secondary" /></div>,
                            finish: <div></div>
                        }
                    }
                >
                    <div style={{ height: "100%" }}>
                        <List subheader={<ListSubheader>
                            {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}
                            {!this.isEmpty(this.props.itemListToView) ?
                                < ListItem onClick={this.onHandleOnclickBackButtonTacografo.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                    pathname: '/dashboard/instaladores/checkeo/' + this.props.itemListToView[0].serialNumber,

                                }}>
                                    <ListItemIcon>

                                        <ArrowBackIosRoundedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Tacógrafo" style={{ marginRight: "10px" }} />
                                    {/* <ListItemText primary="Verificación" /> */}
                                </ListItem>
                                :
                                <ListItem onClick={this.onHandleOnclickBackButtonTacografo.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                    pathname: '/dashboard/instaladores/checkeo',

                                }}>
                                    <ListItemIcon>

                                        <ArrowBackIosRoundedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Tacógrafo" style={{ marginRight: "10px" }} />
                                    {/* <ListItemText primary="Verificación" /> */}
                                </ListItem>
                            }


                            {/* <p style={{ position: "absolute", top: "4px", right: "22px" }}> Verificación</p> */}

                        </ListSubheader>
                        } className={classes.root2}>
                        </List>
                        <div style={{ width: "320px", margin: "auto", marginTop: "20px" }}>
                            {this.props.responseEvents.length < 1 ?
                                <FormControl style={{ marginTop: "0px", width: "100%", wordWrap: "break-word", marginBottom: 10 }} variant="outlined" className={classes.formControl}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ textAlign: "left" }}
                                        value={this.isEmpty(this.props.itemListToView) ? this.props.valueState : this.props.itemListToView[0].checkControl.tacografo}
                                        placeholder="Selecciona un estado"
                                        onChange={this.props.handleOnChangeTacografo.bind(this)}
                                        disabled={!this.isEmpty(this.props.itemListToView) ? true : false}
                                    >
                                        {/* <MenuItem value={0}>Sin Seleccionar</MenuItem> */}
                                        <MenuItem value={1}>No aplica</MenuItem>
                                        {/* <MenuItem value={2}>Verificado</MenuItem> */}
                                        <MenuItem value={0}>Pendiente</MenuItem>
                                    </Select>
                                </FormControl>
                                :
                                <FormControl style={{ marginTop: "0px", width: "100%", wordWrap: "break-word", marginBottom: 10 }} variant="outlined" className={classes.formControl}>
                                    <Select
                                        style={{ textAlign: "left" }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.props.valueState}
                                        placeholder="Selecciona un estado"
                                        onChange={this.props.handleOnChangeTacografo.bind(this)}
                                        disabled={this.props.responseEvents.length >= 1 ? true : false}
                                    >
                                        <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem>
                                        <MenuItem value={1}>No aplica</MenuItem>
                                        <MenuItem value={2}>Verificado</MenuItem>
                                        <MenuItem value={0}>Pendiente</MenuItem>
                                    </Select>
                                </FormControl>

                            }
                            {this.props.IsDeviceTachographDownloadEnabled &&
                                <div>
                                    {this.props.responseEvents.length < 1 ?
                                        <FormControl style={{ marginTop: "0px", width: "100%", wordWrap: "break-word", marginBottom: 10 }} variant="outlined" className={classes.formControl} placeholder='Selecciona una opción'>
                                            <Select
                                                style={{ textAlign: "left" }}
                                                value={this.props.verificationsOptions.tachoDownload === 0 || this.props.verificationsOptions.tachoDownload === -1 ? this.props.tachoDownload
                                                    : this.props.verificationsOptions.tachoDownload
                                                }
                                                //value={0}
                                                placeholder='Selecciona una opción'
                                                onChange={this.props.handleOnChangeTacografoDownload.bind(this)}>
                                                <MenuItem value={0}>Opción no seleccionada</MenuItem>
                                                <MenuItem value={1}>Descarga trasera</MenuItem>
                                                <MenuItem value={2}>Descarga delantera</MenuItem>
                                            </Select>
                                        </FormControl>
                                        :
                                        <FormControl style={{ marginTop: "0px", width: "100%", wordWrap: "break-word", marginBottom: 10 }} variant="outlined" className={classes.formControl} placeholder='Selecciona una opción'>
                                            <Select
                                                style={{ textAlign: "left" }}
                                                value={this.props.tachoDownload}
                                                //value={0}
                                                disabled={true}
                                                onChange={this.props.handleOnChangeTacografoDownload.bind(this)}>
                                                <MenuItem value={0}>Opción no seleccionada</MenuItem>
                                                <MenuItem value={1}>Descarga trasera</MenuItem>
                                                <MenuItem value={2}>Descarga delantera</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                </div>

                            }

                            <Grid item xs={12}>
                                <p style={{ margin: "0px", textAlign: "right" }}>({this.props.responseEvents.length > 1 ? 1 : this.props.responseEvents.length}/1) </p>
                            </Grid>
                            <div>
                                {
                                    this.props.IsDeviceTachographDownloadEnabled &&
                                        this.props.verificationsOptions.tacografo !== 2 ?
                                        this.isEmpty(this.props.itemListToView) ?
                                            <Button disabled={this.props.verificationsOptions.tachoDownload === 0} style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem", height: '44px', marginBottom: '20px' }} onClick={this.handleOnclickTachoDownload.bind(this)} variant="outlined">Actualizar tipo descarga</Button>
                                            : null
                                        : null
                                }
                            </div>

                            {
                                this.props.verificationsOptions.tacografo !== 2 ?
                                    this.isEmpty(this.props.itemListToView) ?
                                        <Button /*disabled={this.props.verificationsOptions.tachoDownload === 0}*/ style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem", marginBottom: '20px' }} onClick={this.handleOnClickTachoCheck.bind(this)} variant="outlined">Comprobación de descarga</Button>
                                        : null
                                    : null
                            }

                            {
                                this.props.verificationsOptions.tacografo !== 2 ?
                                    this.isEmpty(this.props.itemListToView) ?
                                        <div style={{ margin: "auto", width: "100%" }}>

                                            <Button style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem" }} variant="outlined" onClick={this.onHandleOnClickChangeState.bind(this)}>Cambiar estado</Button>
                                        </div>
                                        : null
                                    : null
                            }

                        </div>
                        <Grid item xs={12} md={6} style={{ margin: 'auto' }}>

                            <div >
                                <List dense={true}>
                                    {this.props.listTacografo.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <ListItem className={classes.listFont}>
                                                    <ListItemText
                                                        primary={item.codeCheck}
                                                        secondary={this.formatDate(item.date)}
                                                    />
                                                </ListItem>
                                                <Divider />
                                            </React.Fragment>

                                        )
                                    })}


                                </List>
                            </div>
                        </Grid>

                    </div>

                </PullToRefresh>

            </Grid >

        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(CheckTachograph));
