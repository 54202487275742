import React, { Component } from 'react'
import { Modal, Backdrop, Fade } from '@material-ui/core/';
import Select /*{ components }*/ from 'react-select';


class ModalChangeMassive extends Component {

    state = {
        vehicles: [],
        valueVehiclesEdit: [],
        vehiclesList: [],
        tractorIsEnabled: false,

        drivers: [],
        valueDriversEdit: [],
        driversList: [],
        driverIsEnabled: false,

        valueTrailerEdit: [],
        trailerIsEnabled: false,
        acceptButtonChangeMassiveIsEnabled: true
    }
    // "CambioMasivo?user={user}&fecha={fecha}&turno={turno}&tipo={tipo}&valorAnterior={valorAnterior}&valorNuevo={valorNuevo}&forzar={forzar}&cambiar={cambiar}&format={format}&key={key}")]
    async componentDidMount() {
        this.setState({
            vehiclesList: this.props.vehiclesListChangeMassive,
            driversList: this.props.driversListChangeMassive
        });
    }



    RemoveDuplicates(array) {
        let hash = {};
        let uniqueArray = [];
        uniqueArray = array.filter(o => o.label === "" ? false : hash[o.label] ? false : hash[o.label] = true);

        return uniqueArray;
    }

    handleOnChangeDriverEdit(newSelection) {
        if (newSelection === null) {
            newSelection = [];
        }

        this.setState({
            valueDriversEdit: newSelection
        }, () => {
            this.checkIsEnabledCheckboxAndValueModalDriver();
        });
    }

    handleOnChangeTractorEdit(newSelection) {
        if (newSelection === null) {
            newSelection = [];
        }

        this.setState({
            valueVehiclesEdit: newSelection
        }, () => {
            this.checkIsEnabledCheckboxAndValueModalTractor();
        });
    }

    handleOnChangeTrailerEdit(newSelection) {
        if (newSelection === null) {
            newSelection = [];
        }

        this.setState({
            valueTrailerEdit: newSelection
        }, () => {
            this.checkIsEnabledCheckboxAndValueModalTrailer();
        });
    }

    handleOnChangeCheckBoxEdit = (event) => {
        let newState = "";
        switch (event.target.name) {
            case "driver":
                newState = event.target.checked;
                this.setState({ driverIsEnabled: event.target.checked }, () => {
                    if (this.state.driverIsEnabled) {
                        // this.checkIsEnabledCheckboxAndValueModalDriver()
                        this.setState({
                            acceptButtonChangeMassiveIsEnabled: true
                        });
                    } else if (this.state.valueVehiclesEdit.hasOwnProperty("label") && !this.state.driverIsEnabled) {
                        this.setState({
                            acceptButtonChangeMassiveIsEnabled: false
                        });
                    } else if (this.state.valueTrailerEdit.hasOwnProperty("label") && !this.state.trailerIsEnabled) {
                        this.setState({
                            acceptButtonChangeMassiveIsEnabled: false
                        });
                    }
                });
                break;
            case "tractor":
                newState = event.target.checked;
                this.setState({ tractorIsEnabled: event.target.checked }, () => {
                    if (this.state.tractorIsEnabled) {
                        // this.checkIsEnabledCheckboxAndValueModalTractor()
                        this.setState({
                            acceptButtonChangeMassiveIsEnabled: true
                        });
                    } else if (this.state.valueDriversEdit.hasOwnProperty("label") && !this.state.tractorIsEnabled) {
                        this.setState({
                            acceptButtonChangeMassiveIsEnabled: false
                        });
                    } else if (this.state.valueTrailerEdit.hasOwnProperty("label") && !this.state.trailerIsEnabled) {
                        this.setState({
                            acceptButtonChangeMassiveIsEnabled: false
                        });
                    }
                });
                break;
            case "trailer":
                newState = event.target.checked;
                this.setState({ trailerIsEnabled: event.target.checked }, () => {
                    if (this.state.trailerIsEnabled) {
                        // this.checkIsEnabledCheckboxAndValueModalTrailer()
                        this.setState({
                            acceptButtonChangeMassiveIsEnabled: true
                        });
                    } else if (this.state.valueDriversEdit.hasOwnProperty("label") && !this.state.tractorIsEnabled) {
                        this.setState({
                            acceptButtonChangeMassiveIsEnabled: false
                        });
                    } else if (this.state.valueVehiclesEdit.hasOwnProperty("label") && !this.state.trailerIsEnabled) {
                        this.setState({
                            acceptButtonChangeMassiveIsEnabled: false
                        });
                    }
                });
                break;

            default:
                break;
        }
        
        return newState;
    }

    async handleOnClickAcceptMassiveChange() {
        let newValue = [];
        let newArrayBody = [];
        let response = "";

        let statusResult = {
            statusText: "",
            statusMessage: [],
            statusNumPedido: []
        }
        let arrOk = [];
        let arrNotOk = [];

        let arrSuccess = [];
        let arrError = [];
        let arrWarning = [];
        let arrDriverSameHour = [];
        let arrTractorSameHour = [];
        let arrTrailerSameHour = [];
        // console.log(this.state.valueDriversEdit);

        if (this.state.valueDriversEdit.hasOwnProperty("label")) {
            this.props.orders.forEach(element => {
                if (newValue.lengh > 0) {
                    newValue.push("\"" + element + "\"", "\"1\"", "\"" + this.state.valueDriversEdit.value + "\"");
                    newArrayBody.push(newValue);
                } else {
                    newValue = [];
                    newValue.push("\"" + element + "\"", "\"1\"", "\"" + this.state.valueDriversEdit.value + "\"");
                    newArrayBody.push(newValue);
                }
            });
        }

        if (this.state.valueVehiclesEdit.hasOwnProperty("label")) {
            this.props.orders.forEach(element => {
                if (newValue.lengh > 0) {
                    newValue.push("\"" + element + "\"", "\"2\"", "\"" + this.state.valueVehiclesEdit.value + "\"");
                    newArrayBody.push(newValue);
                } else {
                    newValue = [];
                    newValue.push("\"" + element + "\"", "\"2\"", "\"" + this.state.valueVehiclesEdit.value + "\"");
                    newArrayBody.push(newValue);
                }
            });
        }

        if (this.state.valueTrailerEdit.hasOwnProperty("label")) {
            this.props.orders.forEach(element => {
                if (newValue.lengh > 0) {
                    newValue.push("\"" + element + "\"", "\"3\"", "\"" + this.state.valueTrailerEdit.value + "\"");
                    newArrayBody.push(newValue);
                } else {
                    newValue = [];
                    newValue.push("\"" + element + "\"", "\"3\"", "\"" + this.state.valueTrailerEdit.value + "\"");
                    newArrayBody.push(newValue);
                }
            });
        }

        const settings = {
            method: 'POST',
            body: `[${newArrayBody.map(service => '[' + service + ']')}]`
        };
        const fetchResponse = await fetch("https://interop4gflota.disagrupo.es/acceso4disa/SetCambioMasivoSAP?user=disa&format=JSON&key=VG5itCkNnv9u2kUuKaLjvf7DPGs=", settings);
        if (fetchResponse.ok) {
            if (fetchResponse !== null) {
                try {
                    response = await fetchResponse.json();
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            alert("HTTP-Error: " + fetchResponse.status);
        }
        // console.log(response.Result);

        // -- 0  Actualización de estado correcta.
        // -- -1 No existe el servicio Indicado.
        // -- -2 Servicio ya activado.
        // -- -3 El Conductor ya tiene un servicio asignado a la misma hora.
        // -- -4 La Tractora ya tiene un servicio asignado a la misma hora.
        // -- -5 El SemiRemolque ya tiene un servicio asignado a la misma hora.

        // ErrorCode, Value, Information
        response.Result.forEach(element => {
            switch (element.Value) {
                case 0:
                    arrOk.push(element);
                    arrSuccess.push(element);
                    break;
                case -1:
                    arrNotOk.push(element);
                    arrError.push(element);
                    break;
                case -2:
                    arrNotOk.push(element);
                    arrWarning.push(element);
                    break;
                case -3:
                    arrNotOk.push(element);
                    arrDriverSameHour.push(element);
                    break;
                case -4:
                    arrNotOk.push(element);
                    arrTractorSameHour.push(element);
                    break;
                case -5:
                    arrNotOk.push(element);
                    arrTrailerSameHour.push(element);
                    break;
                default:
                    break;
            }
        });

        let arrObjLongitudes = {
            "0": arrSuccess.length,
            "-1": arrError.length,
            "-2": arrWarning.length,
            "-3": arrDriverSameHour.length,
            "-4": arrTractorSameHour.length,
            "-5": arrTrailerSameHour.length
        };

        let max = [];

        // let max = Math.max(...arrMoreHigher);
        // console.log(max);
        for (const key in arrObjLongitudes) {
            if (arrObjLongitudes.hasOwnProperty(key)) {
                if (arrObjLongitudes[key] > 0) {
                    // moreHigher = key;
                    max.push(key);
                }
            }
        }

        // console.log(max);

        if (arrOk.length > 0) {
            if (arrNotOk.length > 0) {
                statusResult.statusText = "warning";
            } else {
                statusResult.statusText = "success";
            }
        } else if (arrNotOk.length > 0) {
            statusResult.statusText = "error";
        }

        max.forEach(element => {
            switch (element) {
                case "0":
                    statusResult.statusMessage.push("Actualización de estado correcta.");
                    statusResult.statusNumPedido.push(arrSuccess);
                    break;
                case "-1":
                    statusResult.statusMessage.push("No existe el servicio Indicado.");
                    statusResult.statusNumPedido.push(arrError);
    
                    break;
                case "-2":
                    statusResult.statusMessage.push("Servicio ya activado.");
                    statusResult.statusNumPedido.push(arrWarning);
    
                    break;
                case "-3":
                    statusResult.statusMessage.push("El Conductor ya tiene un servicio asignado a la misma hora.");
                    statusResult.statusNumPedido.push(arrDriverSameHour);
                    break;
                case "-4":
                    statusResult.statusMessage.push("La tractora ya tiene un servicio asignado a la misma hora.");
                    statusResult.statusNumPedido.push(arrTractorSameHour);
    
                    break;
                case "-5":
                    statusResult.statusMessage.push("El semiRemolque ya tiene un servicio asignado a la misma hora.");
                    statusResult.statusNumPedido.push(arrTrailerSameHour);
                    break;
                default:
                    break;
            }
        });

        this.props.applyFilter();
        this.props.changeVisibilityAlertStatusAndModal(statusResult);

    }

    checkIsEnabledCheckboxAndValueModalDriver = () => {
        const { valueDriversEdit } = this.state;
        if (valueDriversEdit.hasOwnProperty("label")) {
            this.setState({
                acceptButtonChangeMassiveIsEnabled: false
            });
        } else {
            this.setState({
                acceptButtonChangeMassiveIsEnabled: true
            });
        }
    }

    checkIsEnabledCheckboxAndValueModalTractor() {
        const { valueVehiclesEdit } = this.state;

        if (valueVehiclesEdit.hasOwnProperty("label")) {
            this.setState({
                acceptButtonChangeMassiveIsEnabled: false
            });
        } else {
            this.setState({
                acceptButtonChangeMassiveIsEnabled: true
            });
        }
    }

    checkIsEnabledCheckboxAndValueModalTrailer() {
        const { valueTrailerEdit } = this.state;

        if (valueTrailerEdit.hasOwnProperty("label")) {
            this.setState({
                acceptButtonChangeMassiveIsEnabled: false
            });
        } else {
            this.setState({
                acceptButtonChangeMassiveIsEnabled: true
            });
        }
    }

    hideModal() {
        this.props.changeVisibilityModal();
    }

    render() {

        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modalCustom"
                    open={this.props.modalShowChangeMassive}
                    onClose={this.props.onClickHandleModalChangeMassive.bind(this)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}

                >
                    <Fade in={this.props.modalShowChangeMassive}>
                        <div className="contentModalChangeMassive">
                            <div style={{ marginTop: "10px" }}>
                                {/* <MDBInput label="Conductor" type="checkbox" name="driver" onChange={this.handleOnChangeCheckBoxEdit.bind(this)} /> */}
                                <label className="containerLabelCheckBox">
                                    <input type="checkbox" id="Conductor" label="Conductor" name="driver" onChange={this.handleOnChangeCheckBoxEdit.bind(this)} />
                                    <span className="checkmark"></span>
                                        Conductor
                                </label>
                                {/* <input style={{ opacity: "1", width: "20", height: "20" }} type="checkbox" id="Conductor" label="Conductor" name="driver" onChange={this.handleOnChangeCheckBoxEdit.bind(this)} />
                                    <label for="Conductor">Conductor</label> */}
                                <Select
                                    value={this.state.valueDriversEdit}
                                    onChange={this.handleOnChangeDriverEdit.bind(this)}
                                    isSearchable={true}
                                    classNamePrefix="select"
                                    name="test"
                                    placeholder="Conductores"
                                    options={this.state.driversList}
                                    formatGroupLabel={this.formatGroupLabel}
                                    isClearable={true}
                                    isDisabled={!this.state.driverIsEnabled}
                                />
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                {/* <MDBInput label="Cabeza" type="checkbox" name="tractor" onChange={this.handleOnChangeCheckBoxEdit.bind(this)} /> */}

                                <label className="containerLabelCheckBox">
                                    <input type="checkbox" label="Cabeza" name="tractor" onChange={this.handleOnChangeCheckBoxEdit.bind(this)} />
                                    <span className="checkmark"></span>
                                        Cabeza
                                </label>
                                <Select
                                    value={this.state.valueVehiclesEdit}
                                    onChange={this.handleOnChangeTractorEdit.bind(this)}
                                    isSearchable={true}
                                    classNamePrefix="select"
                                    name="test1"
                                    placeholder="Cabeza"
                                    options={this.state.vehiclesList}
                                    formatGroupLabel={this.formatGroupLabel}
                                    isClearable={true}
                                    isDisabled={!this.state.tractorIsEnabled}
                                />
                            </div>
                            <div style={{ marginTop: "5px" }}>
                                {/* <MDBInput label="Remolque" type="checkbox" name="trailer" onChange={this.handleOnChangeCheckBoxEdit.bind(this)} /> */}

                                <label className="containerLabelCheckBox">Remolque
                                    <input type="checkbox" label="Remolque" name="trailer" onChange={this.handleOnChangeCheckBoxEdit.bind(this)} />
                                    <span className="checkmark"></span>
                                    
                                </label>
                                <Select
                                    value={this.state.valueTrailerEdit}
                                    onChange={this.handleOnChangeTrailerEdit.bind(this)}
                                    isSearchable={true}
                                    classNamePrefix="select"
                                    name="test2"
                                    placeholder="Remolque"
                                    options={this.state.vehiclesList}
                                    formatGroupLabel={this.formatGroupLabel}
                                    isClearable={true}
                                    isDisabled={!this.state.trailerIsEnabled}

                                />
                            </div>
                            <div className="orders" style={{ display: "inline-block", height: "125px", overflowY: this.props.orders.length > 0 ? "scroll" : "hidden" }}>
                                <label>Pedidos: {this.props.orders.map((NumPedido, i) => <label key={i}>{NumPedido + ", "}</label>)}</label>
                            </div>
                            <p className="containerBtn">
                                <button className="btn btn-default" onClick={this.hideModal.bind(this)}>Cancelar</button>
                                <button className="btn btn-default" disabled={this.state.acceptButtonChangeMassiveIsEnabled} onClick={this.handleOnClickAcceptMassiveChange.bind(this)} >Aceptar</button>
                            </p>
                        </div>

                    </Fade>
                </Modal>


            </div>
        )
    }
}

// const ValueContainer = ({ children, getValue, ...props }) => {
//     let maxToShow = 2;
//     var length = getValue().length;
//     let displayChips = React.Children.toArray(children).slice(0, maxToShow);
//     let title = getValue()
//         .slice(maxToShow)
//         .map((x) => x.label)
//         .join(", ");
//     let shouldBadgeShow = length > maxToShow;
//     let displayLength = length - maxToShow;
//     return (
//         <components.ValueContainer {...props}>
//             {!props.selectProps.inputValue && displayChips}
//             {!!shouldBadgeShow && (
//                 <div className="root" title={title}>
//                     {/* {`+ ${displayLength} Opcione${length !== 1 ? "s" : ""} seleccionadas`} */}
//                     {`+ ${displayLength} `}
//                 </div>
//             )}
//         </components.ValueContainer>
//     );
// };

export default ModalChangeMassive;
