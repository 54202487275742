import React from "react";
import "./Map.css";

import Mapa from 'simple-component-library-jose';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// import { getSessionCookie } from '../global/cookies/cookies';
import Cookies from "js-cookie";
import config from "../../config";

class Map extends React.Component {
  constructor() {
    super(...arguments);
    // document.getElementById("wrapper").style.background = "#fff";
    this.state = {
      pathname: '',
      history: {},
      origin: false,
    }
    // props.history.replace(this.props.location.pathname, this.props.location.state); // Update state of current entry in history stack.
    // this.handleChange = this.handleChange.bind(this);

  }

  // componentWillUnmount(){    
  //   window.onpopstate = e => {
  //     console.log(e);
  //   }
  // }
  componentDidMount() {
    // document.querySelectorAll('style,link[rel="stylesheet"]').forEach(item => item.remove())
    let languageText = "";
    // if (this.props.isAuthenticated === false) {

    if (this.props.location.isAuthenticated === false) {
      this.redirectToLogin();

    } else {
      if (this.props.location.state !== undefined) {
        if (this.props.location.state.hasOwnProperty("locale")) {

          let locale = this.props.location.state.locale;
          languageText = locale.split("=");
          this.props.i18n.changeLanguage(languageText[1]);
          let originSplit = window.location.href.split("/");
          let origin = originSplit[4];
          if (origin !== undefined || origin !== null) {
            if (origin === "true") {
              this.setState({
                origin: true,
              });
            } else {
              this.setState({
                origin: false
              });
            }
          }

        }
      }
    }
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }


  // componentWillUnmount() {
  //   this.props.userHasAuthenticated(true);
  //   console.log(this.props.history)
  //   this.props.history.push({
  //     pathname: '/menu/' + this.props.companyName,
  //     state: {
  //       userDataInfo: this.props.location.state.userDataInfo,
  //       urlApi: this.props.location.state.ApiURL
  //     }
  //   });

  // }
  // }

  handleChange = option => (event) => {
    this.setState({
      [option]: event.target.value
    });
  }
  // static getDerivedStateFromProps(props, state) {
  //   if (props.location.state === undefined) {
  //     props.history.push({
  //       pathname: '/'

  //     });
  //   }
  //   return {
  //     pathname: '/'
  //   }
  // }
  redirectToLogin() {
    // this.props.userHasAuthenticated(false);
    Cookies.remove("session");
    this.props.history.push({
      pathname: '/'

    });
  }

  renderMap() {
    const location = this.props.location.state;
    return (
      <Mapa propsRouter={this.props} origin={this.state.origin} urlApi={location !== undefined ? this.props.location.state.ApiURL : ""} userDataInfo={location !== undefined ? this.props.location.state.userDataInfo : ""} localTime={location !== undefined ? this.props.location.state.userDataInfo.localTime : ""} accessToken={location !== undefined ? this.props.location.state.userDataInfo.accessToken : ""} googleMapsApiKey={config.KeyGoogleMaps} />
    )
  }

  render() {
    // let test = document.getElementsByClassName("panel-collapse");

    // for (let i = 0; i < test.length; i++) {
    //   test[i].classList.remove("in")
    // }
    // console.log(this.props)
    return (
      <div id="wrapper" style={{ height: "100%" }} className="wrapper" >
        {/* {this.renderMap()} */}
        {this.props.isAuthenticated !== false ? this.renderMap() : this.redirectToLogin()}
      </div>
    );
  };
}

export default withRouter(withTranslation()(Map));

