import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class DialogView extends Component {

    // handleOnClickShowAndHide() {
    //     this.props.onCloseDialogView.bind(this);
    // }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.dialogViewShow}
                    // onClose={this.handleOnClickShowAndHide.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"AVISO"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                           ¿Desea eliminar el/los siguientes accesos temporales marcados?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size={'large'}
                            onClick={this.props.onCloseOrOpenDialogView.bind(this)}
                            color="primary">
                            Cancelar
                        </Button>
                        <Button
                           onClick={this.props.handleOnclickAcceptDialogView.bind(this)}
                            color="primary" autoFocus>
                            Aceptar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default DialogView;
