import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link } from "react-tiger-transition";
import { CircularProgress, Container, Typography } from '@material-ui/core';


import Config from '../../../config';

const userdatainfo = JSON.parse(localStorage.getItem("userDataInfo"));
function TaskOrders(props) {
  const [purchaseOrders, setPurchaseOrders] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  let userDataInfo = "";
  if (props.location.state === undefined) {
    userDataInfo = JSON.parse(localStorage.getItem('userDataInfo'));
  } else {
    userDataInfo = props.location.state.userDataInfo;
  }
  React.useEffect(() => {
    const GetPurchaseOrders = async () => {
      try {
        //const token = 'TU_TOKEN_JWT'; // Reemplaza con tu token JWT


        const response = await fetch(`${Config.apiGateway.URL}Installers/PurchaseOrders?email=${userDataInfo.email}&status=approved`, {
          method: 'GET',
          // headers: {
          //   'Authorization': `Bearer ${token}`
          // }
        });
        // const response = await fetch(`${Config.apiGateway.URL}Installers/PurchaseOrders?email=sojeda@4gflota.com&status=approved`, {
        //   method: 'GET',
        //   // headers: {
        //   //   'Authorization': `Bearer ${token}`
        //   // }
        // });

        if (response.ok) {
          setLoading(false);
          const jsonData = await response.json();
          jsonData.forEach(element => {

            var dateParts = element.ExpirationDate.split("-");

            element.ExpirationDate = dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];

            // element.ExpirationDate = dateObject;

          });
          setPurchaseOrders(jsonData);

        } else {
          console.error('Error en la respuesta:', response.status);
        }
      } catch (error) {
        console.error('Error en la llamada:', error);
      }
    }
    GetPurchaseOrders();


    if (props.location.state === undefined) {
      localStorage.setItem('userDataInfo', JSON.stringify(userdatainfo));
    }

  }, [props.location.state, userDataInfo]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('es-ES', options);
  };

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: '100vh' }}>
          <CircularProgress color="secondary" />
        </div>
      ) : purchaseOrders !== null &&
      <List component="nav" aria-label="main mailbox folders">
        {purchaseOrders.map((item) => (
          <div key={item.OrderId}>
            <ListItem button component={Link} onClick={() => props.clickHandle(item)} transition='glide-left' to={{
              pathname: '/dashboard/ordenes-trabajo/detail/',

            }}
            >
              <ListItemText primary={item.NumPurchaseOrder + " - " + item.Subject + " - " +formatDate(item.CreatedTime)} />
              {/* <Divider orientation="vertical" flexItem />
                {item.status === "finalizado" ?
                  <CheckIcon style={{ color: "white", background: "#32a852", borderRadius: "4px", margin: 10 }} />
                  :
                  <CloseIcon style={{ color: "white", background: "#eb4034", borderRadius: "4px", margin: 10 }} />
                }

                <Divider orientation="vertical" flexItem /> */}
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                  <ArrowForwardIosIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </div>

        ))}
      </List>
      }

    </div>
  )
}

export default TaskOrders