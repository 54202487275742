import React from "react";
import "./NotFound.css";


function redirectToLogin(props) {
    props.history.push({
      pathname: '/'

    });
  }
export default function NotFound(props) {
redirectToLogin(props) 
    return (
        
        <div className="NotFound">
            <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
                <div className="page-error-content">
                    <div className="error-code mb-4 animated zoomInDown">404</div>
                    <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
                        Page Not Found
                        
                    </h2>
                    
                </div>
            </div>
            
        </div>
        
    );
}