import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import { withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
// import "@fortawesome/fontawesome-free/css/all.min.css";
import { DisaPlaningStartHeaders, ModalFieldsDataPlaning } from '../../../Environments/TranslateDataTable';
import { Switch, FormGroup, FormControlLabel } from '@material-ui/core/';
import Select /*{ components }*/ from 'react-select';
import BeatLoader from 'react-spinners/BeatLoader';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Column from '../../common/opcionesFilter/Column';
import { DragDropContext } from 'react-beautiful-dnd';
import imageFilter from '../../../containers/img/filter.svg';
import ModalChangeMassive from './ModalChangeMassive';
import AlertStatus from '../../common/alerts/AlertStatus';
import '../../services/realTime/taskOrderList.css';

import { getSessionCookie } from '../../global/cookies/cookies';

const spinnerCss = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginLeft: '-30px'
}

class dataTablePlaning extends Component {

    state = {
        valueDate: new Date(),
        dateTime: "",
        isLoading: true,
        isLoadingEdit: false,
        viewFilters: false,

        globalFilter: {
            combo1: [],
            combo2: [],
            combo3: [],
            combo4: [],
            combo5: [],
            combo6: [],
            combo7: [],
            combo8: [],
            combo9: [],
        },


        listDataTable: {},
        listEditDataTable: [],
        orders: [],

        headers: [],
        headersSelected: [],
        headersNotSelected: [],
        services: [],
        copyServices: [],

        modalShow: false,
        modalShowChangeMassive: false,
        statusObj: {},

        clientList: [],
        turnList: [],
        driverList: [],
        tractorList: [],
        trailerList: [],
        centerList: [],
        salesList: [],
        channelList: [],
        stateList: [],

        clientValue: [],
        turnValue: [],
        driverValue: [],
        tractorValue: [],
        trailerValue: [],
        centerValue: [],
        salesValue: [],
        channelValue: [],
        stateValue: [],

        groupedOptionsClientList: [],
        groupedOptionsTurnList: [],
        groupedOptionsDriverList: [],
        groupedOptionsTractorList: [],
        groupedOptionsTrailerList: [],
        groupedOptionsCenterList: [],
        groupedOptionsSalesList: [],
        groupedOptionsChannelList: [],
        groupedOptionsStateList: [],

        modalMobile: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? false : true,
        widthCells: "140px",

        isChecked: false,
        allChecked: false,
        // visibleAlert: false,

        driversListChangeMassiveState: [],
        vehiclesListChangeMassiveState: []

    }

    isEmpty(obj) {
        for (var prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            return false;
          }
        }
    
        return JSON.stringify(obj) === JSON.stringify({});
      }

    async componentDidMount() {
        // let r = getSessionCookie();
        document.getElementById("pin").style.display = "none";
        if (this.isEmpty(getSessionCookie())) {
            // console.log("entra");
            this.redirectToLogin()
          } else {
            await this.getVehicles();
          }
    }

    async getOptionsDataSelected(company, textSelectedOptions, textNotSelectedOptions, vehiclesListChangeMassive, driversListChangeMassive) {
        let response = {};
        const settings = {
            method: 'GET',
            headers: {
                'accessToken': this.props.location.state.AccessToken,

            }
        };
        let responseServices = await fetch(this.props.location.state.ApiURL + "UserLogin/Key/" + textSelectedOptions, settings);
        // let responseServices = await fetch(this.props.location.state.ApiURL + "Services?" + "ServicesDate=" +

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                try {
                    response = await responseServices.json();
                } catch (error) {
                    response = null;
                }
            }

        } else {
            alert("HTTP-Error: " + responseServices.status);
        }
        response = null;
        this.getOptionsDataNotSelected(company, response, textNotSelectedOptions, vehiclesListChangeMassive, driversListChangeMassive);
    }

    async getOptionsDataNotSelected(company, selectedOptions, textNotSelectedOptions, vehiclesListChangeMassive, driversListChangeMassive) {
        let response = {};
        const settings = {
            method: 'GET',
            headers: {
                'accessToken': this.props.location.state.AccessToken,

            }
        };
        let responseServices = await fetch(this.props.location.state.ApiURL + "UserLogin/Key/" + textNotSelectedOptions, settings);
        // let responseServices = await fetch(this.props.location.state.ApiURL + "Services?" + "ServicesDate=" +

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                try {
                    response = await responseServices.json();
                } catch (error) {
                    response = null;
                }
            }
            response = null;
            await this.getData(company, selectedOptions, response, vehiclesListChangeMassive, driversListChangeMassive);

        } else {
            alert("HTTP-Error: " + responseServices.status);
        }
    }

    async saveOptionsData(dataSelected, dataNotSelected, textSelectedOptions, textNotSelectedOptions) {
        let dataBodySelected = {
            key: textSelectedOptions,
            value: JSON.stringify(dataSelected)
        };
        const settings = {
            method: 'POST',
            headers: {
                'accessToken': this.props.location.state.AccessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataBodySelected),
        };
        try {
            const fetchResponse = await fetch(this.props.location.state.ApiURL + "UserLogin/Key/", settings);
            await fetchResponse.json();

            await this.saveOptionsDataNotSelected(dataNotSelected, dataSelected, textNotSelectedOptions);
            // return response;
        } catch (e) {
            return e;
        }
    }

    async saveOptionsDataNotSelected(dataNotSelected, dataSelected, textNotSelectedOptions) {
        if (dataNotSelected === null) {
            dataNotSelected = [];
        }
        let dataBody = {
            key: textNotSelectedOptions,
            value: JSON.stringify(dataNotSelected)
        };
        const settings = {
            method: 'POST',
            headers: {
                'accessToken': this.props.location.state.AccessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataBody),
        };
        try {
            const fetchResponse = await fetch(this.props.location.state.ApiURL + "UserLogin/Key/", settings);
            const response = await fetchResponse.json();
            return response;

        } catch (e) {
            return e;
        }
    }

    async getData(company, selectedOptions, notSelectedOptions, vehiclesListChangeMassive, driversListChangeMassive) {
        let responseServices = [];
        let jsonServices = [];
        let dateToday = this.formatDateApi();
        // console.log(dateToday);
        try {
            responseServices = await fetch("https://interop4gflota.disagrupo.es/acceso4disa/GetPedidos?user=disa&format=JSON&fecha=" + dateToday + "&key=gwDQdnxjtHFxw6TILS2HrlePnUg=");
            // responseServices = await fetch("https://interop4gflota.disagrupo.es/acceso4disa/GetPedidos?user=disa&format=JSON&fecha=20201112&key=gwDQdnxjtHFxw6TILS2HrlePnUg=");
        } catch (error) {
            console.log(error);
        }

        if (selectedOptions === undefined) {
            selectedOptions = this.state.headersSelected
        }

        if (notSelectedOptions === undefined) {
            notSelectedOptions = this.state.headersNotSelectedObject
        }

        if (responseServices.ok) {
            // console.log(responseServices.Result);
            jsonServices = await responseServices.json();
        } else {
            alert("HTTP-Error: " + responseServices.status);
        }

        this.showData(jsonServices, company, null, selectedOptions, notSelectedOptions, vehiclesListChangeMassive, driversListChangeMassive);
    }

    handleOnChangeCheckBoxAll = (event) => {
        let cbs = document.getElementsByTagName('input');
        let listEditDataTable = [];
        let orders = [];
        let stateCheckBoxAll = false;


        if (event.target.checked === true) {
            for (let i = 0; i < cbs.length; i++) {
                if (cbs[i].type === 'checkbox') {
                    cbs[i].checked = true;
                }
            }
            stateCheckBoxAll = true;
            this.state.listDataTable.rows.map(service => {
                listEditDataTable.push(service);
                orders.push(service.NumPedido);
                return service;
            });

        } else {
            stateCheckBoxAll = false;
            for (let i = 0; i < cbs.length; i++) {
                if (cbs[i].type === 'checkbox') {
                    cbs[i].checked = false;
                }
            }
        }

        this.setState({
            listEditDataTable: listEditDataTable,
            orders: orders,
            allChecked: stateCheckBoxAll

        });
    }
    handleCheckBoxAll = (event) => {
        let cbs = document.getElementsByTagName('input');
        let checkBoxAll = document.getElementById("checkboxAll");

        let listEditDataTable = [];
        let orders = [];

        if (checkBoxAll !== null) {
            if (checkBoxAll.checked !== null) {
                if (checkBoxAll.checked === true) {
                    for (let i = 0; i < cbs.length; i++) {
                        if (cbs[i].type === 'checkbox') {
                            cbs[i].checked = false;
                        }
                    }
                    checkBoxAll.checked = false;

                    this.state.listDataTable.rows.map(service => {
                        listEditDataTable.push(service);
                        orders.push(service.NumPedido);
                        return service;
                    });
                } else {
                    checkBoxAll.checked = true;
                    for (let i = 0; i < cbs.length; i++) {
                        if (cbs[i].type === 'checkbox') {
                            cbs[i].checked = false;
                        }
                    }
                }
            }
        }

        this.setState({
            listEditDataTable: [],
            orders: [],
            allChecked: false
        });
    }

    handleOnChangeCheckBoxSingle = (event) => {
        let tmp = [];
        let orders = [];
        if (event.target.checked === false) {
            if (this.state.listEditDataTable.length > 0) {
                tmp = this.state.listEditDataTable.filter(service => service.NumPedido.toString() !== event.target.name);
                tmp.forEach(service => {
                    orders.push(service.NumPedido);
                });
            } else {
                tmp = [];
            }
        } else {
            tmp = this.state.copyServices.rows.filter(service => service.NumPedido.toString() === event.target.name);
            this.state.listEditDataTable.map(element => {
                tmp.push(element);
                return element;
            });

            tmp.forEach(service => {
                orders.push(service.NumPedido);
            });
        }

        this.setState({
            listEditDataTable: tmp,
            orders: orders
        });
    }

    showData(jsonServices, company, filtersHeaders, selected, notSelected, vehiclesListChangeMassive, driversListChangeMassive) {

        let newHeaders = [];
        let modalFieldsDataPlaning = [];

        let resultSelected = [];
        let resultNotSelected = [];
        let newStateSelected = {};

        let clientList = [];
        let turnList = [];
        let driverList = [];
        let tractorList = [];
        let trailerList = [];
        let centerList = [];
        let salesList = [];
        let channelList = [];
        let stateList = [];
        let driversListChangeMassiveState = [];
        let vehiclesListChangeMassiveState = [];

        if (this.state.driversListChangeMassiveState.length > 0 && this.state.vehiclesListChangeMassiveState.length > 0) {
            driversListChangeMassiveState = this.state.driversListChangeMassiveState;
            vehiclesListChangeMassiveState = this.state.vehiclesListChangeMassiveState;
        } else {
            vehiclesListChangeMassive.forEach(vehicle => {
                vehiclesListChangeMassiveState.push({ tag: "Vehículos", value: vehicle.Matricula, label: vehicle.Matricula });
            });

            driversListChangeMassive.forEach(driver => {
                driversListChangeMassiveState.push({ tag: "Conductores", value: driver.Codigo, label: driver.Nombre });
            });
        }
        // vehicles: vehiclesResponse.Result,
        // vehiclesList: vehiclesList,

        // drivers: driversResponse.Result,
        // driversList: driversList,

        modalFieldsDataPlaning = ModalFieldsDataPlaning;
        newHeaders = DisaPlaningStartHeaders;
        // console.log(newHeaders);
        let newDataTable = {
            columns: [],
            rows: []
        }
        newDataTable.columns.push({

            // 'label': <MDBInput label="Seleccionar" type="checkbox" id="checkboxAll" onChange={this.handleOnChangeCheckBoxAll.bind(this)} />,
            'label': <label className="containerLabelCheckBoxtable">
                <input type="checkbox" id="checkboxAll" onChange={this.handleOnChangeCheckBoxAll.bind(this)} />
                <span className="checkmark"></span>
            </label>,
            'field': 'check',
            'sort': 'disabled',
        });
        newHeaders.forEach(header => {
            if (header.content === "Estado") {
                newDataTable.columns.push({
                    label: header.content,
                    field: header.label,
                    width: "140",
                    'sort': 'disabled',

                });
            }
            else {
                newDataTable.columns.push({
                    label: header.content,
                    field: header.label,
                    width: "140",
                    sort: 'disabled',
                });
            }

        });


        jsonServices.Result.forEach((service, i) => {
            newDataTable.rows.push({

                // "check": <MDBInput label=" " type="checkbox" name={service.NumPedido + "-" + service.CodCliente} key={i} onChange={this.handleOnChangeCheckBoxSingle.bind(this)} />,
                "check": <label className="containerLabelCheckBoxtable">
                    <input type="checkbox" name={service.NumPedido + "-" + service.CodCliente} key={i} onChange={this.handleOnChangeCheckBoxSingle.bind(this)} />
                    <span className="checkmark"></span>
                </label>,
                "NumPedido": service.NumPedido + "-" + service.CodCliente,
                "Cliente": service.Cliente,
                "Turno": service.Turno === "M" ? "Mañana" : service.Turno === "T" ? "Tarde" : service.Turno === "N" ? "Noche" : service.Turno,
                "CodConductor": service.CodConductor,
                "Conductor": service.Conductor,
                "CabezaTractora": service.CabezaTractora,
                "Semirremolque": service.Semirremolque,
                "HoraSalidaInstalacion": service.HoraSalidaInstalacion,
                "HoraLlegadaCliente": service.HoraLlegadaCliente,
                "HoraSalidaCliente": service.HoraSalidaCliente,
                "HoraLlegadaInstalacion": service.HoraLlegadaInstalacion,
                "CodCentro": service.CodCentro,
                "OrgVentas": service.OrgVentas,
                "Canal": service.Canal,
                // 5d705f
                "Estado": service.Estado > 0 ? <label id={service.NumPedido + "-" + service.CodCliente} style={{ background: "#878787", padding: "10px", color: "#fff", width: "73px" }}>Pendiente</label> : service.Estado.toString() === "-100" ? <label id={service.NumPedido + "-" + service.CodCliente} style={{ background: "#50f03c", padding: "10px", color: "#fff", width: "73px" }}>Activado</label> : service.Estado.toString() === "-200" ? <label id={service.NumPedido + "-" + service.CodCliente} style={{ background: "#eb4034", padding: "10px", color: "#fff", width: "73px" }}>Eliminado</label> : service.Estado.toString() === "-300" ? <label id={service.NumPedido + "-" + service.CodCliente} style={{ background: "#b0ae2c", padding: "10px", color: "#fff", width: "78px" }}>Modificado</label> : service.Estado,
                "background-color": "#000000",
                'sort': 'disabled'


            });

            clientList.push({ tag: "Cliente", value: service.Cliente, label: service.Cliente });
            turnList.push({ tag: "Turno", value: service.Turno === "M" ? "Mañana" : service.Turno === "T" ? "Tarde" : service.Turno === "N" ? "Noche" : service.Turno, label: service.Turno === "M" ? "Mañana" : service.Turno === "T" ? "Tarde" : service.Turno === "N" ? "Noche" : service.Turno, });
            driverList.push({ tag: "Conductor", value: service.Conductor, label: service.Conductor });
            tractorList.push({ tag: "CabezaTractora", value: service.CabezaTractora, label: service.CabezaTractora });
            trailerList.push({ tag: "Semirremolque", value: service.Semirremolque, label: service.Semirremolque });
            centerList.push({ tag: "CodCentro", value: service.CodCentro, label: service.CodCentro });
            salesList.push({ tag: "OrgVentas", value: service.OrgVentas, label: service.OrgVentas });
            channelList.push({ tag: "Canal", value: service.Canal, label: service.Canal });
            stateList.push({ tag: "Estado", value: service.Estado > 0 ? "Pendiente" : service.Estado.toString() === "-100" ? "Activado" : service.Estado.toString() === "-200" ? "Eliminado" : service.Estado.toString() === "-300" ? "Modificado" : service.Estado, label: service.Estado > 0 ? "Pendiente" : service.Estado === "-100" ? "Activado" : service.Estado === "-200" ? "Eliminado" : service.Estado === "-300" ? "Modificado" : service.Estado });
        });


        let uniqueArray = this.RemoveDuplicates(clientList);
        clientList = uniqueArray;
        clientList.sort((a, b) => a.label.localeCompare(b.label));

        uniqueArray = this.RemoveDuplicates(turnList);
        turnList = uniqueArray;
        turnList.sort((a, b) => a.label.localeCompare(b.label));

        uniqueArray = this.RemoveDuplicates(driverList);
        driverList = uniqueArray;
        driverList.sort((a, b) => a.label.localeCompare(b.label));

        uniqueArray = this.RemoveDuplicates(tractorList);
        tractorList = uniqueArray;
        tractorList.sort((a, b) => a.label.localeCompare(b.label));

        uniqueArray = this.RemoveDuplicates(trailerList);
        trailerList = uniqueArray;
        trailerList.sort((a, b) => a.label.localeCompare(b.label));

        uniqueArray = this.RemoveDuplicates(centerList);
        centerList = uniqueArray;
        centerList.sort((a, b) => a.label.localeCompare(b.label));

        uniqueArray = this.RemoveDuplicates(salesList);
        salesList = uniqueArray;
        salesList.sort((a, b) => a.label.localeCompare(b.label));

        uniqueArray = this.RemoveDuplicates(channelList);
        channelList = uniqueArray;
        channelList.sort((a, b) => a.label.localeCompare(b.label));

        uniqueArray = this.RemoveDuplicates(stateList);
        stateList = uniqueArray;
        stateList.sort((a, b) => a.label === b.label);

        let newDataTable2 = {
            columns: [],
            rows: []
        }

        if (selected !== null && notSelected !== null) {
            newHeaders = selected;
            // console.log(selected);
            resultSelected = selected.map((element) => (element.id));
            // console.log(notSelected);
            resultNotSelected = notSelected.map((element) => (element !== undefined ? element.id : []));
            let test = selected.map((element) => (element.label));

            newStateSelected = {
                ...modalFieldsDataPlaning,
                columns: {
                    ...modalFieldsDataPlaning.columns,
                    "column-2": {
                        id: 'column-2',
                        title: 'Opciones seleccionadas',
                        headersIds: resultSelected
                    },
                    "column-1": {
                        id: 'column-1',
                        title: 'Opciones',
                        headersIds: resultNotSelected
                    }
                }
            };

            let headersWithCheck = test;
            // console.log(headersWithCheck);
            headersWithCheck.unshift("check");
            let filteredparam1 = [];

            newDataTable2.columns = [];
            // console.log(selected);
            newHeaders.forEach(header => {
                if (header.content === "Estado") {
                    newDataTable2.columns.push({
                        label: header.content,
                        field: header.label,
                        width: "140",
                        'sort': 'disabled',

                    });
                }
                else {
                    newDataTable.columns.push({
                        label: header.content,
                        field: header.label,
                        width: "140",
                        'sort': 'asc',
                    });
                }
                // newDataTable2.columns.push({
                //     label: header.content,
                //     field: header.label,
                //     width: "140",
                //     sort: 'disabled',
                // });
            });



            newDataTable2.columns.unshift({
                'label': <label className="containerLabelCheckBoxtable">
                    <input type="checkbox" id="checkboxAll" onChange={this.handleOnChangeCheckBoxAll.bind(this)} />
                    <span className="checkmark"></span>
                </label>,
                'field': 'check',
                'sort': 'disabled',
            });

            newDataTable.rows.forEach((key, value) => {
                let tmp = this.startCopyObjectProps(key, headersWithCheck);
                filteredparam1.push(tmp);
            });

            newDataTable2.rows = filteredparam1;
            // console.log(newDataTable2);
        } else {
            // console.log(DisaPlaningStartHeaders);
            resultSelected = DisaPlaningStartHeaders;
            resultNotSelected = [];
            let test = resultSelected.map((element) => (element.label));
            resultSelected = DisaPlaningStartHeaders.map((element) => (element.id));
            if (resultNotSelected.length > 0) {
                resultNotSelected = resultNotSelected.map((element) => (element.id));
            }
            newStateSelected = {
                ...modalFieldsDataPlaning,
                columns: {
                    ...modalFieldsDataPlaning.columns,
                    "column-2": {
                        id: 'column-2',
                        title: 'Opciones seleccionadas',
                        headersIds: resultSelected
                    },
                    "column-1": {
                        id: 'column-1',
                        title: 'Opciones',
                        headersIds: resultNotSelected
                    }
                }
            };
            let headersWithCheck = test;
            // console.log(headersWithCheck);
            headersWithCheck.unshift("check");
            let filteredparam1 = [];

            newDataTable2.columns = [];
            // console.log(selected);
            newHeaders.forEach(header => {
                if (header.content === "Estado") {
                    newDataTable.columns.push({
                        label: header.content,
                        field: header.label,
                        width: "140",
                        'sort': 'disabled',

                    });
                }
                else {
                    newDataTable.columns.push({
                        label: header.content,
                        field: header.label,
                        width: "140",
                        'sort': 'disabled',
                    });
                }
            });

            newDataTable2.columns.unshift({
                'label': <label className="containerLabelCheckBoxtable">
                    <input type="checkbox" id="checkboxAll" onChange={this.handleOnChangeCheckBoxAll.bind(this)} />
                    <span className="checkmark"></span>
                </label>,
                'field': 'check',
                'sort': 'disabled',
            });

            newDataTable.rows.forEach((key, value) => {
                let tmp = this.startCopyObjectProps(key, headersWithCheck);
                filteredparam1.push(tmp);
            });

            newDataTable2.rows = filteredparam1;
            // console.log(newDataTable2);
        }

        this.setState({
            isLoading: false,
            // isLoadingEdit: false,
            dateTime: this.formatDate(),
            services: jsonServices,
            copyServices: newDataTable,
            headers: newHeaders,

            headersNotSelectedObject: notSelected,

            headersNotSelected: notSelected,
            headersSelected: selected !== null ? selected : resultSelected,

            listDataTable: newDataTable2,

            clientList: clientList,
            turnList: turnList,
            driverList: driverList,
            tractorList: tractorList,
            trailerList: trailerList,
            centerList: centerList,
            salesList: salesList,
            channelList: channelList,
            stateList: stateList,

            groupedOptionsClientList: [{
                label: 'Clientes',
                options: clientList,
            }],
            groupedOptionsTurnList: [{
                label: 'Turnos',
                options: turnList,
            }],
            groupedOptionsDriverList: [{
                label: 'Conductores',
                options: driverList,
            }],
            groupedOptionsTractorList: [{
                label: 'Cabeza Tractoras',
                options: tractorList,
            }],
            groupedOptionsTrailerList: [{
                label: 'Semirremolques',
                options: trailerList,
            }],
            groupedOptionsCenterList: [{
                label: 'Cod Centro',
                options: centerList,
            }],
            groupedOptionsSalesList: [{
                label: 'Org Ventas',
                options: salesList,
            }],
            groupedOptionsChannelList: [{
                label: 'Canales',
                options: channelList,
            }],
            groupedOptionsStateList: [{
                label: 'Estados',
                options: stateList,
            }],
            listTableTranslate: newStateSelected,
            driversListChangeMassiveState: driversListChangeMassiveState,
            vehiclesListChangeMassiveState: vehiclesListChangeMassiveState
        }, () => {
            // console.log(this.state.isLoading);
            this.filterGlobal(true);
        });
    }

    handleOnChangeClient(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
                // tmp.push(newSelection[i].label);
            }
            const newState = {
                ...this.state.globalFilter,
                combo1: tmp
            }
            this.setState({
                globalFilter: newState,
                clientValue: newSelection
            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
                this.filterGlobal(true);
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo1: []
            }
            this.setState({
                globalFilter: newState,
                clientValue: []
            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
                this.filterGlobal(true);
            });
        }
    }

    handleOnChangeTurn(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo2: tmp
            }

            this.setState({
                globalFilter: newState,
                turnValue: newSelection
            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
                this.filterGlobal(true);
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo2: []
            }
            this.setState({
                globalFilter: newState,
                turnValue: []
            }, () => {
                this.filterGlobal(true);
            });
        }
    }

    filterGlobal(comeFromComboBox, comeFromModal) {
        let filteredList = this.state.copyServices.rows;

        for (const key in this.state.globalFilter) {
            if (this.state.globalFilter.hasOwnProperty(key)) {
                const element = this.state.globalFilter[key];
                if (element.length > 0) {
                    filteredList = this.filterTest(element, filteredList, key);
                }
            }
        }
        // console.log(filteredList);
        let newDataTable = {
            ...this.state.listDataTable,
            rows: filteredList
        }

        this.applyFilter(newDataTable, comeFromComboBox);
        // return filteredList;
    }

    filterTest(element, filteredList, key) {
        let arr = [];
        switch (key) {
            case "combo1":
                for (let i = 0; i < element.length; i++) {

                    filteredList.filter(item => {
                        if (item["Cliente"] === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }
                break;
            case "combo2":
                for (let i = 0; i < element.length; i++) {

                    filteredList.filter(item => {
                        if (item["Turno"] === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }
                break;
            case "combo3":
                for (let i = 0; i < element.length; i++) {

                    filteredList.filter(item => {
                        if (item["Conductor"] === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }
                break;
            case "combo4":
                for (let i = 0; i < element.length; i++) {

                    filteredList.filter(item => {
                        if (item["CabezaTractora"] === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }
                break;
            case "combo5":
                for (let i = 0; i < element.length; i++) {

                    filteredList.filter(item => {
                        if (item["Semirremolque"] === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }
                break;
            case "combo6":
                for (let i = 0; i < element.length; i++) {
                    filteredList.filter(item => {
                        if (item["CodCentro"] === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }

                break;
            case "combo7":
                for (let i = 0; i < element.length; i++) {
                    filteredList.filter(item => {
                        if (item["OrgVentas"] === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }
                break;
            case "combo8":
                for (let i = 0; i < element.length; i++) {
                    filteredList.filter(item => {
                        if (item["Canal"] === element[i]) {
                            arr.push(item);
                        }
                        return item;
                    });
                }

                break;
            case "combo9":
                for (let i = 0; i < element.length; i++) {
                    filteredList.filter(item => {
                        let NumPedidoLabel = item.NumPedido;
                        NumPedidoLabel = NumPedidoLabel.toString();

                        if (document.getElementById(NumPedidoLabel) !== null) {

                            let labelState = document.getElementById(NumPedidoLabel).textContent;
                            if (labelState === element[i]) {
                                arr.push(item);
                            }
                        }
                        else {
                            let NumPedidoLabel = item["Estado"].props.children;
                            NumPedidoLabel = NumPedidoLabel.toString();
                            if (NumPedidoLabel === element[i]) {
                                arr.push(item);
                            }
                        }
                        return item;
                    });
                }
                break;

            default:
                break;
        }
        return arr;
    }

    handleOnChangeDriver(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo3: tmp
            }

            this.setState({
                globalFilter: newState,
                driverValue: newSelection,

            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // }, () => {
                //     this.applyFilter();
                // });
                this.filterGlobal(true);
            })
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo3: []
            }
            this.setState({
                globalFilter: newState,
                driverValue: []
            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // }, () => {
                //     this.applyFilter();
                // });
                this.filterGlobal(true);
            });
        }
    }

    handleOnChangeTractor(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo4: tmp
            }

            this.setState({
                globalFilter: newState,
                tractorValue: newSelection
            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
                this.filterGlobal(true);
            })
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo4: []
            }
            this.setState({
                globalFilter: newState,
                tractorValue: []
            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
                this.filterGlobal(true);
            });
        }
    }

    handleOnChangeTrailer(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo5: tmp
            }

            this.setState({
                globalFilter: newState,
                trailerValue: newSelection
            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
                this.filterGlobal(true);
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo5: []
            }
            this.setState({
                globalFilter: newState,
                trailerValue: []
            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
                this.filterGlobal(true);
            });
        }
    }

    handleOnChangeCenter(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo6: tmp
            }

            this.setState({
                globalFilter: newState,
                centerValue: newSelection
            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
                this.filterGlobal(true);
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo6: []
            }
            this.setState({
                globalFilter: newState,
                centerValue: []
            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
                this.filterGlobal(true);
            });
        }
    }

    handleOnChangeSales(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo7: tmp
            }

            this.setState({
                globalFilter: newState,
                salesValue: newSelection
            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
                this.filterGlobal(true);
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo7: []
            }
            this.setState({
                globalFilter: newState,
                salesValue: []
            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
                this.filterGlobal(true);
            });
        }
    }

    handleOnChangeChannel(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo8: tmp
            }

            this.setState({
                globalFilter: newState,
                channelValue: newSelection
            }, () => {
                this.filterGlobal(true);
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo8: []
            }
            this.setState({
                globalFilter: newState,
                channelValue: []
            }, () => {
                this.filterGlobal(true);
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
            });
        }
    }

    handleOnChangeState(newSelection) {
        let newArrayFilter = [];

        if (newSelection === null) {
            newSelection = [];
        }

        for (let i = 0; i < newSelection.length; i++) {
            newArrayFilter.push(newSelection[i].label);
        }
        if (newArrayFilter.length >= 1) {

            let tmp = [];

            for (let i = 0; i < newSelection.length; i++) {
                tmp.push(newSelection[i].label);
            }

            const newState = {
                ...this.state.globalFilter,
                combo9: tmp
            }

            this.setState({
                globalFilter: newState,
                stateValue: newSelection
            }, () => {
                this.filterGlobal(true);
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
            });
        } else {
            const newState = {
                ...this.state.globalFilter,
                combo9: []
            }
            this.setState({
                globalFilter: newState,
                stateValue: []
            }, () => {
                // let result = this.testFilterGlobal();
                // let newDataTable = {
                //     ...this.state.listDataTable,
                //     rows: result
                // }
                // this.setState({
                //     listDataTable: newDataTable
                // });
                this.filterGlobal(true);
            });
        }
    }

    recursiveFilter(data, arrayFilters, index = 0) {
        if (arrayFilters.length === 0) {
            return data;
        }
        // let keys = this.getKeys();
        let keys = this.getKeys(index);
        let key = keys[0];
        const f1 = h => h[key] === arrayFilters[index][key];

        if (index === arrayFilters.length - 1) {
            return data.filter(f1);
        }

        return this.recursiveFilter(data.filter(f1), arrayFilters, (index + 1));
    }

    updateTableFiltered() {
        const { globalFilter } = this.state;

        this.recursiveFilter(this.state.copyServices.rows, globalFilter);
        let newArray = [];
        let tmp = [];
        if (globalFilter.length > 0) {
            for (let i = 0; i < globalFilter.length; i++) {
                this.state.copyServices.rows.filter(function (v, j) {
                    if (v["Cliente"] === globalFilter[i]["Cliente"]) {
                        tmp.push(v);
                    }
                    return v;
                });
            }

            for (let j = 0; j < tmp.length; j++) {
                newArray.push(tmp[j]);
            }
            newArray = this.RemoveDuplicateServices(newArray);
            let newDataTable = {
                ...this.state.listDataTable,
                rows: newArray
            }

            return newDataTable;
        } else {
            return this.state.copyServices;
        }
    }

    onDragEnd = result => {
        // TODO reorder our column
        let tmp = [];
        let newHeaders = [];
        let headersNotSelected = [];
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const start = this.state.listTableTranslate.columns[source.droppableId];
        const finish = this.state.listTableTranslate.columns[destination.droppableId];

        if (start === finish) {
            const newHeaderIds = Array.from(start.headersIds);
            newHeaderIds.splice(source.index, 1);
            newHeaderIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...start,
                headersIds: newHeaderIds
            }
            const newState = {
                ...this.state.listTableTranslate,
                columns: {
                    ...this.state.listTableTranslate.columns,
                    [newColumn.id]: newColumn
                }
            };

            for (const key in this.state.listTableTranslate.headers) {
                if (this.state.listTableTranslate.headers.hasOwnProperty(key)) {
                    tmp.push(this.state.listTableTranslate.headers[key]);
                }
            }

            for (let i = 0; i < newState.columns["column-2"].headersIds.length; i++) {
                tmp.filter(function (v, j) {
                    if (v["id"] === newState.columns["column-2"].headersIds[i]) {
                        newHeaders.push(v);
                    }
                    return v;
                });
            }
            this.setState({
                listTableTranslate: newState,
                headers: newHeaders
            });
            return;
        }

        //moving from one list to another
        const startHeaderIds = Array.from(start.headersIds);
        startHeaderIds.splice(source.index, 1);

        const newStart = {
            ...start,
            headersIds: startHeaderIds
        };

        const finishHeaderIds = Array.from(finish.headersIds);
        finishHeaderIds.splice(destination.index, 0, draggableId);

        const newFinish = {
            ...finish,
            headersIds: finishHeaderIds
        }

        const newState = {
            ...this.state.listTableTranslate,
            columns: {
                ...this.state.listTableTranslate.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish
            }
        };

        for (const key in this.state.listTableTranslate.headers) {
            if (this.state.listTableTranslate.headers.hasOwnProperty(key)) {
                tmp.push(this.state.listTableTranslate.headers[key]);
            }
        }

        for (let i = 0; i < newState.columns["column-2"].headersIds.length; i++) {
            tmp.filter(function (v, j) {
                if (v["id"] === newState.columns["column-2"].headersIds[i]) {
                    newHeaders.push(v);
                }
                return v;
            });
        }

        for (let i = 0; i < newState.columns["column-1"].headersIds.length; i++) {
            tmp.filter(function (v, j) {
                if (v["id"] === newState.columns["column-1"].headersIds[i]) {
                    headersNotSelected.push(v);
                }
                return v;
            });
        }




        // let keys = this.getKeys();
        // console.log(newHeaders);
        // let result1 = this.generateNewRowsForDataTable(keys, headersNotSelected);

        // console.log(result1);
        this.setState({
            listTableTranslate: newState,
            headers: newHeaders,
            // listDataTable: newListDataTable,
            headersNotSelected: headersNotSelected
            // services: updatedServices
        });
    }

    getKeys = (index) => {
        return Object.keys(this.state.globalFilter[index]);
    }

    // getKeys = () => {
    //     return Object.keys(this.state.copyServices.rows[0]);
    // }

    generateNewRowsForDataTable(newHeaders, notSelected) {
        let newListDataTable = [];

        this.state.copyServices.rows.forEach((element, value) => {
            let tmp = this.copyObjectProps(element, this.state.copyServices.columns, notSelected);
            newListDataTable.push(tmp);
        });
    }

    // copyObjectProps = (source, keys, notSelected) => {
    //     let newObject = {}

    //     keys.forEach(function (key) {
    //         if (key.field !== notSelected[0].label) {
    //             newObject[key.field] = source[key.field]
    //         }
    //         // newObject[key] = source[key]
    //     })
    //     return newObject
    // }

    // function getFilteredByKey(array, key, value) {
    //     return array.filter(function(e) {
    //       return e[key] == value;
    //     });
    //   }

    applyFilter = async (arrListDataTable, comeFromComboBox) => {

        const { headers, headersNotSelected } = this.state;
        var filteredparam1 = [];

        let result = headers.map((element) => (element.label));

        result.unshift("check");

        const newListDataTable = {
            ...arrListDataTable,
            columns: []
        }

        headers.forEach(header => {
            newListDataTable.columns.push({
                label: header.content,
                field: header.label,
                width: "140"
            });
        });

        newListDataTable.columns.unshift({
            'label': <label className="containerLabelCheckBoxtable">
                <input type="checkbox" id="checkboxAll" onChange={this.handleOnChangeCheckBoxAll.bind(this)} />
                <span className="checkmark"></span>
            </label>,
            'field': 'check',
            sort: 'disabled',
        });

        arrListDataTable.rows.forEach((key, value) => {
            let tmp = this.copyObjectProps(key, result);
            filteredparam1.push(tmp);
        });

        newListDataTable.rows = filteredparam1;

        if (comeFromComboBox === true) {
            if (this.state.allChecked) {
                this.setState({
                    listDataTable: newListDataTable,
                    headersSelected: headers,
                    headersNotSelected: headersNotSelected,
                    listEditDataTable: [],
                    isLoadingEdit: false,
                    allChecked: false
                }, () => {
                    this.handleOnSort();
                });
            } else {
                this.setState({
                    listDataTable: newListDataTable,
                    headersSelected: headers,
                    headersNotSelected: headersNotSelected,
                    listEditDataTable: [],
                    isLoadingEdit: false,
                    allChecked: false

                }, () => {
                    this.handleOnSort();
                });
            }

        } else {
            this.setState({
                listDataTable: newListDataTable,
                headersSelected: headers,
                headersNotSelected: headersNotSelected,
                headersNotSelectedObject: this.state.headersNotSelected,
                modalShow: false,
                listEditDataTable: [],
                isLoadingEdit: false,
                allChecked: false
                // headersNotSelected: 

            }, async () => {
                await this.saveOptionsData(headers, this.state.headersNotSelectedObject, "SeleccionadasPlaning", "NoSeleccionadasPlaning");
                this.handleOnSort();
            });
        }


    }

    copyObjectProps = (source, keys) => {
        let newObject = {}
        // newObject[]
        // "check": <MDBInput label=" " type="checkbox" name={service.NumPedido + "-" + service.CodCliente} key={i} onChange={this.handleOnChangeCheckBoxSingle.bind(this)} />,
        keys.forEach(function (key) {
            newObject[key] = source[key]
        })
        return newObject
    }

    startCopyObjectProps = (source, keys) => {
        let newObject = {}
        // newObject[]
        // "check": <MDBInput label=" " type="checkbox" name={service.NumPedido + "-" + service.CodCliente} key={i} onChange={this.handleOnChangeCheckBoxSingle.bind(this)} />,
        keys.forEach(function (key) {
            if (key.hasOwnProperty("label")) {
                newObject[key.label] = source[key.label]
            } else {
                newObject[key] = source[key]
            }


        })
        return newObject
    }

    RemoveDuplicateServices(array) {
        let hash = {};
        let uniqueArray = [];
        uniqueArray = array.filter(o => hash[o.NumPedido] ? false : hash[o.NumPedido] = true);

        return uniqueArray;
    }

    RemoveDuplicates(array) {
        let hash = {};
        let uniqueArray = [];
        uniqueArray = array.filter(o => o.label === "" ? false : hash[o.label] ? false : hash[o.label] = true);

        return uniqueArray;
    }

    onClickToggle = () => {
        this.setState({
            viewFilters: !this.state.viewFilters
        });
    }

    onClickHandleModal() {
        this.setState({
            modalShow: !this.state.modalShow
        })
    }

    onClickHandleModalChangeMassive(statusObj) {
        this.setState({
            modalShowChangeMassive: !this.state.modalShowChangeMassive,
        });
    }

    changeVisibilityAlertStatusAndModal(statusObj) {
        let company = this.props.companyName.toLocaleLowerCase();
        this.setState({
            modalShowChangeMassive: !this.state.modalShowChangeMassive,
            statusObj: statusObj,
            visibleAlert: !this.state.visibleAlert,
            isLoadingEdit: !this.state.isLoadingEdit,
            listEditDataTable: [],
            dateTime: this.formatDate(),
        }, () => {
            this.getData(company, this.state.headersSelected, this.state.headersNotSelected, this.state.vehiclesListChangeMassiveState, this.state.driversListChangeMassiveState);
        });
    }

    changeVisibilityAlertStatus() {
        this.setState({
            visibleAlert: !this.state.visibleAlert
        });
    }

    changeVisibilityModal = () => {
        this.setState({
            modalShowChangeMassive: false
        });
    }

    async getVehicles() {
        let response = {};
        const settings = {
            method: 'GET',
            // headers: {
            //     'accessToken': this.props.location.state.AccessToken,
            // }
        };

        let responseServices = await fetch("https://interop4gflota.disagrupo.es/acceso4disa/GetVehiculos?user=disa&format=JSON&actual=0&key=VG5itCkNnv9u2kUuKaLjvf7DPGs=", settings);
        // let responseServices = await fetch(this.props.location.state.ApiURL + "Services?" + "ServicesDate=" +

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                try {
                    
                    response = await responseServices.json();
                    response = response.Result;
                    await this.getDrivers(response);
                } catch (error) {
                    response = null;
                }
            }
        } else {
            alert("HTTP-Error: " + responseServices.status);
        }

    }

    async getDrivers(vehiclesListChangeMassive) {
        // console.log(this.props.companyName);
        let response = {};
        const settings = {
            method: 'GET',
            // headers: {
            //     'accessToken': this.props.location.state.AccessToken,
            // }
        };
        let responseServices = await fetch("https://interop4gflota.disagrupo.es/acceso4disa/GetConductores?user=disa&format=JSON&actual=0&key=Kh3BNr4PJnDNrvrTL0lZSgnHdnk=", settings);
        // let responseServices = await fetch(this.props.location.state.ApiURL + "Services?" + "ServicesDate=" +

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                try {
                    response = await responseServices.json();
                    response = response.Result;
                    let company = this.props.companyName.toLocaleLowerCase();
                    // console.log(company);
                    await this.getOptionsDataSelected(company, "SeleccionadasPlaning", "NoSeleccionadasPlaning", vehiclesListChangeMassive, response);

                } catch (error) {
                    response = null;
                }
            }
        } else {
            alert("HTTP-Error: " + responseServices.status);
        }

    }

    async handleOnClickActivateButton() {
        const { listEditDataTable } = this.state;
        let company = this.props.companyName.toLocaleLowerCase();
        let newValue = [];
        let newArrayBody = [];
        let response = "";

        let statusResult = {
            statusText: "",
            statusMessage: [],
            statusNumPedido: []
        }
        let arrOk = [];
        let arrNotOk = [];

        let arrSuccess = [];
        let arrError = [];
        let arrWarning = [];
        let arrDriverSameHour = [];
        let arrTractorSameHour = [];
        let arrTrailerSameHour = [];

        if (listEditDataTable.length > 0) {
            listEditDataTable.forEach(element => {
                newValue = [];
                newValue.push("\"" + element.NumPedido + "\"", "\"-100\"");
                newArrayBody.push(newValue);
            });
        }

        const settings = {
            method: 'POST',
            body: `[${newArrayBody.map(service => '[' + service + ']')}]`
        };
        const fetchResponse = await fetch("https://interop4gflota.disagrupo.es/acceso4disa/SetEstadoPedidoSAP?user=disa&format=JSON&key=VG5itCkNnv9u2kUuKaLjvf7DPGs=", settings);
        if (fetchResponse.ok) {
            if (fetchResponse !== null) {
                try {
                    response = await fetchResponse.json();
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            alert("HTTP-Error: " + fetchResponse.status);
        }

        response.Result.forEach(element => {
            switch (element.Value) {
                case 0:
                    arrOk.push(element);
                    arrSuccess.push(element);
                    break;
                case -1:
                    arrNotOk.push(element);
                    arrError.push(element);
                    break;
                case -2:
                    arrNotOk.push(element);
                    arrWarning.push(element);
                    break;
                case -3:
                    arrNotOk.push(element);
                    arrDriverSameHour.push(element);
                    break;
                case -4:
                    arrNotOk.push(element);
                    arrTractorSameHour.push(element);
                    break;
                case -5:
                    arrNotOk.push(element);
                    arrTrailerSameHour.push(element);
                    break;
                default:
                    break;
            }
        });

        let arrObjLongitudes = {
            "0": arrSuccess.length,
            "-1": arrError.length,
            "-2": arrWarning.length,
            "-3": arrDriverSameHour.length,
            "-4": arrTractorSameHour.length,
            "-5": arrTrailerSameHour.length
        };

        let max = [];

        for (const key in arrObjLongitudes) {
            if (arrObjLongitudes.hasOwnProperty(key)) {
                if (arrObjLongitudes[key] > 0) {
                    // moreHigher = key;
                    max.push(key);
                }
            }
        }

        if (arrOk.length > 0) {
            if (arrNotOk.length > 0) {
                statusResult.statusText = "warning";
            } else {
                statusResult.statusText = "success";
            }
        } else if (arrNotOk.length > 0) {
            statusResult.statusText = "error";
        }

        max.forEach(element => {
            switch (element) {
                case "0":
                    statusResult.statusMessage.push("Actualización de estado correcta.");
                    statusResult.statusNumPedido.push(arrSuccess);
                    break;
                case "-1":
                    statusResult.statusMessage.push("No existe el servicio Indicado.");
                    statusResult.statusNumPedido.push(arrError);

                    break;
                case "-2":
                    statusResult.statusMessage.push("Servicio ya activado.");
                    statusResult.statusNumPedido.push(arrWarning);

                    break;
                case "-3":
                    statusResult.statusMessage.push("El Conductor ya tiene un servicio asignado a la misma hora.");
                    statusResult.statusNumPedido.push(arrDriverSameHour);
                    break;
                case "-4":
                    statusResult.statusMessage.push("La tractora ya tiene un servicio asignado a la misma hora.");
                    statusResult.statusNumPedido.push(arrTractorSameHour);

                    break;
                case "-5":
                    statusResult.statusMessage.push("El semiRemolque ya tiene un servicio asignado a la misma hora.");
                    statusResult.statusNumPedido.push(arrTrailerSameHour);
                    break;
                default:
                    break;
            }
        });

        if (response.Result.length > 0) {
            this.setState({
                // isLoadingEdit: !this.state.isLoadingEdit,
                statusObj: statusResult,
                visibleAlert: true,
                listEditDataTable: [],
                dateTime: this.formatDate(),
                isLoadingEdit: true
            }, () => {
                this.getData(company, this.state.headersSelected, this.state.headersNotSelectedObject, this.state.vehiclesListChangeMassiveState, this.state.driversListChangeMassiveState);
                this.handleOnSort();
            });
        }



    }

    async handleOnClickDeleteButton() {
        const { listEditDataTable } = this.state;
        let company = this.props.companyName.toLocaleLowerCase();
        let newValue = [];
        let newArrayBody = [];
        let response = "";

        let statusResult = {
            statusText: "",
            statusMessage: [],
            statusNumPedido: []
        }
        let arrOk = [];
        let arrNotOk = [];

        let arrSuccess = [];
        let arrError = [];
        let arrWarning = [];
        let arrDriverSameHour = [];
        let arrTractorSameHour = [];
        let arrTrailerSameHour = [];

        if (listEditDataTable.length > 0) {
            listEditDataTable.forEach(element => {
                newValue = [];
                newValue.push("\"" + element.NumPedido + "\"", "\"-200\"");
                newArrayBody.push(newValue);
            });
        }

        const settings = {
            method: 'POST',
            body: `[${newArrayBody.map(service => '[' + service + ']')}]`
        };
        const fetchResponse = await fetch("https://interop4gflota.disagrupo.es/acceso4disa/SetEstadoPedidoSAP?user=disa&format=JSON&key=VG5itCkNnv9u2kUuKaLjvf7DPGs=", settings);
        if (fetchResponse.ok) {
            if (fetchResponse !== null) {
                try {
                    response = await fetchResponse.json();
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            alert("HTTP-Error: " + fetchResponse.status);
        }

        response.Result.forEach(element => {
            switch (element.Value) {
                case 0:
                    arrOk.push(element);
                    arrSuccess.push(element);
                    break;
                case -1:
                    arrNotOk.push(element);
                    arrError.push(element);
                    break;
                case -2:
                    arrNotOk.push(element);
                    arrWarning.push(element);
                    break;
                case -3:
                    arrNotOk.push(element);
                    arrDriverSameHour.push(element);
                    break;
                case -4:
                    arrNotOk.push(element);
                    arrTractorSameHour.push(element);
                    break;
                case -5:
                    arrNotOk.push(element);
                    arrTrailerSameHour.push(element);
                    break;
                default:
                    break;
            }
        });

        let arrObjLongitudes = {
            "0": arrSuccess.length,
            "-1": arrError.length,
            "-2": arrWarning.length,
            "-3": arrDriverSameHour.length,
            "-4": arrTractorSameHour.length,
            "-5": arrTrailerSameHour.length
        };

        let max = [];

        for (const key in arrObjLongitudes) {
            if (arrObjLongitudes.hasOwnProperty(key)) {
                if (arrObjLongitudes[key] > 0) {
                    // moreHigher = key;
                    max.push(key);
                }
            }
        }

        if (arrOk.length > 0) {
            if (arrNotOk.length > 0) {
                statusResult.statusText = "warning";
            } else {
                statusResult.statusText = "success";
            }
        } else if (arrNotOk.length > 0) {
            statusResult.statusText = "error";
        }

        max.forEach(element => {
            switch (element) {
                case "0":
                    statusResult.statusMessage.push("Actualización de estado correcta.");
                    statusResult.statusNumPedido.push(arrSuccess);
                    break;
                case "-1":
                    statusResult.statusMessage.push("No existe el servicio Indicado.");
                    statusResult.statusNumPedido.push(arrError);

                    break;
                case "-2":
                    statusResult.statusMessage.push("Servicio ya activado.");
                    statusResult.statusNumPedido.push(arrWarning);

                    break;
                case "-3":
                    statusResult.statusMessage.push("El Conductor ya tiene un servicio asignado a la misma hora.");
                    statusResult.statusNumPedido.push(arrDriverSameHour);
                    break;
                case "-4":
                    statusResult.statusMessage.push("La tractora ya tiene un servicio asignado a la misma hora.");
                    statusResult.statusNumPedido.push(arrTractorSameHour);

                    break;
                case "-5":
                    statusResult.statusMessage.push("El semiRemolque ya tiene un servicio asignado a la misma hora.");
                    statusResult.statusNumPedido.push(arrTrailerSameHour);
                    break;
                default:
                    break;
            }
        });

        if (response.Result.length > 0) {
            this.setState({
                isLoadingEdit: !this.state.isLoadingEdit,
                statusObj: statusResult,
                visibleAlert: true,
                listEditDataTable: [],
                dateTime: this.formatDate(),
            }, () => {
                this.getData(company, this.state.headersSelected, this.state.headersNotSelectedObject, this.state.vehiclesListChangeMassiveState, this.state.driversListChangeMassiveState);
                this.handleOnSort();
            });
        }
    }

    formatDate() {
        let time = new Date();
        let day = (time.getDate() < 10 ? "0" + time.getDate() : time.getDate());
        let month = ((time.getMonth() + 1) < 10 ? "0" + (time.getMonth() + 1) : (time.getMonth() + 1));
        let hour = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours());
        let minutes = (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
        let seconds = (time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds());
        return day + "/" + month + "/" + time.getFullYear() + " " + hour + ":" + minutes + ":" + seconds;
    }

    formatDateApi() {
        let time = this.state.valueDate;
        let day = (time.getDate() < 10 ? "0" + time.getDate() : time.getDate());
        let month = ((time.getMonth() + 1) < 10 ? "0" + (time.getMonth() + 1) : (time.getMonth() + 1));
        let year = time.getFullYear();
        return year.toString() + month.toString() + day.toString();
    }

    handleOnSort() {


        setTimeout(() => {
            let cbs = document.getElementsByTagName('input');
            let checkBoxAll = false;
            let checkBoxAllElement = document.getElementById("checkboxAll");
            let listEditDataTable = [];
            let orders = [];

            if (this.state.allChecked === true) {
                for (let i = 0; i < cbs.length; i++) {
                    if (cbs[i].type === 'checkbox') {
                        checkBoxAllElement.checked = true;
                        cbs[i].checked = true;
                        checkBoxAll = true;

                    }
                }
                this.state.listDataTable.rows.map(service => {
                    listEditDataTable.push(service);
                    orders.push(service.NumPedido);
                    return service;
                });

            } else {
                for (let i = 0; i < cbs.length; i++) {
                    if (cbs[i].type === 'checkbox') {
                        this.state.orders.forEach(element => {
                            if (cbs[i].name === element) {
                                checkBoxAllElement.checked = false;
                                cbs[i].checked = false
                                checkBoxAll = false;

                            }
                        });
                    }
                }
            }
            let th = document.getElementsByTagName("th");
            if (th !== null || th !== undefined) {
                for (let i = 0; i < th.length; i++) {
                    if (th[i].innerText === "Estado") {
                        // th[i].classList = "";
                        // console.log(th[i].classList);
                        // th[i].style.content = '.sorting:before' + 'content: "";';
                        th[i].removeAttribute("class");
                    }
                }
            }
            this.setState({
                listEditDataTable: this.state.listEditDataTable,
                orders: this.state.orders,
                allChecked: checkBoxAll
            });
        }, 0);


    }

    getValue = (value) => {
        this.setState({
            valueDate: value,
            loading: true
        }, async () => {
            let company = this.props.companyName;
            company = company.toLocaleLowerCase();
            await this.getData(company, this.state.headersSelected, this.state.headersNotSelected, this.state.vehiclesListChangeMassiveState, this.state.driversListChangeMassiveState);
        });
    }

    redirectToLogin() {
        this.props.history.push({
            pathname: '/'
        });
    }

    render() {


        return (
            <div>
                {
                    this.state.isLoadingEdit ?
                        <div className="loadingSpinnerEdit">
                            <BeatLoader css={spinnerCss} size={15} margin={4} color={"#bf2349"} />
                        </div>
                        :

                        <div style={{overflow: "auto", height: "93vh"}}>
                            <AlertStatus visibleAlert={this.state.visibleAlert} changeVisibilityAlertStatus={this.changeVisibilityAlertStatus.bind(this)} statusText={this.state.statusObj.statusText} statusMessage={this.state.statusObj.statusMessage} statusNumPedido={this.state.statusObj.statusNumPedido} />
                            <h2 style={{ textAlign: "center", color: "#bf2349" }}>Planificación</h2>
                            <div className="tableContainer">
                                <div className="updatedTime">
                                    <p>Última actualización: <b>{this.state.dateTime}</b></p>
                                </div>

                                <div>
                                    <button onClick={() => this.getData(this.props.companyName.toLowerCase(), this.state.headersSelected, this.state.headersNotSelectedObject, this.state.vehiclesListChangeMassiveState, this.state.driversListChangeMassiveState)} style={{ width: "30%", float: "right", marginRight: "51px", height: "44px" }} className="btn btn-default btnExport">Actualizar Tabla</button>

                                </div>
                                <div>
                                    <div className="datePicker" style={{ float: "right" }}>
                                        <DatePicker
                                            onChange={this.getValue}
                                            value={this.state.valueDate}
                                            format={"dd-MM-y"}
                                            minDate={new Date()}
                                            clearIcon={null}
                                            locale={"es-ES"}
                                        />
                                    </div>
                                </div>
                                <div></div>
                            </div>

                            <div className="grid-container">
                                <div className="grid-item">
                                    <FormGroup aria-label="position" style={{ width: "3%" }} row>
                                        <FormControlLabel
                                            value="start"

                                            control={<Switch checked={this.state.viewFilters ? true : false} onClick={this.onClickToggle} />}
                                            label="Filtros"
                                            labelPlacement="start"
                                        />
                                    </FormGroup>
                                </div>

                            </div>

                            <div className="slide-down slide-up" style={{ display: this.state.viewFilters ? "inline-block" : "none", width: "100%" }}>
                                <div className="grid-container" style={{ width: "100%" }}>
                                    <div className="grid-item">
                                        <Select
                                            value={this.state.clientValue}
                                            onChange={this.handleOnChangeClient.bind(this)}
                                            isSearchable={true}
                                            classNamePrefix="select"
                                            name="Cliente"
                                            placeholder="Cliente"
                                            options={this.state.groupedOptionsClientList}
                                            formatGroupLabel={this.formatGroupLabel}
                                            isClearable={true}
                                            isMulti
                                            hideSelectedOptions={false}
                                        // components={{ ValueContainer }}
                                        />
                                    </div>
                                    <div className="grid-item">
                                        <Select
                                            value={this.state.turnValue}
                                            onChange={this.handleOnChangeTurn.bind(this)}
                                            isSearchable={true}
                                            name="Turno"
                                            placeholder="Turno"
                                            options={this.state.groupedOptionsTurnList}
                                            formatGroupLabel={this.formatGroupLabel}
                                            isClearable={true}
                                            isMulti
                                            hideSelectedOptions={false}
                                        // components={{ ValueContainer }}
                                        />
                                    </div>
                                    <div className="grid-item">
                                        <Select
                                            value={this.state.driverValue}
                                            onChange={this.handleOnChangeDriver.bind(this)}
                                            name="Conductor"
                                            placeholder="Conductor"
                                            options={this.state.groupedOptionsDriverList}
                                            formatGroupLabel={this.formatGroupLabel}
                                            isClearable={true}
                                            isSearchable={true}
                                            isMulti
                                            hideSelectedOptions={false}
                                        // components={{ ValueContainer }}

                                        //     value={this.state.valueDriversEdit}
                                        // onChange={this.handleOnChangeDriverEdit.bind(this)}
                                        // isSearchable={true}
                                        // classNamePrefix="select"
                                        // name="test"
                                        // placeholder="Conductores"
                                        // options={this.state.driversList}
                                        // formatGroupLabel={this.formatGroupLabel}
                                        // isClearable={true}
                                        // isDisabled={!this.state.driverIsEnabled}
                                        />
                                    </div>
                                    <div className="grid-item">
                                        <Select
                                            value={this.state.tractorValue}
                                            onChange={this.handleOnChangeTractor.bind(this)}
                                            name="Tractora"
                                            placeholder="Tractora"
                                            options={this.state.groupedOptionsTractorList}
                                            formatGroupLabel={this.formatGroupLabel}
                                            isClearable={true}
                                            isSearchable={true}
                                            isMulti
                                            hideSelectedOptions={false}
                                        // components={{ ValueContainer }}
                                        />
                                    </div>
                                    <div className="grid-item">
                                        <Select
                                            value={this.state.trailerValue}
                                            onChange={this.handleOnChangeTrailer.bind(this)}
                                            name="Remolque"
                                            placeholder="Remolque"
                                            options={this.state.groupedOptionsTrailerList}
                                            formatGroupLabel={this.formatGroupLabel}
                                            isClearable={true}
                                            isSearchable={true}
                                            isMulti
                                            hideSelectedOptions={false}
                                        // components={{ ValueContainer }}
                                        />
                                    </div>

                                    <div className="grid-item">
                                        <Select
                                            value={this.state.centerValue}
                                            onChange={this.handleOnChangeCenter.bind(this)}
                                            name="Centro"
                                            placeholder="Centro"
                                            options={this.state.groupedOptionsCenterList}
                                            formatGroupLabel={this.formatGroupLabel}
                                            isClearable={true}
                                            isSearchable={true}
                                            isMulti
                                            hideSelectedOptions={false}
                                        // components={{ ValueContainer }}
                                        />
                                    </div>
                                    <div className="grid-item">
                                        <Select
                                            value={this.state.salesValue}
                                            onChange={this.handleOnChangeSales.bind(this)}
                                            name="Ventas"
                                            placeholder="Ventas"
                                            options={this.state.groupedOptionsSalesList}
                                            formatGroupLabel={this.formatGroupLabel}
                                            isClearable={true}
                                            isSearchable={true}
                                            isMulti
                                            hideSelectedOptions={false}
                                        // components={{ ValueContainer }}
                                        />
                                    </div>
                                    <div className="grid-item">
                                        <Select
                                            value={this.state.channelValue}
                                            onChange={this.handleOnChangeChannel.bind(this)}
                                            name="Canal"
                                            placeholder="Canal"
                                            options={this.state.groupedOptionsChannelList}
                                            formatGroupLabel={this.formatGroupLabel}
                                            isClearable={true}
                                            isSearchable={true}
                                            isMulti
                                            hideSelectedOptions={false}
                                        // components={{ ValueContainer }}
                                        />
                                    </div>
                                    <div className="grid-item">
                                        <Select
                                            value={this.state.stateValue}
                                            onChange={this.handleOnChangeState.bind(this)}
                                            name="Estado"
                                            placeholder="Estado"
                                            options={this.state.groupedOptionsStateList}
                                            formatGroupLabel={this.formatGroupLabel}
                                            isClearable={true}
                                            isSearchable={true}
                                            isMulti
                                            hideSelectedOptions={false}
                                        // components={{ ValueContainer }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <button style={{ display: this.state.modalMobile === true ? "block" : "none" }} className="btnModal" onClick={this.onClickHandleModal.bind(this)}>Visualización  <img alt="iconFilter" src={imageFilter} style={{ width: "10%", marginLeft: "5px" }}></img></button>

                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className="modalCustom"
                                open={this.state.modalShow}
                                onClose={this.onClickHandleModal.bind(this)}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}

                            >
                                <Fade in={this.state.modalShow}>

                                    <div className="paper">

                                        {
                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                {
                                                    this.state.listTableTranslate !== undefined && this.state.listTableTranslate.columnOrder !== undefined ?
                                                        this.state.listTableTranslate.columnOrder.map(columnId => {
                                                            const column = this.state.listTableTranslate.columns[columnId];
                                                            const headers = column.headersIds.map(headerId => this.state.listTableTranslate.headers[headerId]);
                                                            return <Column key={column.id} column={column} headers={headers} />
                                                        })
                                                        :
                                                        null
                                                }

                                            </DragDropContext>

                                        }
                                        <p></p>
                                        {/* height: 34px;
                                        width: 287px;
                                        margin-left: 8px;
                                        border-radius: 3px;
                                        margin-bottom: 30px; */}

                                        <button style={{ height: "34px", width: "243px", marginLeft: "8px", borderRadius: "3px", marginBottom: "30px" }} className="btn btn-default" onClick={this.filterGlobal.bind(this)}>Aceptar</button>
                                    </div>
                                </Fade>
                            </Modal>

                            {this.state.modalShowChangeMassive ?
                                <ModalChangeMassive changeVisibilityModal={this.changeVisibilityModal} applyFilter={this.filterGlobal.bind(this)} vehiclesListChangeMassive={this.state.vehiclesListChangeMassiveState} driversListChangeMassive={this.state.driversListChangeMassiveState} changeVisibilityAlertStatusAndModal={this.changeVisibilityAlertStatusAndModal.bind(this)} orders={this.state.orders} modalShowChangeMassive={this.state.modalShowChangeMassive} onClickHandleModalChangeMassive={this.onClickHandleModalChangeMassive.bind(this)} />
                                :
                                null
                            }


                            <div style={{ display: this.state.listEditDataTable.length > 0 ? "" : "none" }} className="grid-container-planing-btn">
                                <div>
                                    <button className="btn btn-default" onClick={this.handleOnClickActivateButton.bind(this)}>Activar</button>
                                </div>
                                <div>
                                    <button className="btn btn-default" onClick={this.handleOnClickDeleteButton.bind(this)}>Eliminar</button>
                                </div>
                                <div>
                                    <button className="btn btn-default" onClick={this.onClickHandleModalChangeMassive.bind(this)}>Cambio Masivo</button>
                                </div>
                            </div>

                            {this.state.isLoading ?
                                <div className="loadingSpinner">
                                    <BeatLoader css={spinnerCss} size={15} margin={4} color={"#bf2349"} />
                                </div>
                                :
                                <MDBDataTable name="table"
                                    sortable={true}
                                    striped
                                    bordered={false}
                                    paging={false}
                                    searching={false}
                                    data={this.state.listDataTable}
                                    className="tableServices"
                                    responsive
                                    onSort={this.handleOnSort.bind(this)}

                                >
                                    {/* <MDBTableHead columns={this.state.listDataTable.columns} />
                                    <MDBTableBody rows={this.state.listDataTable.rows} /> */}
                                </MDBDataTable  >
                            }
                        </div>
                }
            </div>
        )
    }
}
export default withRouter(dataTablePlaning);

// const ValueContainer = ({ children, getValue, ...props }) => {
//     let maxToShow = 2;
//     var length = getValue().length;
//     let displayChips = React.Children.toArray(children).slice(0, maxToShow);
//     let title = getValue()
//         .slice(maxToShow)
//         .map((x) => x.label)
//         .join(", ");
//     let shouldBadgeShow = length > maxToShow;
//     let displayLength = length - maxToShow;
//     return (
//         <components.ValueContainer {...props}>
//             {!props.selectProps.inputValue && displayChips}
//             {!!shouldBadgeShow && (
//                 <div className="root" title={title}>
//                     {/* {`+ ${displayLength} Opcione${length !== 1 ? "s" : ""} seleccionadas`} */}
//                     {`+ ${displayLength} `}
//                 </div>
//             )}
//         </components.ValueContainer>
//     );
// };
