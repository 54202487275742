import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import { Grid, Paper } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import BeatLoader from 'react-spinners/BeatLoader';

import Config from '../../../../config';

const spinnerCss = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  marginLeft: '-30px'
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogComment(props) {
  const [userComments, setUserComments] = React.useState([]);
  const [textFieldValue, setTextFieldValue] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const selectedOt = JSON.parse(localStorage.getItem("selectedOt"));
  //const selectedOt = props.selectedOt === false ? props.location.selectedOt : props.selectedOt;

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };


  const publishCommentClick = async () => {

    setIsLoading(true);
    const requestBody = {
      TicketNo: selectedOt.TicketNumber,
      CommentContent: textFieldValue,
      UserId: selectedOt.UserId,
      UserName: selectedOt.UserName,
      userAccessKey: selectedOt.accesskey
    };


    try {
      const response = await fetch(`${Config.apiGateway.URL}Installers/Comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),

      });

      if (response.ok) {
        // Hacer algo si la respuesta es exitosa
        setTextFieldValue('');
        GetUserComments();


      } else {
        // Manejar el caso de error en la respuesta
        console.error('Error en la solicitud');
      }
    } catch (error) {
      // Manejar errores de red u otras excepciones
      console.error('Error:', error);
    }
    setIsLoading(false);
  }

  React.useEffect(() => {
    const GetUserComments = async () => {
      try {
        //const token = 'TU_TOKEN_JWT'; // Reemplaza con tu token JWT
        const response = await fetch(`${Config.apiGateway.URL}Installers/Comments?ticketNumber=${selectedOt.TicketNumber}&userId=${selectedOt.UserId}`, {
          method: 'GET',
          // headers: {
          //   'Authorization': `Bearer ${token}`
          // }
        });

        if (response.ok) {
          const jsonData = await response.json();

          if (jsonData.length > 0) {
            jsonData.forEach(element => {
              var dateParts = element.CreatedTime.split("-");
              var hoursParts = dateParts[2].split(" ");

              let result = hoursParts[0] + "/" + dateParts[1] + "/" + dateParts[0] + " " + hoursParts[1];


              element.CreatedTime = result;

            });
          }
          setUserComments(jsonData);
        } else {
          console.error('Error en la respuesta:', response.status);
        }
      } catch (error) {
        console.error('Error en la llamada:', error);
      }
    }
    GetUserComments();
  }, [selectedOt.TicketNumber, selectedOt.UserId]);



  const GetUserComments = async () => {
    try {
      //const token = 'TU_TOKEN_JWT'; // Reemplaza con tu token JWT
      const response = await fetch(`${Config.apiGateway.URL}Installers/Comments?ticketNumber=${selectedOt.TicketNumber}&userId=${selectedOt.UserId}`, {
        method: 'GET',
        // headers: {
        //   'Authorization': `Bearer ${token}`
        // }
      });

      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.length > 0) {
          jsonData.forEach(element => {
            var dateParts = element.CreatedTime.split("-");
            var hoursParts = dateParts[2].split(" ");

            let result = hoursParts[0] + "/" + dateParts[1] + "/" + dateParts[0] + " " + hoursParts[1];


            element.CreatedTime = result;

          });
        }

        setUserComments(jsonData);

      } else {
        console.error('Error en la respuesta:', response.status);
      }
    } catch (error) {
      console.error('Error en la llamada:', error);
    }
  }

  const classes = useStyles();
  return (
    <div>
      <Dialog fullScreen open={props.openDialogComment} onClose={props.handleCloseDialogComment} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.handleCloseDialogComment} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Ticket: {selectedOt.TicketNumber}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="md">
          <div style={{ padding: 14, justifyContent: "right" }} >
            <h1>Comentarios</h1>
            <TextField
              value={textFieldValue}
              placeholder="Añade su comentario aquí..."
              multiline
              variant='outlined'
              onChange={handleTextFieldChange}
              maxRows={6}
            />
            <Button variant='outlined' onClick={publishCommentClick} style={{ marginTop: 20 }}>Publicar</Button>

            {userComments.length > 0 &&
              userComments.map((item, index) => (
                item.modcommentsid !== null &&
                <Paper key={index} style={{ padding: "20px 20px", marginTop: 30 }}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <h4 style={{ textAlign: "left" }}>{item.CreatedTime}</h4>
                    </Grid>

                    <Grid item xs zeroMinWidth>

                      <p style={{ textAlign: "left" }}>
                        {item.CommentContent}
                      </p>
                    </Grid>
                  </Grid>
                </Paper>
              ))}

          </div>
        </Container>
        {isLoading ?
          <div className="loadingSpinner">
            <BeatLoader css={spinnerCss} size={15} margin={4} color={"#bf2349"} />
          </div>
          :
          null
        }

      </Dialog>
    </div >
  )
}

export default DialogComment