import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import { compose } from 'recompose';
// import { MDBDataTable } from 'mdbreact';
import Select from 'react-select';
import { TextField } from '@material-ui/core/';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { DataGrid, esES, } from '@material-ui/data-grid';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/IconButton';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import AlertStatus from '../common/alerts/AlertStatus';

import BeatLoader from 'react-spinners/BeatLoader';
import ModalView from './ModalView';
//import DialogView from './DialogView'
import DialogViewSondas from './DialogViewSondas';

import './SondasBT.css';



import { withTranslation } from 'react-i18next';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
const theme = createTheme(
  {
    typography: {
      htmlFontSize: 10
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          html: {
            fontSize: "62.5%"
          }
        }
      }
    }
  },
  esES,
);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    // '&:focus': {
    //     backgroundColor: "#bf2349",
    //     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //         color: theme.palette.common.white,
    //     },
    // },
  },
}))(MenuItem);

const spinnerCss = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  marginLeft: '-30px'
}

const styles = theme => ({

  containerTextField: {
    '& .MuiTextField-root': {
      // margin: theme.spacing(1),
      width: "100%",
    },
    '& .MuiInputBase-root': {
      fontSize: "14px"
    },
    '& .MuiInputBase-input': {
      height: "1.7rem"
    }
  },
});

class SondasBT extends Component {
  state = {
    copyDataTable: {},
    dataTable: {
      columns: [],
      rows: []
    },
    modalViewShow: false,
    dialogViewShow: false,
    globalFilter: {
      combo1: [],
      combo2: []
    },
    visibleAlert: false,
    statusObjAlert: {},
    selectedRowsIds: [],
    objSelectedToView: {},

    groupedOptionsStateList: [{
      label: this.props.t('filters.state'),
      options: [
        { tag: "Todos", value: this.props.t('filters.all'), label: this.props.t('filters.all') },
        { tag: "Alta", value: this.props.t('filters.high'), label: this.props.t('filters.high') },
        { tag: "Baja", value: this.props.t('filters.low'), label: this.props.t('filters.low') },
      ],
    }],
    OptionStateSelected: [{ tag: "Todos", value: this.props.t('filters.all'), label: this.props.t('filters.all') }],
    accessToken: this.props.location.state !== undefined ? this.props.location.state.accessToken : "",
    isLoading: true,
    listVehiclesGroups: [],
    assetsInfo: [],
    assetsTags: [],
    locale: "",
    anchorEl: null


  }

  async componentDidMount() {
    let languageText = "";



    if (this.props.location.state !== undefined) {
      let locale = this.props.location.state.locale;
      languageText = locale.split("=");
      this.props.i18n.changeLanguage(languageText[1]);

    }

    let assetsTags = await this.getAssetsTags();
    let assetsInfo = await this.getAssetsInfo();
    let groupsNewAccessList = await this.getGroupsFromApi();
    let groupTags = [];

    assetsTags.forEach(assetTag => {
      assetsInfo.forEach(assetInfo => {
        if (assetInfo.assetsTagsId === assetTag.assetsTagsId) {
          assetTag.assetsTagInfo.push({
            value: assetInfo.assetsInfoId, label: assetInfo.assetsInfoName
          });
        }
      });
      groupTags.push({
        "label": assetTag.assetsTagName,
        "options": assetTag.assetsTagInfo
      });

      assetsTags.forEach(element => {
        element.assetsTagInfo = []
      });
    });

    let listAccessControlRows = await this.getDataListControlAccess(2);

    if (listAccessControlRows === false) {
      listAccessControlRows = [];
    }
    
    // let objTest = {
    //   fecha: "1/7/2024, 14:58:34",
    //   id: "7CD9F4142B-900",
    //   sonda: "7CD9F414230B",
    //   temperatura:"--",
    //   tipo: "Sensor",
    //   vehiculo: "DAP_gps",
    // }
    //listAccessControlRows.push(objTest);

    this.setState({
      dataTable: {
        columns: [
          { field: 'id', hide: true },
          { field: 'vehiculo', headerName: "Vehículo", width: 210 },
          { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
          { field: 'tipo', headerName: "Tipo", width: 200 },
          {
            field: 'temperatura',
            headerName: "Temperatura",
            width: 200,
            valueGetter: (params) => {

              // Verificar si el valor es null
              if (params.value === null || params.value === undefined) {
                // Si es null, devuelve el texto deseado
                return '--';
              } else if(params.value === 300){
                return 'No Detectado';
              }
              // Si no es null, devuelve el valor original
              return params.value;
            },
          },
          {
            field: 'fecha',
            headerName: "Fecha",
            width: 150,
            valueGetter: (params) => {
              // Verificar si el valor es null
              if (params.value === null) {
                return '--/--/-- --:--:--';
              }
              // Si no es null, devuelve el valor original
              return params.value;
            },
          },
          
          // { field: 'id', hide: true },
          // { field: 'observations', hide: true },
          // { field: 'email', headerName: this.props.t('headersTable.email'), width: 210 },
          // { field: 'inicio', headerName: this.props.t('headersTable.dateStart'), type: 'dateTime', width: 200 },
          // { field: 'fin', headerName: this.props.t('headersTable.dateEnd'), type: 'dateTime', width: 200 },
          // { field: 'nVehiculos', headerName: this.props.t('headersTable.nVehicles'), width: 150 },
        ],
        rows: listAccessControlRows

      },
      copyDataTable: {
        columns: [
          { field: 'id', hide: true },
          { field: 'vehiculo', headerName: "Vehículo", width: 210 },
          { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
          { field: 'tipo', headerName: "Tipo", width: 200 },
          {
            field: 'temperatura',
            headerName: "Temperatura",
            width: 200,
            valueGetter: (params) => {
              // Verificar si el valor es null
              if (params.value === null || params.value === undefined) {
                // Si es null, devuelve el texto deseado
                return '--';
              } else if(params.value === 300){
                return 'No Detectado';
              }
              // Si no es null, devuelve el valor original
              return params.value;
            },
          },
          {
            field: 'fecha',
            headerName: "Fecha",
            width: 150,
            valueGetter: (params) => {
              // Verificar si el valor es null
              if (params.value === null) {
                return '--/--/-- --:--:--';
              }
              // Si no es null, devuelve el valor original
              return params.value;
            },
          },
          
        ],
        rows: listAccessControlRows
      },
      isLoading: false,
      //groupedOptionsStateList: groupedOptionsStateList,
      listGroupWithVehicles: groupTags,
      assetsTags: assetsTags,
      assetsInfo: assetsInfo,
      copyAssetsTags: assetsTags,
      locale: languageText[1],
      groupsNewAccessList: groupsNewAccessList
    }, () => {
      let optionStateSelected = { tag: "Todos", value: this.props.t('filters.all'), label: this.props.t('filters.all') };
      this.setState({
        //groupedOptionsStateList: groupedOptionsStateList,
        OptionStateSelected: optionStateSelected,
        tagsGroups: assetsTags
      });
    });
  }

  async getGroupsFromApi() {
    let response = "";
    const settings = {
      method: 'GET',
      headers: {
        'accessToken': this.props.location.state !== undefined ? this.props.location.state.AccessToken : this.state.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    };
    let responseCreateGroup = await fetch(`${config.apiGateway.URL}AccessControl/Transport/Group`, settings);


    if (responseCreateGroup.ok) {
      if (responseCreateGroup !== null || responseCreateGroup !== undefined) {
        try {
          response = await responseCreateGroup.json();
          let newResponse = [];
          response.forEach(element => {
            let newObject = {
              label: element.name,
              value: element.id
            }
            newResponse.push(newObject);
          });
          return newResponse;


        } catch (error) {
          // this.setState({
          //     rows: [],
          //     isLoading: false
          // });
        }
      }
      // console.log(response);
      // response = null;


    } else {
      // alert("HTTP-Error: " + responseServices.status);
      return;
    }
  }

  async getDataListControlAccess(filter) {
    // console.log(filter);
    let listAccessControl = await this.getAccessControlList(filter);

    if (listAccessControl === undefined) {
      return false;
    } else {
      let rows = [];
      var options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false,
      };
      var dtfEs = new Intl.DateTimeFormat('es-ES', options);
      if (listAccessControl !== undefined) {
        listAccessControl.forEach(element => {
          let fecha = new Date(element.fecha);
          rows.push({
            id: element.sonda + "-" + element.idVehiculo,
            vehiculo: element.vehiculo,
            sonda: element.sonda,
            temperatura: element.temperatura,
            fecha: element.fecha === null ? "--/--/--" : dtfEs.format(fecha),
            tipo: element.tipo === 2 ? 'Sonda Temperatura' : 'Sensor'
          });
        });
        return rows;
      }
    }
  }
  my_date(date_string) {
    var date_components = date_string.split("-");
    var day = date_components[2];
    var month = date_components[1];
    var year = date_components[0];
    return new Date(year, month - 1, day);
  }

  redirectToLogin() {
    this.props.history.push({
      pathname: '/'

    });
  }

  async getAssetsTags() {

    let responseGroups = await fetch(`${config.apiGateway.URL}AssetsTags`, {
      headers: {
        'accessToken': this.props.location.state !== undefined ? this.props.location.state.AccessToken : this.state.accessToken,
      }
    });

    if (responseGroups.ok) { // if HTTP-status is 200-299
      let jsonGroups = await responseGroups.json();
      // localStorage.setItem("jsonGroups", JSON.stringify(jsonGroups));
      return jsonGroups;

    } else {
      // alert("HTTP-Error: " + responseGroups.status);
      // this.goToPageErr();
      return;
    }
  }

  async getAssetsInfo() {
    let responseInfo = await fetch(`${config.apiGateway.URL}AssetsInfo`, {
      headers: {
        'accessToken': this.props.location.state !== undefined ? this.props.location.state.AccessToken : this.state.accessToken,
      }
    });

    if (responseInfo.ok) {
      let jsonInfo = await responseInfo.json();
      // console.log(jsonInfo);
      return jsonInfo;

    } else {
      // alert("HTTP-Error: " + responseInfo.status);
      // this.goToPageErr();
      return;
    }
  }

  async getAccessControlList(filter) {
    let response = {};
    const settings = {
      method: 'GET',
      headers: {
        'accessToken': this.props.location.state !== undefined ? this.props.location.state.AccessToken : this.state.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    // IsActive(bool), Email, Observations
    let responseServices = await fetch(`${config.apiGateway.URL}Sensors`, settings);
    // let responseServices = await fetch(config.apiGateway.URL + "/AccessControl?IsActive=false&Email=''&Observations=", settings);

    if (responseServices.ok) {
      if (responseServices !== null || responseServices !== undefined) {
        try {
          response = await responseServices.json();
          return response;
        } catch (error) {
          response = null;
        }
      }
      // console.log(response);
      // response = null;


    } else {
      // alert("HTTP-Error: " + responseServices.status);
      // this.goToPageErr();
      return;
    }
  }

  goToPageErr = async () => {
    // this.props.userHasAuthenticated(false);
    // console.log("entra");
    // this.props.history.push({
    //   pathname: '/ExpireKeyErr/',
    //   state: {
    //     userDataInfo: '',
    //     locale: this.state.locale
    //   }
    // });
    window.alert("No se ha podido acceder al sistema.");
  }

  handleOnSelection = (e) => {

    // console.log(e.rowIds);
    // let result = this.state.copyDataTable.rows.filter(x => x.id === parseInt(e.rowIds[0]));
    if (e.length === 0) {
      this.setState({
        selectedRowsIds: e,
        objSelectedToView: {}
      });
    } else {
      this.setState({
        selectedRowsIds: e
      });
    }
  }

  formatDateFromApi(date) {
    let newDateAndHour = date.split("T");
    let newDate = newDateAndHour[0].split("-");
    let newDateAndHourString = newDate[2] + "/" + newDate[1] + "/" + newDate[0] + " " + newDateAndHour[1];
    return newDateAndHourString;
  }

  formatDate() {
    let time = new Date();
    let day = (time.getDate() < 10 ? "0" + time.getDate() : time.getDate());
    let month = ((time.getMonth() + 1) < 10 ? "0" + (time.getMonth() + 1) : (time.getMonth() + 1));
    let hour = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours());
    let minutes = (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
    // let seconds = (time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds());
    return day + "/" + month + "/" + time.getFullYear() + " " + hour + ":" + minutes;
  }

  onClickHandleModalView = () => {
    this.setState({ modalViewShow: !this.state.modalViewShow, objSelectedToView: {} });
    // console.log(this.props.isAuthenticated);
  }

  onCloseModalView = () => {
    this.setState({ modalViewShow: false });
  }

  handleOnClickView() {
    if (this.state.selectedRowsIds.length === 0) {
      let statusResult = {
        statusText: "warning",
        statusMessage: [this.props.t("alerts.errSelectedView")],
        statusNumPedido: []
      }
      this.setState({
        statusObjAlert: statusResult,
        visibleAlert: true,
      }, () => {
        setTimeout(() => {
          this.setState({
            visibleAlert: false
          });
        }, 5000);
      });
    } else {
      if (this.state.selectedRowsIds.length > 1) {
        let statusResult = {
          statusText: "warning",
          statusMessage: [this.props.t("alerts.errSelectedView2")],
          statusNumPedido: []
        }
        this.setState({
          statusObjAlert: statusResult,
          visibleAlert: true,
        }, () => {
          setTimeout(() => {
            this.setState({
              visibleAlert: false
            });
          }, 5000);
        });
      } else {
        let result = this.state.dataTable.rows.filter(x => x.id === this.state.selectedRowsIds[0]);

        // console.log(result);
        this.setState({
          objSelectedToView: result,
          modalViewShow: !this.state.modalViewShow
        });
      }
    }

  }
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleOnClickDelete() {
    // let result = this.state.copyDataTable.rows.filter(x => x.id !== parseInt(this.state.selectedRowsIds[0]));
    if (this.state.selectedRowsIds.length === 0) {
      let statusResult = {
        statusText: "warning",
        statusMessage: [this.props.t("alerts.errSelectedDelete")],
        statusNumPedido: []
      }
      this.setState({
        statusObjAlert: statusResult,
        visibleAlert: true,
      }, () => {
        setTimeout(() => {
          this.setState({
            visibleAlert: false
          });
        }, 5000);
      });
    } else {
      this.onCloseOrOpenDialogView();
    }

  }

  handleOnChangeDescription = (event) => {
    let textToBeSearch = event.target.value;

    if (textToBeSearch != null) {
      // Verificar si la descripción no está vacía
      if (textToBeSearch.trim() !== "") {
        // Filtrar la lista de this.state.copyDataTable.rows por la descripción
        const filteredRows = this.state.copyDataTable.rows.filter(row => {
          // Verificar si row.vehiculo no es nulo antes de intentar acceder a sus propiedades
          return row.vehiculo && row.vehiculo.toLowerCase().includes(textToBeSearch.toLowerCase());
        });

        let newDataTable = {
          ...this.state.dataTable,
          rows: filteredRows
        }
        this.setState({
          dataTable: newDataTable,
        })

      } else {
        // Si la descripción está vacía, mostrar todas las filas
        this.setState({ dataTable: this.state.copyDataTable });
      }
    }
  }

  async filterGlobal(filter) {
    let filteredList = this.state.copyDataTable.rows;
    for (const key in this.state.globalFilter) {
      if (this.state.globalFilter.hasOwnProperty(key)) {
        const element = this.state.globalFilter[key];
        if (element.length > 0) {
          filteredList = this.filterTest(element, filteredList, key);
        }
      }
    }
    let newDataTable = {
      ...this.state.dataTable,
      rows: filteredList
    }
    this.setState({
      dataTable: newDataTable,
    })
  }

  filterTest(element, filteredList, key) {
    let arr = [];
    switch (key) {
      case "combo1":
        for (let i = 0; i < element.length; i++) {

          filteredList.filter(item => {
            if (item["email"].match(element[i])) {
              arr.push(item);
            }
            return item;
          });
        }
        break;
      default:
        break;
    }
    return arr;
  }

  changeVisibilityAlertStatus() {
    this.setState({
      visibleAlert: !this.state.visibleAlert
    });
  }

  onCloseOrOpenDialogView = (e) => {
    // console.log(e);
    this.setState({
      dialogViewShow: !this.state.dialogViewShow
    });
  }

  async deleteData() {
    let response = "";
    let objectToDelete = [];
    let copyeSelectedRow = this.state.selectedRowsIds;

    copyeSelectedRow.forEach(element => {
      let arr = element.split('-');
      objectToDelete.push({
        sonda: arr[0],
        idVehiculo: arr[1]
      })
    });

    const settings = {
      method: 'DELETE',
      headers: {
        'accessToken': this.props.location.state !== undefined ? this.props.location.state.AccessToken : this.state.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(objectToDelete)

      // `[${newArrayBody.map(service => '[' + service + ']')}]`
    };

    const fetchResponse = await fetch(`${config.apiGateway.URL}Sensors`, settings);
    if (fetchResponse.ok) {
      if (fetchResponse !== null) {
        try {
          response = await fetchResponse.json();
          return response;
        } catch (error) {
          return error;
          // console.log(error);
        }
      }
    } else {
      this.goToPageErr();
      // return fetchResponse.status;
      // alert("HTTP-Error: " + fetchResponse.status);
    }
  }

  handleOnclickAcceptDialogView = async () => {
    // let result = [];
    // let result2 = [];
    // let bool = false;
    await this.deleteData();


    let statusResult = {
      statusText: "success",
      statusMessage: [this.props.t("alerts.successDelete")],
      statusNumPedido: []
    }

    let filter = -2;
    if (this.state.OptionStateSelected !== undefined) {
      if (this.state.OptionStateSelected.tag === "Todos") {
        filter = 2;
      } else if (this.state.OptionStateSelected.tag === "Alta") {
        filter = 1;
      } else if (this.state.OptionStateSelected.tag === "Baja") {
        filter = 0
      }
    }
    let listAccessControlRows = await this.getDataListControlAccess(filter);
    if (listAccessControlRows === false) {
      listAccessControlRows = [];
    }
    this.setState({
      selectedRowsIds: [],
      dataTable: {
        columns: [
          { field: 'id', hide: true },
          { field: 'vehiculo', headerName: "Vehículo", width: 210 },
          { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
          { field: 'tipo', headerName: "Tipo", width: 200 },
          {
            field: 'temperatura',
            headerName: "Temperatura",
            width: 200,
            valueGetter: (params) => {
              // Verificar si el valor es null
              if (params.value === null || params.value === undefined) {
                // Si es null, devuelve el texto deseado
                return '--';
              } else if(params.value === 300){
                return 'No Detectado';
              }
              // Si no es null, devuelve el valor original
              return params.value;
            },
          },
          {
            field: 'fecha',
            headerName: "Fecha",
            width: 150,
            valueGetter: (params) => {
              // Verificar si el valor es null
              if (params.value === null) {
                return '--/--/-- --:--:--';
              }
              // Si no es null, devuelve el valor original
              return params.value;
            },
          },
          
        ],
        rows: listAccessControlRows

      },
      copyDataTable: {
        columns: [
          { field: 'id', hide: true },
          { field: 'vehiculo', headerName: "Vehículo", width: 210 },
          { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
          { field: 'tipo', headerName: "Tipo", width: 200 },
          {
            field: 'temperatura',
            headerName: "Temperatura",
            width: 200,
            valueGetter: (params) => {
              // Verificar si el valor es null
              if (params.value === null || params.value === undefined) {
                // Si es null, devuelve el texto deseado
                return '--';
              } else if(params.value === 300){
                return 'No Detectado';
              }
              // Si no es null, devuelve el valor original
              return params.value;
            },
          },
          {
            field: 'fecha',
            headerName: "Fecha",
            width: 150,
            valueGetter: (params) => {
              // Verificar si el valor es null
              if (params.value === null) {
                return '--/--/-- --:--:--';
              }
              // Si no es null, devuelve el valor original
              return params.value;
            },
          },
          
        ],
        rows: listAccessControlRows
      },
      // modalViewShow: !this.state.modalViewShow,
      dialogViewShow: false,
      statusObjAlert: statusResult,
      visibleAlert: true,
    }, () => {
      // this.filterGlobal(this.state.OptionStateSelected.tag);
      setTimeout(() => {
        this.setState({
          visibleAlert: false
        });
      }, 5000);
    });

    // this.setState({
    //     selectedRowsIds: [],
    //     dataTable: newDataTable,
    //     dialogViewShow: false
    //     // modalViewShow: !this.state.modalViewShow
    // });
  }

  handleOnCloseModalAndShowAlert = (obj) => {
    this.setState({
      statusObjAlert: obj,
      modalViewShow: false,
      visibleAlert: true,
      // isLoading: true
    }, async () => {
      let filter = -2;
      if (this.state.OptionStateSelected !== undefined) {
        if (this.state.OptionStateSelected.tag === "Todos") {
          filter = 2;
        } else if (this.state.OptionStateSelected.tag === "Alta") {
          filter = 1;
        } else if (this.state.OptionStateSelected.tag === "Baja") {
          filter = 0
        }
      }

      let listAccessControlRows = await this.getDataListControlAccess(filter);

      this.setState({
        selectedRowsIds: [],
        dataTable: {
          columns: [
            { field: 'id', hide: true },
            { field: 'vehiculo', headerName: "Vehículo", width: 210 },
            { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
            { field: 'tipo', headerName: "Tipo", width: 200 },
            {
              field: 'temperatura',
              headerName: "Temperatura",
              width: 200,
              valueGetter: (params) => {
                // Verificar si el valor es null
                if (params.value === null || params.value === undefined) {
                  // Si es null, devuelve el texto deseado
                  return '--';
                } else if(params.value === 300){
                  return 'No Detectado';
                }
                // Si no es null, devuelve el valor original
                return params.value;
              },
            },
            {
              field: 'fecha',
              headerName: "Fecha",
              width: 150,
              valueGetter: (params) => {
                // Verificar si el valor es null
                if (params.value === null) {
                  return '--/--/-- --:--:--';
                }
                // Si no es null, devuelve el valor original
                return params.value;
              },
            },
           
          ],
          rows: listAccessControlRows

        },
        copyDataTable: {
          columns: [
            { field: 'id', hide: true },
            { field: 'vehiculo', headerName: "Vehículo", width: 210 },
            { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
            { field: 'tipo', headerName: "Tipo", width: 200 },
            {
              field: 'temperatura',
              headerName: "Temperatura",
              width: 200,
              valueGetter: (params) => {
                // Verificar si el valor es null
                if (params.value === null || params.value === undefined) {
                  // Si es null, devuelve el texto deseado
                  return '--';
                } else if(params.value === 300){
                  return 'No Detectado';
                }
                // Si no es null, devuelve el valor original
                return params.value;
              },
            },
            {
              field: 'fecha',
              headerName: "Fecha",
              width: 150,
              valueGetter: (params) => {
                // Verificar si el valor es null
                if (params.value === null) {
                  return '--/--/-- --:--:--';
                }
                // Si no es null, devuelve el valor original
                return params.value;
              },
            },
            
          ],
          rows: listAccessControlRows,
        },
        // modalViewShow: !this.state.modalViewShow,

      }, () => {
        // this.filterGlobal(this.state.OptionStateSelected.tag);
        setTimeout(() => {
          this.setState({
            visibleAlert: false
          });
        }, 5000);
      });
    });
  }

  async handleOnChangeFilterSelectOption(newValue) {
    if (newValue === null) {
      newValue = { tag: "Todos" };
    }
    let listAccessControlRows = [];
    // console.log(newValue);

    switch (newValue.tag) {
      case "Alta":
        listAccessControlRows = await this.getDataListControlAccess(1);
        if (listAccessControlRows !== false) {
          // console.log("entra");
          this.setState({
            dataTable: {
              columns: [
                { field: 'id', hide: true },
                { field: 'vehiculo', headerName: "Vehículo", width: 210 },
                { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
                { field: 'tipo', headerName: "Tipo", width: 200 },
                {
                  field: 'temperatura',
                  headerName: "Temperatura",
                  width: 200,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null || params.value === undefined) {
                      // Si es null, devuelve el texto deseado
                      return '--';
                    } else if(params.value === 300){
                      return 'No Detectado';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                {
                  field: 'fecha',
                  headerName: "Fecha",
                  width: 150,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null) {
                      return '--/--/-- --:--:--';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                
              ],
              rows: listAccessControlRows

            },
            copyDataTable: {
              columns: [
                { field: 'id', hide: true },
                { field: 'vehiculo', headerName: "Vehículo", width: 210 },
                { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
                { field: 'tipo', headerName: "Tipo", width: 200 },
                {
                  field: 'temperatura',
                  headerName: "Temperatura",
                  width: 200,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null || params.value === undefined) {
                      // Si es null, devuelve el texto deseado
                      return '--';
                    } else if(params.value === 300){
                      return 'No Detectado';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                {
                  field: 'fecha',
                  headerName: "Fecha",
                  width: 150,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null) {
                      return '--/--/-- --:--:--';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                
              ],
              rows: listAccessControlRows
            },
            isLoading: false,
            // valueTextToBeSearch: undefined,
            OptionStateSelected: newValue
          });
        } else {
          this.setState({
            dataTable: {
              columns: [
                { field: 'id', hide: true },
                { field: 'vehiculo', headerName: "Vehículo", width: 210 },
                { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
                { field: 'tipo', headerName: "Tipo", width: 200 },
                {
                  field: 'temperatura',
                  headerName: "Temperatura",
                  width: 200,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null || params.value === undefined) {
                      // Si es null, devuelve el texto deseado
                      return '--';
                    } else if(params.value === 300){
                      return 'No Detectado';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                {
                  field: 'fecha',
                  headerName: "Fecha",
                  width: 150,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null) {
                      return '--/--/-- --:--:--';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                
              ],
              rows: []

            },
            copyDataTable: {
              columns: [
                { field: 'id', hide: true },
                { field: 'vehiculo', headerName: "Vehículo", width: 210 },
                { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
                { field: 'tipo', headerName: "Tipo", width: 200 },
                {
                  field: 'temperatura',
                  headerName: "Temperatura",
                  width: 200,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null || params.value === undefined) {
                      // Si es null, devuelve el texto deseado
                      return '--';
                    } else if(params.value === 300){
                      return 'No Detectado';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                {
                  field: 'fecha',
                  headerName: "Fecha",
                  width: 150,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null) {
                      return '--/--/-- --:--:--';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                
              ],
              rows: this.state.copyDataTable.rows
            },
            isLoading: false,
            // valueTextToBeSearch: undefined,
            OptionStateSelected: newValue
          });
        }
        break;
      case "Baja":
        listAccessControlRows = await this.getDataListControlAccess(0);
        // console.log(listAccessControlRows);
        if (listAccessControlRows !== false) {
          // console.log("entra");
          this.setState({
            dataTable: {
              columns: [
                { field: 'id', hide: true },
                { field: 'vehiculo', headerName: "Vehículo", width: 210 },
                { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
                { field: 'tipo', headerName: "Tipo", width: 200 },
                {
                  field: 'temperatura',
                  headerName: "Temperatura",
                  width: 200,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null || params.value === undefined) {
                      // Si es null, devuelve el texto deseado
                      return '--';
                    } else if(params.value === 300){
                      return 'No Detectado';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                {
                  field: 'fecha',
                  headerName: "Fecha",
                  width: 150,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null) {
                      return '--/--/-- --:--:--';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                
              ],
              rows: listAccessControlRows

            },
            copyDataTable: {
              columns: [
                { field: 'id', hide: true },
                { field: 'vehiculo', headerName: "Vehículo", width: 210 },
                { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
                { field: 'tipo', headerName: "Tipo", width: 200 },
                {
                  field: 'temperatura',
                  headerName: "Temperatura",
                  width: 200,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null || params.value === undefined) {
                      // Si es null, devuelve el texto deseado
                      return '--';
                    } else if(params.value === 300){
                      return 'No Detectado';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                {
                  field: 'fecha',
                  headerName: "Fecha",
                  width: 150,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null) {
                      return '--/--/-- --:--:--';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                
              ],
              rows: listAccessControlRows
            },
            isLoading: false,
            // valueTextToBeSearch: undefined,
            OptionStateSelected: newValue
          });
        } else {
          this.setState({
            dataTable: {
              columns: [
                { field: 'id', hide: true },
                { field: 'vehiculo', headerName: "Vehículo", width: 210 },
                { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
                { field: 'tipo', headerName: "Tipo", width: 200 },
                {
                  field: 'temperatura',
                  headerName: "Temperatura",
                  width: 200,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null || params.value === undefined) {
                      // Si es null, devuelve el texto deseado
                      return '--';
                    } else if(params.value === 300){
                      return 'No Detectado';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                {
                  field: 'fecha',
                  headerName: "Fecha",
                  width: 150,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null) {
                      return '--/--/-- --:--:--';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                
              ],
              rows: []

            },
            copyDataTable: {
              columns: [
                { field: 'id', hide: true },
                { field: 'vehiculo', headerName: "Vehículo", width: 210 },
                { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
                { field: 'tipo', headerName: "Tipo", width: 200 },
                {
                  field: 'temperatura',
                  headerName: "Temperatura",
                  width: 200,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null || params.value === undefined) {
                      // Si es null, devuelve el texto deseado
                      return '--';
                    } else if(params.value === 300){
                      return 'No Detectado';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                {
                  field: 'fecha',
                  headerName: "Fecha",
                  width: 150,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null) {
                      return '--/--/-- --:--:--';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                
              ],
              rows: this.state.copyDataTable.rows
            },
            isLoading: false,
            // valueTextToBeSearch: undefined,
            OptionStateSelected: newValue
          });
        }
        break;
      case "Todos":
        listAccessControlRows = await this.getDataListControlAccess(2);
        if (listAccessControlRows !== false) {
          // console.log("entra");
          this.setState({
            dataTable: {
              columns: [
                { field: 'id', hide: true },
                { field: 'vehiculo', headerName: "Vehículo", width: 210 },
                { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
                { field: 'tipo', headerName: "Tipo", width: 200 },
                {
                  field: 'temperatura',
                  headerName: "Temperatura",
                  width: 200,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null || params.value === undefined) {
                      // Si es null, devuelve el texto deseado
                      return '--';
                    } else if(params.value === 300){
                      return 'No Detectado';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                {
                  field: 'fecha',
                  headerName: "Fecha",
                  width: 150,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null) {
                      return '--/--/-- --:--:--';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                
              ],
              rows: listAccessControlRows

            },
            copyDataTable: {
              columns: [
                { field: 'id', hide: true },
                { field: 'vehiculo', headerName: "Vehículo", width: 210 },
                { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
                { field: 'tipo', headerName: "Tipo", width: 200 },
                {
                  field: 'temperatura',
                  headerName: "Temperatura",
                  width: 200,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null || params.value === undefined) {
                      // Si es null, devuelve el texto deseado
                      return '--';
                    } else if(params.value === 300){
                      return 'No Detectado';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                {
                  field: 'fecha',
                  headerName: "Fecha",
                  width: 150,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null) {
                      return '--/--/-- --:--:--';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                
              ],
              rows: listAccessControlRows
            },
            isLoading: false,
            // valueTextToBeSearch: undefined,
            OptionStateSelected: newValue
          });
        } else {
          this.setState({
            dataTable: {
              columns: [
                { field: 'id', hide: true },
                { field: 'vehiculo', headerName: "Vehículo", width: 210 },
                { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
                { field: 'tipo', headerName: "Tipo", width: 200 },
                {
                  field: 'temperatura',
                  headerName: "Temperatura",
                  width: 200,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null || params.value === undefined) {
                      // Si es null, devuelve el texto deseado
                      return '--';
                    } else if(params.value === 300){
                      return 'No Detectado';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                {
                  field: 'fecha',
                  headerName: "Fecha",
                  width: 150,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null) {
                      return '--/--/-- --:--:--';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                
              ],
              rows: []

            },
            copyDataTable: {
              columns: [
                { field: 'id', hide: true },
                { field: 'vehiculo', headerName: "Vehículo", width: 210 },
                { field: 'sonda', headerName: "Sonda / Sensor", width: 200 },
                { field: 'tipo', headerName: "Tipo", width: 200 },
                {
                  field: 'temperatura',
                  headerName: "Temperatura",
                  width: 200,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null || params.value === undefined) {
                      // Si es null, devuelve el texto deseado
                      return '--';
                    } else if(params.value === 300){
                      return 'No Detectado';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
                {
                  field: 'fecha',
                  headerName: "Fecha",
                  width: 150,
                  valueGetter: (params) => {
                    // Verificar si el valor es null
                    if (params.value === null) {
                      return '--/--/-- --:--:--';
                    }
                    // Si no es null, devuelve el valor original
                    return params.value;
                  },
                },
               
              ],
              rows: this.state.copyDataTable.rows
            },
            isLoading: false,
            // valueTextToBeSearch:undefined,
            OptionStateSelected: newValue
          });
        }
        break;
      default:
        break;
    }
  }

  handleClickOpenMenuOptions(e) {
    this.setState({
      anchorEl: e.currentTarget
    })
  }

  handleCloseMenuOptions() {
    this.setState({
      anchorEl: null
    })
  }
  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
  redirectToCreatingGroup() {
    let isInIframe = this.inIframe();
    if (!isInIframe) {
      this.props.history.push({

        pathname: '/dashboard/gestion-grupos-acceso-temporal/',
        state: {
          locale: this.props.location.state.locale,
          userDataInfo: this.props.location.state.userDataInfo,
          ApiURL: this.props.location.state.ApiURL,
        },
        isAuthenticated: this.props.isAuthenticated,
        companyName: this.props.companyName,
        accessToken: this.props.location.state.AccessToken,
      });
    } else {
      this.props.history.push({
        pathname: '/gestion-grupos-acceso-temporal/',

        state: {
          locale: this.props.location.state.locale,
          userDataInfo: this.props.location.state.userDataInfo,
          ApiURL: this.props.location.state.ApiURL,
        },
        isAuthenticated: this.props.isAuthenticated,
        companyName: this.props.companyName,
        accessToken: this.props.location.state.AccessToken,
      });
    }


  }

  render() {
    const { t, classes } = this.props;
    // console.log("render")
    return (
      <div style={{ height: "100%" }}>
        {
          // this.props.isAuthenticated !== true ? this.redirectToLogin() :


          <div className="grid-mainContainer">
            <div className="item5">
              <AlertStatus
                visibleAlert={this.state.visibleAlert}
                changeVisibilityAlertStatus={this.changeVisibilityAlertStatus.bind(this)}
                statusText={this.state.statusObjAlert.statusText}
                statusMessage={this.state.statusObjAlert.statusMessage}
                statusNumPedido={this.state.statusObjAlert.statusNumPedido}
              />
            </div>

            <AppBar className="item1" position="static">
              <Toolbar>
                <Button color="inherit" className="buttonMenu" onClick={this.onClickHandleModalView.bind(this)}>{t('menu.new')}</Button>
                <Button color="inherit" className="buttonMenu" onClick={this.handleOnClickView.bind(this)}>{"Editar"}</Button>
                <Button color="inherit" className="buttonMenu" onClick={this.handleOnClickDelete.bind(this)}>{t('menu.delete')}</Button>
                <StyledMenu
                  id="customized-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleCloseMenuOptions.bind(this)}
                >
                  <StyledMenuItem>
                    {/* <ListItemIcon>
                                                <SendIcon fontSize="small" />
                                            </ListItemIcon> */}
                    <ListItemText onClick={this.redirectToCreatingGroup.bind(this)} primary="Creación de grupos" />
                  </StyledMenuItem>

                </StyledMenu>
                {/* <Button color="inherit" className="buttonMenu">{t('menu.help')}</Button> */}
              </Toolbar>
            </AppBar>
            {/* <h2 className="item2" style={{ textAlign: "center", color: "#bf2349" }}>Accesos Temporales</h2> */}
            <h2 className="item2" style={{ textAlign: "center", color: "#bf2349" }}>Asignación Sondas / Sensores</h2>

            {this.state.isLoading ?
              <BeatLoader
                css={spinnerCss}
                size={15}
                margin={4}
                color={"#bf2349"}
              />
              :
              <div>
                <div className="grid-containerFilters item3">
                  <div className={classes.containerTextField}>
                    <TextField
                      // value={this.state.valueTextToBeSearch !== undefined ? this.state.valueTextToBeSearch[0] : "" }
                      id="outlined-basic"
                      label={t('placeholders.inputSearch')}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <Icon
                              disabled={true}
                              aria-label=""


                            // onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            >
                              <SearchIcon style={{ color: "#858585" }} />
                            </Icon>
                          </InputAdornment>
                      }}
                      onChange={this.handleOnChangeDescription.bind(this)}

                    // onChange={this.handleOnChangeDescription.bind(this)}
                    >

                    </TextField>
                  </div>

                  {/* <div>
                                        <Select

                                            // value={this.state.OptionStateSelected}
                                            onChange={this.handleOnChangeFilterSelectOption.bind(this)}
                                            defaultValue={this.state.groupedOptionsStateList[0].options[0]}
                                            // name="Ver"
                                            placeholder={t('placeholders.view')}
                                            options={this.state.groupedOptionsStateList}
                                            formatGroupLabel={this.formatGroupLabel}
                                            isClearable={true}
                                            isSearchable={true}
                                            hideSelectedOptions={false}


                                        />
                                    </div> */}
                </div>

                <div style={{ height: 600, width: '100%' }} className="item4">
                  <ThemeProvider theme={theme}>
                    <DataGrid
                      // rows={this.state.dataTable.rows} 
                      // columns={this.state.dataTable.columns}

                      onSelectionModelChange={this.handleOnSelection.bind(this)}
                      // autoPageSize={true}
                      autoHeight={true}
                      pageSize={10}
                      checkboxSelection
                      // rowsPerPageOptions={[5, 10, 20]}
                      rows={this.state.dataTable.rows}
                      columns={this.state.dataTable.columns}
                      disableColumnMenu
                      locale="es"
                    // {...this.state.dataTable}
                    // sortModel={[
                    //     {
                    //         field: 'inicio',
                    //         sort: 'asc',
                    //     },
                    // ]}


                    />
                  </ThemeProvider>

                </div>


                {this.state.modalViewShow &&
                  <ModalView
                    accessToken={this.props.location.state !== undefined ? this.props.location.state.AccessToken : this.state.accessToken}
                    t={this.props.t}
                    modalViewShow={this.state.modalViewShow}
                    onClickHandleModalView={this.onClickHandleModalView}
                    onCloseModalView={this.onCloseModalView}
                    selectedObj={this.state.objSelectedToView}
                    handleOnCloseModalAndShowAlert={this.handleOnCloseModalAndShowAlert}
                    listVehiclesGroups={this.state.listVehiclesGroups}
                    assetsTags={this.state.tagsGroups}
                    assetsInfo={this.state.assetsInfo}
                    tagsGroups={this.state.tagsGroups}
                    history={this.props.history}
                    location={this.props.location}
                    groupsNewAccessList={this.state.groupsNewAccessList}
                  />}

                <DialogViewSondas handleOnclickAcceptDialogView={this.handleOnclickAcceptDialogView} onCloseOrOpenDialogView={this.onCloseOrOpenDialogView} dialogViewShow={this.state.dialogViewShow} />
              </div>

            }
          </div>
        }
      </div>
    )
  }
}



// export default withTranslation()(TemporalAccessList);
export default withRouter(compose(
  withTranslation(),
  withStyles(styles, { withTheme: true }),


)(SondasBT))
// export default withRouter(withTranslation()(TemporalAccessList));
