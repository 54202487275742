import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogCANBus(props) {
  const [isOpenSnackBar, setisOpenSnackBar] = React.useState(false);
  const OnclickCopyAndRedirectButton = () => {
    navigator.clipboard.writeText("CGygSsf4Pm");
    setisOpenSnackBar(true);
  }

  const RedirectToTeltonika = () => {
    props.isCloseProps();
    setTimeout(() => {
      window.open("https://drive.teltonika.lt/d/8decd35b5caa4b5cbff3/", "_blank");
    }, 100);
  }

  return (
    <div>
      <Dialog open={props.isOpenProps} onClose={props.isCloseProps} TransitionComponent={Transition}>
        <DialogTitle id="responsive-dialog-title">{"Clave de acceso"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para poder acceder al repositorio de Teltonika, es necesario que copie la siguiente contraseña.
            Puede copiarlo haciendo clic en el icono que se encuentra en la derecha de la contraseña.
            <TextField style={{ marginTop: "20px" }} type="password" variant='outlined' value={"VFCbAyLe5XBNqS4h"}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="End">
                    <IconButton onClick={OnclickCopyAndRedirectButton}>
                      <LinkIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >

            </TextField>
          </DialogContentText>
          <Snackbar
            message="Copiado al portapapeles"
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={3000}
            onClose={() => setisOpenSnackBar(false)}
            open={isOpenSnackBar}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={RedirectToTeltonika} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


export default DialogCANBus
