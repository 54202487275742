import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import { Switch, FormGroup, FormControlLabel } from '@material-ui/core/';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { DataGrid, esES, } from '@material-ui/data-grid';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import IncidenceInfoDialog from './IncidenceInfoDialog';

const styles = theme => ({

    input: {
        'input': {
            '&::placeholder': {
                color: '#808080'
            }
        }
    }
});
const theme = createTheme(
    {
        typography: {
            htmlFontSize: 10
        },
        overrides: {
            MuiCssBaseline: {
                "@global": {
                    html: {
                        fontSize: "62.5%"
                    }
                }
            }
        },
        palette: {
            primary: {
                light: '#bf2349',
                main: '#bf2349',
                dark: '#bf2349',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ff7961',
                main: '#f44336',
                dark: '#ba000d',
                contrastText: '#000',
            },
        },
    },
    esES,
);

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

class DataTableIncidentManagement extends Component {
    state = {
        dataTable: {
            columns: [],
            rows: []
        },
        copyDataTable: {
            columns: [],
            rows: []
        },
        groupedOptionsVehicle: [{
            label: "Vehículos",
            options: []
        }],
        groupedOptionsDrivers: [{
            label: "Conductores",
            options: []
        }],
        groupedOptionsCustomers: [{
            label: "Clientes",
            options: []
        }],
        groupedOptionsStates: [{
            label: "Estados",
            options: []
        }],
        filter: {},
        startDateValue: null,
        endDateValue: null,
        stringStartDateValue: null,
        stringEndDateValue: null,
        isChequed: false,
        isOpenDialogInfo: false,
        rowSelected: [],
        isLoading: true
    }
    componentDidMount() {
        document.getElementById("pin").style.display = "none";
        this.getVehicles();
    }

    async getVehicles() {
        let response = {};
        const settings = {
            method: 'GET',
            // headers: {
            //     'accessToken': this.props.location.state.AccessToken,
            // }
        };

        let responseServices = await fetch("https://interop4gflota.disagrupo.es/acceso4disa/GetVehiculosIncidencias?user=disa&format=JSON&actual=0&key=zqiWc1LgMLrgJCjesFtfRu5rAA8=", settings);
        // let responseServices = await fetch(this.props.location.state.ApiURL + "Services?" + "ServicesDate=" +

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                try {

                    response = await responseServices.json();
                    response = response.Result;
                    await this.getDrivers(response);
                } catch (error) {
                    response = null;
                }
            }
        } else {
            alert("HTTP-Error: " + responseServices.status);
            this.setState({
                isLoading: !this.state.isLoading
            });
        }

    }

    async getDrivers(vehiclesList) {
        let response = {};
        const settings = {
            method: 'GET',
            // headers: {
            //     'accessToken': this.props.location.state.AccessToken,
            // }
        };
        let responseServices = await fetch("https://interop4gflota.disagrupo.es/acceso4disa/GetConductoresIncidencias?user=disa&format=JSON&actual=0&key=DDwESVnu_tVDoa40xSyA0U_M7Rw=", settings);
        // let responseServices = await fetch(this.props.location.state.ApiURL + "Services?" + "ServicesDate=" +

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                try {
                    response = await responseServices.json();
                    response = response.Result;
                    this.getCustomers(vehiclesList, response);
                } catch (error) {
                    response = null;
                }
            }
        } else {
            alert("HTTP-Error: " + responseServices.status);
            this.setState({
                isLoading: !this.state.isLoading
            });
        }

    }

    async getCustomers(vehiclesList, driverList) {
        let response = {};
        const settings = {
            method: 'GET',
            // headers: {
            //     'accessToken': this.props.location.state.AccessToken,
            // }
        };
        let responseServices = await fetch("https://interop4gflota.disagrupo.es/acceso4disa/GetClientesIncidencias?user=disa&format=JSON&actual=0&key=JfzzshHNMyh7iG7JVZkx60ESKPk=", settings);
        // let responseServices = await fetch(this.props.location.state.ApiURL + "Services?" + "ServicesDate=" +

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                try {
                    response = await responseServices.json();
                    response = response.Result;
                    this.loadComboBoxData(vehiclesList, driverList, response);
                } catch (error) {
                    response = null;
                }
            }
        } else {
            alert("HTTP-Error: " + responseServices.status);
            let dataRows = [];
            let dataTable = this.getStructureDataTable(dataRows);
            this.setState({
                isLoading: !this.state.isLoading,
                dataTable: dataTable
            });
        }

    }
    getStructureDataTable = (rows) => {
        return {
            columns: [
                { field: 'foes', headerName: "FOEs", width: 200 },
                { field: 'cliente', headerName: "Cliente", width: 260 },
                { field: 'conductor', headerName: "Conductor", width: 300 },
                { field: 'vehiculo', headerName: "Vehiculo", width: 120 },
                { field: 'fecha', headerName: "Fecha", type: 'dateTime', width: 180 },
                { field: 'estado', headerName: "Estado", width: 140 },
            ],
            rows: rows
        };
    }

    getIncidenceList = async (filter, startDate, endDate) => {
        let filterRequest = {
            vehiculo: filter.hasOwnProperty("vehiculo") ? filter.vehiculo : null,
            foes: filter.hasOwnProperty("foes") ? filter.foes : null,
            cliente: filter.hasOwnProperty("cliente") ? filter.cliente : null,
            conductor: filter.hasOwnProperty("conductor") ? filter.conductor : null,
            estado: filter.hasOwnProperty("estado") ? filter.estado : null
        }
        const settings = {
            method: 'POST',
            headers: {
                'accessToken': this.props.location.state.AccessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(filterRequest),


        };

        let methodUrl = "";

        if (startDate !== null && endDate !== null) {
            methodUrl = "Incidences?fechaInicio=" + startDate + "&fechaFin=" + endDate;
        } else if (startDate !== null) {
            methodUrl = "Incidences?fechaInicio=" + startDate + "&fechaFin=";
        } else if (endDate !== null) {
            methodUrl = "Incidences?fechaInicio=&fechaFin=" + endDate;
        } else {
            methodUrl = "Incidences?fechaInicio=&fechaFin=";
        }



        let responseServices = await fetch(this.props.location.state.ApiURL + methodUrl, settings);
        return await responseServices.json();
    }

    loadComboBoxData = async (vehicleList, driverList, customersList) => {
        let dataRows = [];
        const response = await this.getIncidenceList(this.state.filter, null, null);


        let groupedOptionsVehicle = [];
        let groupedOptionsDrivers = [];
        let groupedOptionsCustomers = [];
        let groupedOptionsStates = [];

        vehicleList.forEach(vehicle => {
            groupedOptionsVehicle.push({ tag: "Vehículos", value: vehicle.Matricula, label: vehicle.Matricula });
        });

        driverList.forEach(driver => {
            groupedOptionsDrivers.push({ tag: "Conductores", value: driver.Codigo, label: driver.Nombre });
        });
        customersList.forEach(customer => {
            groupedOptionsCustomers.push({ tag: "Clientes", value: customer.Codigo, label: customer.Nombre });
        });
        groupedOptionsStates.push({ tag: "Estados", value: 0, label: "Abierta" });
        groupedOptionsStates.push({ tag: "Estados", value: 1, label: "Desestimada" });
        groupedOptionsStates.push({ tag: "Estados", value: 2, label: "Enviado CRM" });
        response.forEach(element => {
            dataRows.push(
                {
                    id: element.idIncidencia,
                    idIncidencia: element.idIncidencia,
                    numPedido: element.numPedido,
                    codCliente: element.codCliente,
                    codConductor: element.codConductor,
                    vehiculo: element.codCabezaTractora,
                    codSemiremolque: element.codSemiremolque,
                    fecha: this.formatDate(element.fecha),
                    razon: element.tipoIncidenciaConductor,
                    observacion: element.descripcionConductor,
                    tipoCRM: element.tipoCRM,
                    subTipoCRM: element.subTipoCRM,
                    estado: element.estado === 0 ? "Abierta" : element.estado === 1 ? "Desestimada" : element.estado === 2 ? "Enviado CRM" : element.estado,
                    cliente: element.nombreCliente,
                    vehiculo: element.matriculaVehiculo,
                    conductor: element.nombreConductor,
                    foes: element.numPedido,
                    imagesIds: element.imagenesIds
                }
            );
        });

        if (dataRows.length > 0) {
            if (dataRows[0].codCliente === null) {
                dataRows = null;
            }
        }


        let dataTable = this.getStructureDataTable(dataRows);

        this.setState({
            dataTable: dataTable,
            copyDataTable: dataTable,
            groupedOptionsVehicle: [{ label: this.state.groupedOptionsVehicle.label, options: groupedOptionsVehicle }],
            groupedOptionsDrivers: [{ label: this.state.groupedOptionsVehicle.label, options: groupedOptionsDrivers }],
            groupedOptionsCustomers: [{ label: this.state.groupedOptionsVehicle.label, options: groupedOptionsCustomers }],
            groupedOptionsStates: [{ label: this.state.groupedOptionsVehicle.label, options: groupedOptionsStates }],
            isLoading: false
        });
    }

    filterData = (filters) => {
        const newRows = this.state.copyDataTable.rows.filter(function (item) {
            for (var key in filters) {
                if (item[key] === undefined || item[key].includes(filters[key])) {
                    return false;
                }
            }
            return true;
        });
        return newRows;
    }

    converDate(dateParameter) {
        let dateAndHourArray = dateParameter.split(" ");
        let date = dateAndHourArray[0].split("/");
        if (date[2].length <= 2) {
            date[2] = "20" + date[2];
        }

        let dateString = date[2] + "/" + date[1] + "/" + date[0] + " " + dateAndHourArray[1];

        return new Date(dateString);
    }


    handleOnSelection = (e) => {
        // let rowSelected = this.state.dataTable.rows.filter(element => element.id === parseInt(e.selectionModel[0]));
        // console.log(rowSelected);
        if (e.row.estado === "Desestimada") {
            e.row.subTipoCRM = null;
            e.row.tipoCRM = null;
        }
        this.setState({
            rowSelected: e.row,
            isOpenDialogInfo: true
        });
    }
    // applyFilter = (data, filter) => data.filter(obj =>
    //     Object.entries(filter).every(([prop, find]) => find.includes(obj[prop]))
    // );
    applyFilter = (data, filter) => {
        let newRows = data.filter(obj =>
            Object.entries(filter).every(([prop, find]) => find.includes(obj[prop]))
        );
        if (this.state.startDateValue !== null && this.state.endDateValue !== null) {
            let startDate = Date.parse(this.state.startDateValue.toString());
            let endDate = Date.parse(this.state.endDateValue.toString());

            newRows = newRows.filter((item) => this.converDate(item.fecha).getTime() >= startDate && this.converDate(item.fecha).getTime() <= endDate);
        }
        return newRows;
    }


    handleOnChangeVehicle = (e) => {
        let copyFilter = { ...this.state.filter };
        let newRows = [];

        if (e !== null) {
            if (!copyFilter.hasOwnProperty("vehiculo")) {
                copyFilter.vehiculo = [];
            }
            let filter = [];
            e.forEach(element => {
                filter.push(element.label);
            });
            copyFilter.vehiculo = filter;

            if (copyFilter.vehiculo.length === 0) {
                delete copyFilter.vehiculo;
            }
            // newRows = this.applyFilter(this.state.copyDataTable.rows, copyFilter);
        } else {
            delete copyFilter.vehiculo;
            // newRows = this.applyFilter(this.state.copyDataTable.rows, copyFilter);
        }
        // let dataTable = this.getStructureDataTable(newRows);
        this.setState({
            filter: copyFilter,
            // dataTable: dataTable
        }
            // , async () => {
            //     const response = await this.getIncidenceList(copyFilter);
            //     const dataRows = this.setDataToDataGrid(response);


            //     let dataTable = this.getStructureDataTable(dataRows);

            //     this.setState({
            //         dataTable: dataTable,
            //         copyDataTable: dataTable,
            //     });
            // }
        );
    }
    handleOnChangeDrivers = (e) => {
        let copyFilter = { ...this.state.filter };
        let newRows = [];

        if (e !== null) {
            if (!copyFilter.hasOwnProperty("conductor")) {
                copyFilter.conductor = [];
            }
            let filter = [];
            e.forEach(element => {
                filter.push(element.value);
            });
            copyFilter.conductor = filter;

            if (copyFilter.conductor.length === 0) {
                delete copyFilter.conductor;
            }
            // newRows = this.applyFilter(this.state.copyDataTable.rows, copyFilter);
        } else {
            delete copyFilter.conductor;
            // newRows = this.applyFilter(this.state.copyDataTable.rows, copyFilter);
        }

        // let dataTable = this.getStructureDataTable(newRows);
        this.setState({
            filter: copyFilter,
            // dataTable: dataTable
        }
            // , async () => {
            //     const response = await this.getIncidenceList(copyFilter);
            //     const dataRows = this.setDataToDataGrid(response);


            //     let dataTable = this.getStructureDataTable(dataRows);

            //     this.setState({
            //         dataTable: dataTable,
            //         copyDataTable: dataTable,
            //     });
            // }
        );
    }

    handleOnChangeCustomers = (e) => {
        let copyFilter = { ...this.state.filter };
        let newRows = [];

        if (e !== null) {
            if (!copyFilter.hasOwnProperty("cliente")) {
                copyFilter.cliente = [];
            }
            let filter = [];
            e.forEach(element => {
                filter.push(element.value);
            });
            copyFilter.cliente = filter;

            if (copyFilter.cliente.length === 0) {
                delete copyFilter.cliente;
            }
            // newRows = this.applyFilter(this.state.copyDataTable.rows, copyFilter);

        } else {
            delete copyFilter.cliente;
            // newRows = this.applyFilter(this.state.copyDataTable.rows, copyFilter);
        }

        // let dataTable = this.getStructureDataTable(newRows);
        this.setState({
            filter: copyFilter,
            // dataTable: dataTable
        }
            // , async () => {
            //     const response = await this.getIncidenceList(copyFilter);
            //     const dataRows = this.setDataToDataGrid(response);


            //     let dataTable = this.getStructureDataTable(dataRows);

            //     this.setState({
            //         dataTable: dataTable,
            //         copyDataTable: dataTable,
            //     });
            // }
        );
    }
    handleOnChangeStates = (e) => {
        let copyFilter = { ...this.state.filter };
        let newRows = [];

        if (e !== null) {
            if (!copyFilter.hasOwnProperty("estado")) {
                copyFilter.estado = [];
            }
            let filter = [];
            e.forEach(element => {
                filter.push(element.value);
            });
            copyFilter.estado = filter;

            if (copyFilter.estado.length === 0) {
                delete copyFilter.estado;
            }
            // newRows = this.applyFilter(this.state.copyDataTable.rows, copyFilter);
        } else {
            delete copyFilter.estado;
            // newRows = this.applyFilter(this.state.copyDataTable.rows, copyFilter);
        }

        // let dataTable = this.getStructureDataTable(newRows);
        this.setState({
            filter: copyFilter,
            // dataTable: dataTable
        }
            // , async () => {
            //     const response = await this.getIncidenceList(copyFilter);
            //     const dataRows = this.setDataToDataGrid(response);


            //     let dataTable = this.getStructureDataTable(dataRows);

            //     this.setState({
            //         dataTable: dataTable,
            //         copyDataTable: dataTable,
            //     });
            // }
        );
    }
    handleOnChangeFoes = (e) => {
        let copyFilter = { ...this.state.filter };
        let newRows = [];
        if (e.target.value !== "") {
            if (!copyFilter.hasOwnProperty("foes")) {
                copyFilter.foes = [];
            }
            let filter = [];
            filter.push(e.target.value);
            copyFilter.foes = e.target.value;

            if (copyFilter.foes.length === 0) {
                delete copyFilter.foes;
            }
            // newRows = this.applyFilter(this.state.copyDataTable.rows, copyFilter);
        } else {
            delete copyFilter.foes;
            // newRows = this.applyFilter(this.state.copyDataTable.rows, copyFilter);
        }

        // let dataTable = this.getStructureDataTable(newRows);
        this.setState({
            filter: copyFilter,
            // dataTable: dataTable
        });
    }

    setDataToDataGrid = (response) => {
        let dataRows = [];
        response.forEach(element => {
            dataRows.push(
                {
                    id: element.idIncidencia,
                    idIncidencia: element.idIncidencia,
                    numPedido: element.numPedido,
                    codCliente: element.codCliente,
                    codConductor: element.codConductor,
                    vehiculo: element.codCabezaTractora,
                    codSemiremolque: element.codSemiremolque,
                    fecha: this.formatDate(element.fecha),
                    razon: element.tipoIncidenciaConductor,
                    observacion: element.descripcionConductor,
                    tipoCRM: element.tipoCRM,
                    subTipoCRM: element.subTipoCRM,
                    estado: element.estado === 0 ? "Abierta" : element.estado === 1 ? "Desestimada" : element.estado === 2 ? "Enviado CRM" : element.estado,
                    cliente: element.nombreCliente,
                    vehiculo: element.matriculaVehiculo,
                    conductor: element.nombreConductor,
                    foes: element.numPedido,
                    imagesIds: element.imagenesIds
                }
            );
        });
        return dataRows;
    }

    handleDateEnd = (e) => {
        let copyFilter = { ...this.state.filter };
        let dateForApi = this.DateTimeToTicks(e);
        let stringDate = this.formatDate(e);

        this.setState({
            endDateValue: e,
            stringEndDateValue: stringDate
        }
            // , () => {
            //     if (this.state.endDateValue !== null && this.state.startDateValue !== null) {
            //         let newRows = this.applyFilter(this.state.copyDataTable.rows, copyFilter);
            //         let dataTable = this.getStructureDataTable(newRows);
            //         this.setState({
            //             dataTable: dataTable
            //         });
            //     } else {
            //         let newRows = this.applyFilter(this.state.copyDataTable.rows, copyFilter);
            //         let dataTable = this.getStructureDataTable(newRows);
            //         this.setState({
            //             dataTable: dataTable
            //         });
            //     }
            // }
        );
    }
    handleDateStart = async (e) => {
        let copyFilter = { ...this.state.filter };
        let stringDate = this.formatDate(e);
        // const response = await this.getIncidenceList(copyFilter, this.DateTimeToTicks(e));
        // const dataRows = this.setDataToDataGrid(response);

        // let dataTable = this.getStructureDataTable(dataRows);
        this.setState({
            startDateValue: e,
            stringStartDateValue: stringDate,
            // dataTable: dataTable,
            // copyDataTable: dataTable,
        });
    }

    closeVisibilityDialog = () => {
        this.setState({
            isLoading: true
        }, async () => {
            const response = await this.getIncidenceList(this.state.filter, null, null);
            const dataRows = this.setDataToDataGrid(response);


            let dataTable = this.getStructureDataTable(dataRows);

            this.setState({
                dataTable: dataTable,
                copyDataTable: dataTable,
                isOpenDialogInfo: false,
                isLoading: false
            });
        })
    }

    handleOnCloseDialog = () => {
        this.setState({
            isOpenDialogInfo: false
        })
    }

    formatDate(date) {
        let time = new Date(date);
        let day = (time.getDate() < 10 ? "0" + time.getDate() : time.getDate());
        let month = ((time.getMonth() + 1) < 10 ? "0" + (time.getMonth() + 1) : (time.getMonth() + 1));
        let hour = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours());
        let minutes = (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
        let seconds = (time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds());

        return day + "/" + month + "/" + time.getFullYear() + " " + hour + ":" + minutes + ":" + seconds;
    }
    DateTimeToTicks(date) {
        var ticks = ((date * 10000) + 621355968000000000);
        return ticks;
    }

    applyFilterApi = async () => {
        let starDate = null;
        let endDate = null;

        if (this.state.startDateValue !== null) {
            starDate = this.DateTimeToTicks(this.state.startDateValue)
        }

        if (this.state.endDateValue !== null) {
            endDate = this.DateTimeToTicks(this.state.endDateValue)
        }

        const response = await this.getIncidenceList(this.state.filter, starDate, endDate);
        const dataRows = this.setDataToDataGrid(response);
        let dataTable = this.getStructureDataTable(dataRows);
        this.setState({
            isLoading: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    dataTable: dataTable,
                    copyDataTable: dataTable,
                    isLoading: false
                });
            }, 600);

        });
    }

    render() {
        const { t, classes } = this.props;
        return (
            <ThemeProvider theme={theme} style={{ height: "100%" }}>
                <Container maxWidth="lg" style={{ marginTop: "20px" }}>
                    <h2 style={{ textAlign: "center", color: "#bf2349" }}>Gestión de Incidencias</h2>
                    <FormGroup aria-label="position" style={{ width: "3%" }} row>
                        <FormControlLabel
                            value="start"

                            control={<Switch checked={this.state.isChequed} color='primary' onClick={() => this.setState({ isChequed: !this.state.isChequed })} />}
                            label="Filtros"
                            labelPlacement="start"
                            style={{ marginLeft: 0, marginRight: 0, }}
                        />
                    </FormGroup>
                    <div style={{ display: this.state.isChequed ? "block" : "none" }}>
                        <Grid container style={{ flexGrow: 1, marginTop: "10px", marginBottom: "50px" }} spacing={2}>
                            <Grid item xs={12} md={6} lg={3}>
                                <Select
                                    // value={this.state.OptionStateSelected}
                                    onChange={this.handleOnChangeVehicle.bind(this)}
                                    isMulti
                                    placeholder={"Vehiculos"}
                                    options={this.state.groupedOptionsVehicle}
                                    formatGroupLabel={this.formatGroupLabel}
                                    isClearable={true}
                                    isSearchable={true}
                                    hideSelectedOptions={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#BF2349',
                                        },
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Select
                                    // value={this.state.OptionStateSelected}
                                    onChange={this.handleOnChangeDrivers.bind(this)}
                                    isMulti
                                    placeholder={"Conductores"}
                                    options={this.state.groupedOptionsDrivers}
                                    formatGroupLabel={this.formatGroupLabel}
                                    isClearable={true}
                                    isSearchable={true}
                                    hideSelectedOptions={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#BF2349',
                                        },
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Select
                                    // value={this.state.OptionStateSelected}
                                    onChange={this.handleOnChangeCustomers.bind(this)}
                                    isMulti
                                    placeholder={"Clientes"}
                                    options={this.state.groupedOptionsCustomers}
                                    formatGroupLabel={this.formatGroupLabel}
                                    isClearable={true}
                                    isSearchable={true}
                                    hideSelectedOptions={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#BF2349',
                                        },
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Select

                                    // value={this.state.OptionStateSelected}
                                    onChange={this.handleOnChangeStates.bind(this)}
                                    isMulti
                                    placeholder={"Estados"}
                                    options={this.state.groupedOptionsStates}
                                    formatGroupLabel={this.formatGroupLabel}
                                    isClearable={this.state.groupedOptionsStates[0].options.some((v) => v.label !== "Abierta")}
                                    isSearchable={true}
                                    hideSelectedOptions={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#BF2349',
                                        },
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <DatePicker
                                    className="inputDateManagementIncidence"
                                    selected={this.state.startDateValue}
                                    onChange={this.handleDateStart.bind(this)}
                                    showTimeSelect
                                    // filterTime={filterPassedTime}
                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                    isClearable
                                    // minDate={new Date()}
                                    timeFormat="HH:mm:ss"
                                    style={{ padding: "6px", height: "40px" }}
                                    placeholderText='Desde'
                                // disabled={this.props.selectedObj.length > 0 ? true : false}
                                />

                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <DatePicker
                                    className="inputDateManagementIncidence"
                                    selected={this.state.endDateValue}
                                    onChange={this.handleDateEnd.bind(this)}
                                    showTimeSelect
                                    placeholderText='Hasta'
                                    isClearable
                                    // filterTime={filterPassedTime}
                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                    // minDate={new Date()}
                                    timeFormat="HH:mm:ss"
                                    style={{ padding: "5px", height: "40px" }}
                                // disabled={this.props.selectedObj.length > 0 ? true : false}
                                />

                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField id="outlined-basic"
                                    placeholder="Foes"
                                    variant="outlined"
                                    size='small'
                                    style={{ width: "100%" }}
                                    InputProps={{ style: { width: "100%" }, classes: { input: this.props.classes.input } }}
                                    onChange={this.handleOnChangeFoes.bind(this)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    style={{ padding: "7px 15px" }}
                                    fullWidth={true}
                                    onClick={this.applyFilterApi}
                                >
                                    Aplicar
                                </Button>
                            </Grid>

                        </Grid>
                    </div>



                    <div style={{ height: 620, width: '100%' }} className="item4" onKeyDownCapture={(e) => {
                        if (e.key === "Backspace" || e.key === "Delete") {
                            e.stopPropagation();
                        }
                    }}>
                        <DataGrid

                            rowHeight={50}
                            editMode={false}
                            onRowClick={this.handleOnSelection}
                            pageSize={10}
                            rows={this.state.dataTable.rows}
                            columns={this.state.dataTable.columns}
                            disableColumnMenu
                            locale="es"
                        />
                    </div>

                </Container>
                {this.state.isOpenDialogInfo &&
                    <IncidenceInfoDialog
                        closeVisibilityDialog={this.closeVisibilityDialog}
                        handleOnCloseDialog={this.handleOnCloseDialog}
                        open={this.state.isOpenDialogInfo}
                        rowSelected={this.state.rowSelected}
                        AccessToken={this.props.location.state.AccessToken}
                        ApiURL={this.props.location.state.ApiURL}
                    />}
                <Backdrop style={{
                    zIndex: 1000,
                    color: '#fff'
                }} open={this.state.isLoading}>
                    <CircularProgress color="primary" />
                </Backdrop>
            </ThemeProvider>
        )

    }


}
export default withRouter(compose(
    withTranslation(),
    withStyles({ styles, withTheme: true }),


)(DataTableIncidentManagement))
