import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link, glide } from "react-tiger-transition";
import Grid from "@material-ui/core/Grid";
import { DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { green, red } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DialogView from './Dialog';

import Config from '../../config';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';


glide({
    name: 'glide-left'
});
glide({
    name: 'glide-right',
    direction: 'right'
});

const theme = createTheme(
    {
        typography: {
            htmlFontSize: 10
        },
        overrides: {
            MuiCssBaseline: {
                "@global": {
                    html: {
                        fontSize: "62.5%"
                    }
                }
            }
        }
    }
);

const styles = theme => ({
    root: {
        fontSize: 14,
        zIndex: 500,
        '& > * + *': {
            fontSize: 14,
        },
        '& .MuiInputBase-root': {
            fontSize: "1.6rem !important"
        },
        '& .MuiMenuItem-root': {
            fontSize: "14px !important"
        }
    },
    toolbarAdd: {
        color: green[500],
        // backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[100],
        },
        fontSize: 14,
        borderColor: green[500]
        // background: "#54bf71",
    },
    toolbarDelete: {
        color: red[500],
        // backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[100],
        },
        fontSize: 14,
        marginLeft: 10,
        borderColor: red[500]
        // background: "#54bf71",
    },
    gridContainer: {
        '& .MuiGrid-root': {
            padding: "3px !important",
            background: "unset !important",
            width: "100% !important"
        }
    },
    snackBar: {

        '& .MuiAlert-message': {
            fontSize: 14
        }
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class DeviceTypeConfiguration extends Component {

    state = {
        rows: [],
        columns: [
            { field: 'tipoDispositivo', headerName: 'Tipo Dispositivo', width: 180, },
            { field: 'Nombre', headerName: 'Nombre', width: 130, flex: 1 },

            {
                field: 'acciones', headerName: 'Acciones', width: 300, sortable: false, filterable: false,
                renderCell: (GridCellParams) => {
                    return (

                        <div>
                            <strong>

                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    startIcon={<DeleteOutlineOutlinedIcon />}
                                    style={{ width: 100 }}
                                    onClick={this.onOpenDialogView.bind(this, GridCellParams)}
                                >
                                    Eliminar
                                </Button>
                            </strong>
                            <strong >
                                <Link to="/dashboard/añadir-configuracion-dispositivos/" transition='glide-left'>
                                    <Button

                                        variant="outlined"
                                        color="primary"
                                        startIcon={<EditOutlinedIcon />}
                                        style={{ marginLeft: 15, width: 100 }}
                                        onClick={this.edit.bind(this, GridCellParams)}
                                    >
                                        Editar
                                    </Button>
                                </Link>
                            </strong>
                            <Divider />
                        </div>

                    )
                }
            },
        ],
        dialogViewShow: false,
        selectedToDelete: {},
        textForDialog: "",
        isLoading: true,
        openSnackBarSuccess: true
    }

    componentDidMount() {
        setTimeout(() => {
            this.getListParameters();
        }, 1000);

        if (this.props.typesDevicesConfiguration !== undefined) {
            if (this.props.typesDevicesConfiguration.length <= 0) {
                this.getDevicesTypes();
            }
        }


    }

    edit(e, a) {
        this.props.getDataTopassConfigurationDevices(e.row, "edit");
    }

    delete = (e) => {
        let copyRows = [...this.state.rows];

        let newRows = copyRows.filter(item => item.id !== this.state.selectedToDelete.id);
        this.setState({
            rows: newRows
        });
    }



    onOpenDialogView = (GridCellParams) => {
        // console.log(e);
        this.setState({
            dialogViewShow: true,
            selectedToDelete: GridCellParams.row,
            textForDialog: "¿Desea eliminar la configuración?"
        });
    }

    onCloseDialogView = () => {
        this.setState({
            dialogViewShow: false,

        });
    }

    handleOnclickAcceptDialogView = async () => {
        let copyRows = [...this.state.rows];
        let newRows = copyRows.filter(item => item.id !== this.state.selectedToDelete.id);

        let request = {
            DeviceTypeId: this.state.selectedToDelete.deviceTypeId,
            FirmwareVersion: this.state.selectedToDelete.Nombre,
        }

        const settings = {
            method: 'DELETE',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)

        };
        const fetchResponse = await fetch(`${Config.apiGateway.URL}Devices/Parameters`, settings);
        if (fetchResponse.ok) {
            this.props.getDataTopassConfigurationDevices("", "delete");
        }


        this.setState({
            dialogViewShow: false
        }, () => {
            this.setState({
                rows: newRows,
                selectedToDelete: {},

            });
        });

    }

    getDevicesTypes = async () => {
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };
        const fetchResponse = await fetch(`${Config.apiGateway.URL}devices/types`, settings);
        if (fetchResponse.ok) {
            response = await fetchResponse.json();
            this.getParametersTypes(response);
        }
    }

    getParametersTypes = async (typesDevices) => {
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };
        const fetchResponse = await fetch(`${Config.apiGateway.URL}devices/parameters/info`, settings);
        if (fetchResponse.ok) {
            response = await fetchResponse.json();
            this.props.getDataTypeDevicesTopassConfiguration(typesDevices, response);
        }


    }

    async getListParameters() {
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };
        const fetchResponse = await fetch(`${Config.apiGateway.URL}devices/parameters`, settings);



        if (fetchResponse.ok) {
            response = await fetchResponse.json();

            // response.forEach ()) => {
            //     element.push({id: })
            // });
            // id: 1, tipoDispositivo: "dt1", Nombre: 'test',
            let newArray = [];

            response.forEach(async (element, index) => {

                if (element.parametersInfo !== "") {
                    element.parametersInfo = JSON.parse(element.parametersInfo);
                }
                // 
                // jsonparametersInfo.replace("\"", '');


                newArray.push({
                    "id": index,
                    deviceTypeId: element.deviceTypeId,
                    tipoDispositivo: element.deviceTypeName,
                    Nombre: element.firmwareVersion,
                    listParameters: element.parametersInfo

                });
            });

            this.setState({
                rows: newArray,
                isLoading: false
            });
        }

    }

    CustomToolbar() {
        const { classes } = this.props;
        return (
            // <GridToolbarContainer className={classes.gridContainer}>

            // </GridToolbarContainer>
            <Grid item xs={12}>
                <Link to="/dashboard/añadir-configuracion-dispositivos/" transition='glide-left'>

                    <Button
                        variant="outlined"
                        className={classes.toolbarAdd}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                    // onClick={this.testOnclick.bind(this)}
                    >
                        Añadir
                    </Button>
                </Link>


                {/* <Button
                        variant="outlined"
                        className={classes.toolbarDelete}
                        startIcon={<DeleteOutlineOutlinedIcon />}

                    >
                        Eliminar
            </Button> */}
            </Grid>

        );
    }

    CustomLoadingOverlay() {
        return (
            // <GridOverlay>

            // </GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress color={"secondary"} />
            </div>
        );
    }

    handleCloseSnackbarSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.handleCloseSnackbarSuccess();

        // this.setState({
        //     openSnackBarSuccess: false
        // });
    };

    render() {
        const { classes } = this.props;
        return (
            <div
                style={{ height: "calc(100% - 100px)", width: '80%', margin: "auto", marginTop: 30 }} >
                <ThemeProvider theme={theme}>
                    <DataGrid
                        className={classes.root}
                        rows={this.state.rows}
                        columns={this.state.columns.map((column) => ({
                            ...column,
                            disableClickEventBubbling: true,
                        }))}
                        autoHeight={true}
                        checkboxSelection={false}
                        pageSize={10}
                        // disableColumnMenu
                        components={{
                            Toolbar: this.CustomToolbar.bind(this),
                            LoadingOverlay: this.CustomLoadingOverlay.bind(this),
                            // Toolbar: GridToolbar,
                        }}
                        loading={this.state.isLoading}

                    />
                </ThemeProvider>

                <div className={classes.snackBar}>
                    <Snackbar open={this.props.isOpenSnackbarSuccess} autoHideDuration={4000} onClose={this.handleCloseSnackbarSuccess.bind(this)}>
                        <Alert onClose={this.handleCloseSnackbarSuccess.bind(this)} severity="success">
                            {this.props.textSnackBarSuccess}
                        </Alert>
                    </Snackbar>
                </div>


                <DialogView handleOnclickAcceptDialogView={this.handleOnclickAcceptDialogView} textForDialog={this.state.textForDialog} onOpenDialogView={this.onOpenDialogView} onCloseDialogView={this.onCloseDialogView} dialogViewShow={this.state.dialogViewShow} />
            </div>
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(DeviceTypeConfiguration));
// export default withStyles(styles, {withTheme: true })(DeviceTypeConfiguration);
