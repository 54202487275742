import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { InputLabel } from '@material-ui/core';
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import Select from 'react-select';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Types } from './TypesAndSubTypes';

// const theme = createMuiTheme({
//     palette: {
//         secondary: {
//             main: '#bf2349',
//         },
//     },
// });

const styles = theme => ({

    appBar: {
        position: 'relative',
        background: "#bf2349"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    input: {
        'input': {
            '&::placeholder': {
                color: '#808080'
            }
        }
    },
    button: {

        '&:hover': {
            backgroundColor: '#8a1c36',
            borderColor: '#8a1c36',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#8a1c36',
            borderColor: '#8a1c36',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },
});

const options = {
    url: "https://v4.mui.com/static/images/image-list/camera.jpg",
    dest: "test"              // will be saved to /path/to/dest/image.jpg
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class IncidenceInfoDialog extends Component {

    state = {
        open: null,
        groupOptionsTypes: [{
            label: "Tipos",
            options: []
        }],
        groupOptionsSubType: [{
            label: "Subtipos",
            options: []
        }],
        OptionSubTypeIncidenceSelected: null,
        OptionTypeIncidenceSelected: null,
        imagesUrls: [],
        isLoading: true,
        openAlert: false,
        openAlertConfirm: false,
        messageConfirm: "",
        typeOfSend: -1

    }

    componentDidMount() {
        let groupOptionsTypes = [];

        Types.forEach(element => {
            groupOptionsTypes.push({ tag: "Tipos", value: element.Type, label: element.Type });
        });
        const loadData = async () => {
            const images = await this.getImages();
            this.setState({
                groupOptionsTypes: groupOptionsTypes,
                imagesUrls: images,
                isLoading: false,

            })

        };
        loadData();
    }

    handleClose = () => {
        this.setState({
            open: false,
            messageConfirm: "",
            openAlertConfirm: !this.state.openAlertConfirm,
        }, () => {
            setTimeout(() => {
                this.props.closeVisibilityDialog();
            }, 300);
        })
    }

    toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))

    handleOnClickdownloadImage = (e) => {
        const link = document.createElement("a"); //Create <a>
        link.href = e.target.src;
        link.download = "Image.png"; //File name Here
        link.click(); //Downloaded file
    }
    handleOnChangeTypes = (e) => {
        let groupOptionsSubType = [];
        let subTypes = [];
        let subTypesTransport = Types[0].SubTypes;

        if (e !== null) {
            if (e.label === "Transportes") {
                subTypes = subTypesTransport;
            }
        } else {
            groupOptionsSubType = [{
                label: "Subtipos",
                options: []
            }];
            this.setState({
                OptionTypeIncidenceSelected: null,
                groupOptionsSubType: groupOptionsSubType
            });
            return;
        }



        subTypes.forEach(element => {
            groupOptionsSubType.push({ tag: "Subtipos", value: element.ExternalCategory, label: element.SubType });
        });

        this.setState({
            groupOptionsSubType: groupOptionsSubType,
            OptionTypeIncidenceSelected: e,
            OptionSubTypeIncidenceSelected: null
        })
    }

    handleOnChangeSubTypes = (e) => {
        this.setState({
            OptionSubTypeIncidenceSelected: e
        })
    }

    updateIncidence = async (estado) => {



        if (this.state.OptionSubTypeIncidenceSelected === null && this.state.OptionTypeIncidenceSelected === null && estado === 2) {
            this.setState({
                openAlert: true,
                messageConfirm: "",
                openAlertConfirm: !this.state.openAlertConfirm,
            });
            return;
        } else {
            this.handleClose();
            let request = {
                "IdIncidencia": this.props.rowSelected.idIncidencia,
                "TipoCRM": this.state.OptionTypeIncidenceSelected !== null ? this.state.OptionTypeIncidenceSelected.value : null,
                "SubTipoCRM": this.state.OptionSubTypeIncidenceSelected !== null ? this.state.OptionSubTypeIncidenceSelected.value : null,
                "Estado": estado
            }
            const settings = {
                method: 'POST',
                headers: {
                    'accessToken': this.props.AccessToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request),


            };
            let responseServices = await fetch(this.props.ApiURL + "Incidences/Update", settings);
            const response = await responseServices.json();

        }

    }

    handleOnCancel = () => {
        this.setState({
            open: false
        }, () => {
            setTimeout(() => {
                this.props.handleOnCloseDialog();
            }, 200);
        })
    }

    getImages = async () => {
        // let guids = ["af6ddace-81e9-43de-805a-69a6e7798844"];
        let guids = this.props.rowSelected.imagesIds;
        const settings = {
            method: 'POST',
            headers: {
                'accessToken': this.props.AccessToken,
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify(this.props.rowSelected.ImagesIds),
            body: JSON.stringify(guids),


        };

        let methodUrl = "Incidences/Images";
        let responseServices = await fetch(this.props.ApiURL + methodUrl, settings);
        const responseReturn = await responseServices.json();
        return responseReturn;
    }

    handleOnCloseAlert = () => {
        this.setState({
            openAlert: false
        })
    }

    showConfirmDialog = (estado) => {

        let messageConfirm = "";
        if (estado === 1) {
            messageConfirm = "¿Confirma que desea desestimar?";
        } else {
            messageConfirm = "¿Confirma que desea enviar al CRM?";
        }

        this.setState({
            messageConfirm: messageConfirm,
            openAlertConfirm: !this.state.openAlertConfirm,
            typeOfSend: estado
        });
    }

    handleButtonConfirmDialog = (valor) => {
        if (valor === 1) {
            this.updateIncidence(this.state.typeOfSend);
        } else {
            this.setState({
                messageConfirm: "",
                openAlertConfirm: !this.state.openAlertConfirm,
            })
        }
    }
    render() {
        const { t, classes } = this.props;
        return (
            <div>
                <Dialog fullScreen open={this.state.open !== null ? this.state.open : this.props.open} onClose={this.handleOnCancel} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleOnCancel} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            {/* <Typography variant="h6" className={classes.title}>
                                Sound
                            </Typography>
                            <Button autoFocus color="inherit" onClick={this.handleClose}>
                                save
                            </Button> */}
                        </Toolbar>
                    </AppBar>
                    <Container maxWidth="md" style={{ marginTop: "20px" }}>
                        <h2 style={{ textAlign: "center", color: "#bf2349" }}>Información Incidencia</h2>
                        <Grid container style={{ flexGrow: 1, marginTop: "10px", marginBottom: "10px" }} spacing={2} >
                            <Grid item xs={12} md={4} lg={4}>
                                <InputLabel shrink>
                                    FOEs
                                </InputLabel>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    style={{ width: "100%" }}
                                    disabled={true}
                                    value={this.props.rowSelected.foes}
                                // onChange={this.handleOnChangeFoes.bind(this)}
                                // InputProps={{ className: { input: this.props.classes.input } }}

                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <InputLabel shrink>
                                    Cliente
                                </InputLabel>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    style={{ width: "100%" }}
                                    disabled={true}
                                    value={this.props.rowSelected.cliente}
                                // onChange={this.handleOnChangeFoes.bind(this)}
                                // InputProps={{ className: { input: this.props.classes.input } }}

                                />
                            </Grid>
                            {/* <Grid item xs={12} md={3} lg={3}>

                            </Grid> */}
                            <Grid item xs={12} md={4} lg={4}>
                                <InputLabel shrink>
                                    Fecha
                                </InputLabel>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    style={{ width: "100%" }}
                                    disabled={true}
                                    value={this.props.rowSelected.fecha}
                                // onChange={this.handleOnChangeFoes.bind(this)}
                                // InputProps={{ className: { input: this.props.classes.input } }}

                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <InputLabel shrink>
                                    Vehículo
                                </InputLabel>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    style={{ width: "100%" }}
                                    disabled={true}
                                    value={this.props.rowSelected.vehiculo}
                                // onChange={this.handleOnChangeFoes.bind(this)}
                                // InputProps={{ className: { input: this.props.classes.input } }}

                                />
                            </Grid>
                            <Grid item xs={12} md={8} lg={8}>
                                <InputLabel shrink>
                                    Conductor
                                </InputLabel>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    style={{ width: "100%" }}
                                    disabled={true}
                                    value={this.props.rowSelected.conductor}
                                // onChange={this.handleOnChangeFoes.bind(this)}
                                // InputProps={{ className: { input: this.props.classes.input } }}

                                />
                            </Grid>

                            {/* <Grid item xs={12} md={3} lg={6}>

                            </Grid> */}
                            <Grid item xs={12} md={4} lg={4}>
                                <InputLabel shrink>
                                    Razón
                                </InputLabel>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    style={{ width: "100%" }}
                                    disabled={true}
                                    value={this.props.rowSelected.razon}
                                // onChange={this.handleOnChangeFoes.bind(this)}
                                // InputProps={{ className: { input: this.props.classes.input } }}

                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <InputLabel shrink>
                                    Observación
                                </InputLabel>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    multiline
                                    style={{ width: "100%" }}
                                    disabled={true}
                                    value={this.props.rowSelected.observacion}
                                    rows={4}
                                // onChange={this.handleOnChangeFoes.bind(this)}
                                // InputProps={{ className: { input: this.props.classes.input } }}

                                />
                            </Grid>

                        </Grid>
                        <ScrollMenu
                            LeftArrow={null}
                            RightArrow={null}
                        /* onWheel={onWheel} */
                        /* onMouseDown={() => dragStart}
                        onMouseUp={() => dragStop}
                        onMouseMove={handleDrag} */
                        >
                            {this.state.imagesUrls.map((item, index) => {
                                return (
                                    <img onClick={this.handleOnClickdownloadImage.bind(this.src)} key={index} style={{
                                        float: "left",
                                        width: "150px",
                                        height: "150px",
                                        objectFit: "cover",
                                        marginLeft: index > 0 ? "15px" : "0px",
                                        cursor: "pointer"
                                    }} src={item} alt={index} />

                                )
                            })}



                        </ScrollMenu>
                        <Divider style={{ marginBottom: "20px", marginTop: "20px" }} />

                        <Grid container style={{ flexGrow: 1, marginTop: "10px", marginBottom: "10px" }} spacing={2}>
                            <Grid item xs={12} md={6} lg={6}>
                                {this.props.rowSelected.tipoCRM === "" ?
                                    <React.Fragment>
                                        <InputLabel shrink>
                                            Tipos
                                        </InputLabel>
                                        <Select
                                            value={this.state.OptionTypeIncidenceSelected}
                                            onChange={this.handleOnChangeTypes}
                                            placeholder={"Tipos"}
                                            options={this.state.groupOptionsTypes}
                                            isClearable={true}
                                            isSearchable={true}
                                            hideSelectedOptions={false}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#BF2349',
                                                },
                                            })}
                                        />

                                    </React.Fragment>
                                    :
                                    this.props.rowSelected.tipoCRM !== null &&
                                    <React.Fragment>

                                        <InputLabel shrink>
                                            Tipos
                                        </InputLabel>
                                        <TextField
                                            variant="outlined"
                                            size='small'
                                            style={{ width: "100%" }}
                                            disabled={true}
                                            value={this.props.rowSelected.tipoCRM}
                                        />
                                    </React.Fragment>

                                }

                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>


                                {this.props.rowSelected.subTipoCRM === "" ?
                                    <React.Fragment>
                                        <InputLabel shrink>
                                            Subtipos
                                        </InputLabel>
                                        <Select
                                            value={this.state.OptionSubTypeIncidenceSelected}
                                            onChange={this.handleOnChangeSubTypes}
                                            // isMulti
                                            placeholder={"Subtipos"}
                                            options={this.state.groupOptionsSubType}
                                            formatGroupLabel={this.formatGroupLabel}
                                            isClearable={true}
                                            isSearchable={true}
                                            hideSelectedOptions={false}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#BF2349',
                                                },
                                            })}

                                        />

                                    </React.Fragment>
                                    :
                                    this.props.rowSelected.subTipoCRM !== null &&
                                    <React.Fragment>

                                        <InputLabel shrink>
                                            Subtipos
                                        </InputLabel>
                                        <TextField
                                            variant="outlined"
                                            size='small'
                                            style={{ width: "100%" }}
                                            disabled={true}
                                            value={this.props.rowSelected.subTipoCRM}
                                        />
                                    </React.Fragment>
                                }
                            </Grid>
                        </Grid>
                        <Grid container style={{ flexGrow: 1, marginTop: "10px", marginBottom: "10px" }} spacing={2}>

                        </Grid>

                        <Grid container style={{ flexGrow: 1, marginTop: "20px", textAlign: "center" }} spacing={2} >
                            {this.props.rowSelected.estado === "Abierta" &&
                                <React.Fragment>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Button variant="outlined" size="medium" fullWidth={true} onClick={() => this.showConfirmDialog(2)}>
                                            Enviar a CRM
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Button variant="outlined" size="medium" fullWidth={true} onClick={() => this.showConfirmDialog(1)}>
                                            Desestimar
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                            }

                            <Grid item xs={12} md={4} lg={4}>
                                <Button variant="outlined" size="medium" onClick={this.handleOnCancel} fullWidth={true}>
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    {
                        this.state.isLoading &&
                        <Backdrop style={{
                            zIndex: 1000,
                            color: '#fff'
                        }} open={this.state.isLoading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    }

                </Dialog>
                {
                    this.state.openAlert &&
                    <Dialog
                        open={this.state.openAlert}
                        // onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Aviso"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Debe rellenar el tipo y el subtipo para continuar.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleOnCloseAlert} color="primary" autoFocus>
                                Aceptar
                            </Button>
                        </DialogActions>
                    </Dialog>
                }

                {
                    this.state.openAlertConfirm &&
                    <Dialog
                        open={this.state.openAlertConfirm}
                        // onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Aviso"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.messageConfirm}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleButtonConfirmDialog(0)} color="primary" autoFocus>
                                Cancelar
                            </Button>
                            <Button onClick={() => this.handleButtonConfirmDialog(1)} color="primary" autoFocus>
                                Aceptar
                            </Button>
                        </DialogActions>
                    </Dialog>
                }

            </div >
        )
    }
}


export default withStyles(styles)(IncidenceInfoDialog)
