import React, { Component } from 'react';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         width: '100%',
//         '& > * + *': {
//             marginTop: theme.spacing(2),
//         },
//     },
// }));

class AlertStatus extends Component {

    // changeVisibilityAlertStatus = () => {
    //     this.setState({
    //         visible: !this.state.visible
    //     });
    // }
    render() {
        return (
            <div>
                <Collapse in={this.props.visibleAlert}>
                    <Alert severity={this.props.statusText}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={this.props.changeVisibilityAlertStatus.bind(this)}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        
                        {/* <p style={{fontSize: "16px"}}> {this.props.statusMessage !== undefined ? this.props.statusMessage.map((element)  => element[0] ) : null} {this.props.statusNumPedido !== undefined ? this.props.statusNumPedido.map((element, i) => element[0].Information + ", ") : null} </p>  */}
                        <p style={{fontSize: "16px"}}> {this.props.statusMessage !== undefined ? this.props.statusMessage[0] !== undefined ? this.props.statusMessage[0] : null : null} {this.props.statusNumPedido !== undefined ? this.props.statusNumPedido[0] !== undefined ? this.props.statusNumPedido[0].map((element, i) => element.Information + ", ") : null : null} </p> 
                        <p style={{fontSize: "16px"}}> {this.props.statusMessage !== undefined ? this.props.statusMessage[1] !== undefined ? this.props.statusMessage[1] : null : null} {this.props.statusNumPedido !== undefined ? this.props.statusNumPedido[1] !== undefined ? this.props.statusNumPedido[1].map((element, i) => element.Information + ", ") : null : null} </p> 
                        <p style={{fontSize: "16px"}}> {this.props.statusMessage !== undefined ? this.props.statusMessage[2] !== undefined ? this.props.statusMessage[2] : null : null} {this.props.statusNumPedido !== undefined ? this.props.statusNumPedido[2] !== undefined ? this.props.statusNumPedido[2].map((element, i) => element.Information + ", ") : null : null} </p> 
                        <p style={{fontSize: "16px"}}> {this.props.statusMessage !== undefined ? this.props.statusMessage[3] !== undefined ? this.props.statusMessage[3] : null : null} {this.props.statusNumPedido !== undefined ? this.props.statusNumPedido[3] !== undefined ? this.props.statusNumPedido[3].map((element, i) => element.Information + ", ") : null : null} </p> 
                        <p style={{fontSize: "16px"}}> {this.props.statusMessage !== undefined ? this.props.statusMessage[4] !== undefined ? this.props.statusMessage[4] : null : null} {this.props.statusNumPedido !== undefined ? this.props.statusNumPedido[4] !== undefined ? this.props.statusNumPedido[4].map((element, i) => element.Information + ", ") : null : null} </p> 
                        <p style={{fontSize: "16px"}}> {this.props.statusMessage !== undefined ? this.props.statusMessage[5] !== undefined ? this.props.statusMessage[5] : null : null} {this.props.statusNumPedido !== undefined ? this.props.statusNumPedido[5] !== undefined ? this.props.statusNumPedido[5].map((element, i) => element.Information + ", ") : null : null} </p> 
                    </Alert>
                </Collapse>
            </div>
        )
    }
}

export default AlertStatus;
