import React, { useState, useEffect, Suspense } from "react";
// import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import { BeatLoader } from 'react-spinners';
import { BrowserRouter } from 'react-router-dom';
import "./App.css";
import { Auth } from "aws-amplify";
import "./modules/users/login/SignIn.css";
import "./modules/users/login/SignInResponsive.css";
import "../src/modules/menu/Menu.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { esES } from '@material-ui/core/locale';

const theme = createTheme({
  typography: {
    htmlFontSize: 10
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          fontSize: "62.5%"
        }
      }
    }
  }
}, esES);

const spinnerCss = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  marginLeft: '-30px'
}
function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch (e) {
      if (e !== 'No current user') {
      }
    }
  }

  // const Loader = () => (
  //   <div style={{ margin: "auto" }}>
  //     {/* <div className='pin bounce'></div>
  //     <div className='pulse'></div>
  //     <img className="bounceImg imgLogo" src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" alt="" /> */}
  //   </div>
  // );



  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div id="wrapper" className="wrapper" style={{ height: "100%", width: "100%" }}>
        <Suspense fallback={<BeatLoader
          css={spinnerCss}
          size={15}
          margin={4}
          color={"#bf2349"}
        />}>
          <BrowserRouter>
            <Routes appProps={{ isAuthenticated, userHasAuthenticated, logout: false }} />
          </BrowserRouter>
        </Suspense>
      </div>
    </ThemeProvider>

  );
}
export default App;
