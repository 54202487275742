import React, { Component } from 'react';
import config from '../../config';
import { Modal, Backdrop, Fade } from '@material-ui/core/';
import { TextField } from '@material-ui/core/';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Select, { components } from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import BeatLoader from 'react-spinners/BeatLoader';
// import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';

import "react-datepicker/dist/react-datepicker.css";

import { withStyles } from "@material-ui/core/styles";

import { isSameDay, startOfToday } from 'date-fns';


const styles = theme => ({
  rootList: {
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 100,
    border: "1px solid #D3D3D3",
    borderRadius: "5px"
  },
  rootList2: {
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 100,
    border: "1px solid #D3D3D3",
    borderRadius: "5px",
    padding: 21,
    paddingLeft: 0
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  root: {
    flexGrow: 1,
    minWidth: "50%",
    // '& .MuiTextField-root': {
    //     margin: theme.spacing(1),
    //     width: '25ch',
    // },
    margin: "auto",
    padding: "15px",
    overflow: "scroll"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  containerTextField: {
    '& .MuiTextField-root': {
      // margin: theme.spacing(1),
      width: "100%",

    },
    '& .MuiInputBase-root': {
      fontSize: "14px"
    },
    '& .MuiInputBase-input': {
      height: "0.7rem"
    }
  },
  containerTextFieldArea: {
    '& .MuiTextField-root': {
      // margin: theme.spacing(1),
      width: "100%",

    },
    '& .MuiInputBase-root': {
      fontSize: "14px"
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        fontSize: "20px"
      },
      input: {
        padding: "16px 14px",
        height: 56,
        boxSizing: "border-box"
      }
    },
    '& .MuiInputLabel': {
      root: {
        fontSize: "20px"
      },
      outlined: {
        transform: "translate(14px, 19px) scale(1)"
      }
    }
  }
});

const spinnerCss = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  marginLeft: '-30px'
}

const groupStyles = {

  color: '#fff',
  background: "#bf2349",
  padding: '5px 0px',
  display: 'flex',
};

const options = [
  { value: '2', label: 'Sonda temperatura' },
  { value: '1', label: 'Sensor' }
];

const GroupHeading = props => (
  <div style={{ ...groupStyles, display: 'flex', alignItems: 'center' }}>
    <components.GroupHeading {...props} />
    <Tooltip title={"groupIcon"} >
      <GroupOutlinedIcon styles={{ color: "#fff", fontSize: "12px" }} />
    </Tooltip>
  </div>
);

class ModalView extends Component {
  state = {
    isLoading: true,
    selectedObj: {},
    selectedDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    maxDate: this.addDaysToDateMax.bind(this),
    email: "",
    accessToken: "",
    groupedOptions: [],

    errorEmail: false,
    errorEmailText: "",
    errorDateStart: false,
    errorDateTextStart: "",
    errorDateEnd: false,
    errorDateTextEnd: "",
    selectedVehicles: null,
    description: "",
    marginTopTextArea: "30px",

    dateAndHourStart: new Date(),
    dateAndhourEnd: new Date(),

    listVehiclesNames: [],

    maxMinTimeSelect: "",
    minTime: isSameDay(new Date(), new Date()) ? new Date() : startOfToday(),
    boolDisabled: true,
    isEmpty: false,
    selectedValue: "email",
    groupsToNewAccess: [],
    groupSelectedToNewAccess: [],
    listGroupName: [],
    selectedOptionType: null
  }

  async componentDidMount() {
    document.getElementById("pin").style.display = "none";
    let isEmpty = this.isEmpty(this.props.selectedObj);


    let listGroupWithVehicles = [];
    let listGroupName = [];
    let selectedOptionType = null;
    this.setState({
      isLoading: false,
      selectedObj: this.props.selectedObj
    });

    if (!isEmpty) {

      const groupTags = [];
      const listGroupName = [];
      const listGroupWithVehicles = [];
      let selectedVehicle = null;

      this.props.assetsTags.forEach(assetTag => {
        const clonedAssetTag = { ...assetTag }; // Crear copias de objetos
        clonedAssetTag.assetsTagInfo = [];

        this.props.assetsInfo.forEach(assetInfo => {
          if (assetInfo.assetsTagsId === clonedAssetTag.assetsTagsId) {
            clonedAssetTag.assetsTagInfo.push({
              value: assetInfo.assetsInfoId, label: assetInfo.assetsInfoName
            });
          }

          // Verificar si este vehículo es el que queremos seleccionar
          if (assetInfo.assetsInfoId === parseInt(this.props.selectedObj[0].id.split("-")[1])) {
            selectedVehicle = { value: assetInfo.assetsInfoId, label: assetInfo.assetsInfoName };
          }
        });

        groupTags.push({
          "label": clonedAssetTag.assetsTagName,
          "options": [...clonedAssetTag.assetsTagInfo] // Crear copias de arrays
        });

        selectedOptionType = this.props.selectedObj[0].tipo.includes("Sonda") ? options[0] : options[1];

      });
      
      const groups = this.props.groupsNewAccessList;


      // Actualizar el estado con las copias de los datos
      this.setState({
        groupedOptions: [...groupTags], // Crear copia de array
        isLoading: false,
        marginTopTextArea: "45px",
        listVehiclesNames: listGroupWithVehicles,
        isEmpty: false,
        groupsToNewAccess: groups,
        listGroupName: listGroupName,
        selectedVehicles: selectedVehicle,
        email: this.props.selectedObj[0].sonda,
        boolDisabled: false,
        selectedOptionType: selectedOptionType

      });
    } else {
      const groupTags = [];

      this.props.assetsTags.forEach(assetTag => {
        const clonedAssetTag = { ...assetTag }; // Crear copias de objetos
        clonedAssetTag.assetsTagInfo = [];

        this.props.assetsInfo.forEach(assetInfo => {
          if (assetInfo.assetsTagsId === clonedAssetTag.assetsTagsId) {
            clonedAssetTag.assetsTagInfo.push({
              value: assetInfo.assetsInfoId, label: assetInfo.assetsInfoName
            });
          }
        });

        groupTags.push({
          "label": clonedAssetTag.assetsTagName,
          "options": [...clonedAssetTag.assetsTagInfo] // Crear copias de arrays
        });
      });
      const groups = this.props.groupsNewAccessList;

      // Actualizar el estado con las copias de los datos
      this.setState({
        groupedOptions: [...groupTags], // Crear copia de array
        isLoading: false,
        marginTopTextArea: "45px",
        listVehiclesNames: listGroupWithVehicles,
        isEmpty: false,
        groupsToNewAccess: groups,
        listGroupName: listGroupName,
      });
    }
  }

  async getAssetsTags() {

    let responseGroups = await fetch(`${config.apiGateway.URL}AssetsTags`, {
      headers: {
        'accessToken': this.props.accessToken
      }
    });

    if (responseGroups.ok) { // if HTTP-status is 200-299
      let jsonGroups = await responseGroups.json();
      // localStorage.setItem("jsonGroups", JSON.stringify(jsonGroups));
      return jsonGroups;

    } else {
      alert("HTTP-Error: " + responseGroups.status);
      return;
    }
  }

  async getAssetsInfo() {
    let responseInfo = await fetch(`${config.apiGateway.URL}AssetsInfo`, {
      headers: {
        'accessToken': this.props.accessToken
      }
    });

    if (responseInfo.ok) {
      let jsonInfo = await responseInfo.json();
      localStorage.setItem("jsonInfo", JSON.stringify(jsonInfo));
      // console.log(jsonInfo);
      return jsonInfo;

    } else {
      alert("HTTP-Error: " + responseInfo.status);
      return;
    }
  }

  formatDateAndHour(dateObj) {
    let dateAndHour = dateObj.split(" ");
    let date = dateAndHour[0].split("/");
    date = date[1] + "/" + date[0] + "/" + date[2];

    let formatDateAndHour = date + " " + dateAndHour[1];
    let resultFormatDateAndHour = new Date(formatDateAndHour);

    return resultFormatDateAndHour;
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    let day = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
    let month = ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1));
    // var ampm = hours >= 12 ? 'pm' : 'am';
    // hours = hours % 12;
    hours = hours === 0 ? hours += "0" : hours;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + '00';
    // return date.getFullYear() + "-" + month + "-" + day + " " + strTime
    return date.getFullYear() + "-" + month + "-" + day + " " + strTime
  }

  handleDateStart(e) {
    let boolDisabled = true;
    // if (this.state.email !== "" && this.state.selectedVehicles.length > 0 && this.validateDateEnd() === true) {
    //     boolDisabled = false
    // } else {
    //     boolDisabled = true;
    // }


    this.setState({ startDate: e, minTime: e, endDate: e, maxDate: e }, () => {
      if (this.state.selectedValue === "email") {
        if (this.state.email !== "" && this.state.selectedVehicles.length > 0 && !this.validateDateStart()) {
          boolDisabled = false
        } else {
          boolDisabled = true;
        }
      } else {

        if (!this.isEmpty(this.state.groupSelectedToNewAccess.length) && this.state.selectedVehicles.length > 0 && !this.validateDateStart()) {
          boolDisabled = false
        } else {
          boolDisabled = true;
        }
      }

      this.setState({ boolDisabled: boolDisabled });
    });
  }

  handleDateEnd(e) {

    let endDate = e;
    let startDate = this.state.startDate;

    let boolDisabled = true;


    let newEndDate = "";
    // console.log(e);

    if (endDate > startDate) {
      newEndDate = startOfToday();
    } else if (isSameDay(new Date(), new Date())) {
      newEndDate = this.state.startDate;
    }
    // console.log(newEndDate);

    this.setState({ endDate: e, minTime: newEndDate, boolDisabled: boolDisabled }, () => {

      if (this.state.selectedValue === "email") {
        if (this.state.email !== "" && this.state.selectedVehicles.length > 0 && this.state.endDate >= this.state.startDate) {
          boolDisabled = false
        } else {
          boolDisabled = true;
        }
      } else {
        if (!this.isEmpty(this.state.groupSelectedToNewAccess.length) && this.state.selectedVehicles.length > 0 && this.state.endDate >= this.state.startDate) {
          boolDisabled = false
        } else {
          boolDisabled = true;
        }

      }
      this.setState({ boolDisabled: boolDisabled });
    });
  }

  handleOnclickAcceptNewAccess = () => {
    // console.log("entra");
    this.validateData();
    // this.props.onClickHandleModalView();
  }

  handleOnclicCancelViewAccess = () => {
    this.props.onClickHandleModalView();
  }

  handleOnChangeEmail = (e) => {
    e.target.value = e.target.value.toUpperCase();
    let boolDisabled = true;
    if (e.target.value.length <= 0) {
      boolDisabled = true;
      this.setState({ email: e.target.value, boolDisabled: boolDisabled });
      return;
    }
    if (this.validateEmail(e.target.value) && this.state.selectedVehicles !== null && this.state.selectedOptionType !== null) {
      boolDisabled = false
    } else {
      boolDisabled = true;
    }
   
    this.setState({
      boolDisabled: boolDisabled,
      email: e.target.value
    });
  }

  handleOnChangeSelectGroupNewAccess = (newValue) => {
    this.setState({
      groupSelectedToNewAccess: newValue
    })
  }

  handleOnchangeSelectVehices = (newValue) => {
    let boolDisabled = true;
    if (this.validateEmail(this.state.email) && Object.keys(newValue).length > 0 && this.state.selectedOptionType !== null) {
      boolDisabled = false
    } else {
      boolDisabled = true;
    }

    this.setState({
      selectedVehicles: newValue,
      boolDisabled: boolDisabled
    });

  }

  handleOnChangeDescription = (e) => {
    this.setState({
      description: e.target.value
    });
  }

  validateData() {
    if (this.state.selectedValue === "email") {
      var error = "";
      if (!this.validateEmail(this.state.email)) {
        error = "Su sonda no tiene el formato correcto.";

        this.setState({
          errorEmailText: error,
          errorEmail: true
        });
        return;
      }

      this.setState({
        errorEmailText: "",
        errorEmail: false
      });
    }
    if (this.props.selectedObj.length > 0) {
      this.editData();
    } else {
      this.saveData();
    }

  }

  validateDateStart() {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;

    if (endDate > startDate) {
      return true;
    } else if (endDate === startDate) {
      return true;
    } else {
      return false;
    }
  }

  validateDateEnd() {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if (endDate > startDate) {
      return true;
    } else {
      return false;
    }
  }

  validateEmail(email) {
    const re = /^[a-zA-Z0-9]{12}$/;
    return re.test(String(email));
  }

  async editData() {

    if (this.state.email === this.props.selectedObj[0].sonda) {
      this.props.onClickHandleModalView();
      return;
    }

    let objectToSave = {
      sondaPrevia: this.props.selectedObj[0].sonda,
      sondaNueva: this.state.email,
      idVehiculo: this.state.selectedVehicles.value,
      tipo: parseInt(this.state.selectedOptionType.value)
    };


    const settings = {
      method: 'PUT',
      headers: {
        'accessToken': this.props.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(objectToSave)

      // `[${newArrayBody.map(service => '[' + service + ']')}]`
    };


    const fetchResponse = await fetch(`${config.apiGateway.URL}Sensors`, settings);
    if (fetchResponse.ok) {
      if (fetchResponse !== null) {
        try {
          await fetchResponse.json();
          let statusResult = {
            statusText: "success",
            statusMessage: ["Sensor editado correctamente."],
            statusNumPedido: []
          }

          this.props.handleOnCloseModalAndShowAlert(statusResult);
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      // La respuesta no es exitosa
      if (fetchResponse.status === 400) {
        // Manejar el bad request aquí
        alert(await fetchResponse.json());
      } else {
        // Manejar otros códigos de estado aquí
        console.log('Otro error:', fetchResponse.status);
      }
    }
  }

  async saveData() {
    let objectToSave = {};
    if (this.state.selectedValue === "email") {
      objectToSave = {
        sonda: this.state.email,
        idVehiculo: this.state.selectedVehicles.value,
        tipo: parseInt(this.state.selectedOptionType.value)
      };
    } else {
      objectToSave = {
        sonda: this.state.email,
        idVehiculo: this.state.selectedVehicles.value,
        tipo: parseInt(this.state.selectedOptionType.value)
      };
    }

    const settings = {
      method: 'POST',
      headers: {
        'accessToken': this.props.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(objectToSave)

      // `[${newArrayBody.map(service => '[' + service + ']')}]`
    };



    const fetchResponse = await fetch(`${config.apiGateway.URL}Sensors`, settings);
    if (fetchResponse.ok) {
      if (fetchResponse !== null) {
        try {
          await fetchResponse.json();
          let statusResult = {
            statusText: "success",
            statusMessage: ["Sensor asignado correctamente."],
            statusNumPedido: []
          }

          this.props.handleOnCloseModalAndShowAlert(statusResult);
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      // La respuesta no es exitosa
      if (fetchResponse.status === 400) {
        // Manejar el bad request aquí
        alert(await fetchResponse.json());
      } else {
        // Manejar otros códigos de estado aquí
        console.log('Otro error:', fetchResponse.status);
      }
    }
  }

  maxMinTime() {
    // let r = ((new Date(), 30), 17);
    this.setState({
      dateAndhourEnd: ((new Date(), 30), 17)
    });
  }

  goToPageErr = async () => {
    // this.props.userHasAuthenticated(false);
    // console.log("entra");
    this.props.history.push({
      pathname: '/ExpireKeyErr/',
      state: {
        userDataInfo: '',
      }
    });
  }

  validateDataDisabled() {
    let bool = false;
    if (this.state.selectedVehicles.length < 0 && this.state.email === "" && this.validateDateEnd() !== false) {
      bool = false;

    } else if (this.state.selectedVehicles.length > 0 && this.state.email !== "" && this.validateDateEnd() === false) {
      bool = true;
    }
  }

  addDaysToDateMax() {

    let starDate = this.state.startDate;
    // Milisegundos de 3 días mas 
    // (días * 24 horas * 60 minutos * 60 segundos * 1000 milésimas de segundo) 
    let dateWithFourDaysMore = starDate.getTime() + (4 * 24 * 60 * 60 * 1000);
    let dateWithFourDaysMoreFormated = new Date(dateWithFourDaysMore);
    return dateWithFourDaysMoreFormated;
  }

  handleOnChangeRadioButtons(e) {
    this.setState({
      selectedValue: e.target.value
    });
  }

  handleChangeType = selectedOptionType => {
    let boolDisabled = true;

    if (this.validateEmail(this.state.email) && this.state.selectedVehicles !== null) {
      boolDisabled = false
    } else {
      boolDisabled = true;
    }
    this.setState({ selectedOptionType, boolDisabled});
    
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    // if (this.state.email === "") {
    //     //     boolDisabled = true;
    // } else if(this.state.selectedVehicles.length === 0){
    //     boolDisabled = true;
    // } else if(this.validateDateEnd() === false){
    //     boolDisabled = true;
    // } else {
    //     boolDisabled = false;
    // }

    return (

      <div className={classes.root} >
        {this.state.isLoading ?
          <div className="loadingSpinner">
            <BeatLoader css={spinnerCss} size={15} margin={4} color={"#bf2349"} />
          </div>
          :

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modalCustom"
            open={this.props.modalViewShow}
            style={{ overflow: "scroll" }}
            onClose={this.props.onClickHandleModalView.bind(this)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}>
            <Fade id="fade" in={this.props.modalViewShow}>

              <Grid style={{ background: "#fff", padding: "15px", paddingBottom: "30px", margin: "auto !important" }} container spacing={2}>
                <Grid item xs={12} md={12}>
                  {this.props.selectedObj.length > 0 ?
                    <div className={classes.paper}>Editar Asignación</div>
                    :
                    <div className={classes.paper}>Nueva Asignación</div>
                  }

                </Grid>
                <Grid item xs={12} md={12}>
                  {
                    !this.isEmpty(this.props.selectedObj) ?
                      <label style={{ color: "#000", padding: "0px !important" }} className="labelModalView labelSelect">Tipo</label>
                      :
                      <label style={{ color: "#000", padding: "0px !important" }} className="labelModalView labelSelect">Seleccionar tipo</label>
                  }

                  <Select
                   value={this.state.selectedOptionType}
                   onChange={this.handleChangeType.bind(this)}
                   options={options}
                   placeholder="Selecciona una opción"
                    styles={{
                      control: base => ({
                        ...base,
                        height: 44,
                        minHeight: 44
                      }),
                    }}
                    isDisabled={this.props.selectedObj.length > 0 ? true : false}

                  />

                </Grid>

                <Grid className={classes.containerTextField} item xs={12} md={6}>

                  <div>
                    <label className="labelModalView">Sonda</label>
                    <TextField
                      // style={{ width: "100%", height: "49px" }}

                      placeholder="Sonda"
                      variant="outlined"
                      size="medium"

                      value={this.state.email}
                      //disabled={this.props.selectedObj.length > 0 ? true : false}
                      onChange={this.handleOnChangeEmail.bind(this)}
                      error={this.state.errorEmail}
                      id="outlined-error"
                      helperText={this.state.errorEmailText}
                      required
                      margin="none"
                      inputProps={{ maxLength: 12 }}
                    />
                  </div>


                </Grid>

                <Grid item xs={12} md={6}>
                  {
                    !this.isEmpty(this.props.selectedObj) ?
                      <label style={{ color: "#000", padding: "0px !important" }} className="labelModalView labelSelect">{"Vehículo"}</label>
                      :
                      <label style={{ color: "#000", padding: "0px !important" }} className="labelModalView labelSelect">{t('modalView.listVehicles')}</label>
                  }

                  <Select
                    // defaultValue={this.state.colourOptions[1]}

                    options={this.state.groupedOptions}
                    components={{ GroupHeading }}
                    styles={{
                      control: base => ({
                        ...base,
                        height: 44,
                        minHeight: 44
                      }),
                      groupHeading: base => ({
                        ...base,
                        flex: '1 1',
                        background: 'transparent',
                        color: '#ffffff',
                        margin: 0,
                        alignItems: "center",
                        fontWeight: 'bold',
                        justifyContent: "center"
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? 'gray' : provided.backgroundColor,
                        color: state.isSelected ? '#fff' : provided.color,
                      }),
                      ValueContainer
                    }}
                    onChange={this.handleOnchangeSelectVehices.bind(this)}
                    placeholder={t('modalView.listVehicles')}
                    value={this.state.selectedVehicles}
                    isSearchable={true}
                    isDisabled={this.props.selectedObj.length > 0 ? true : false}

                  />

                </Grid>



                <Grid item xs={12} md={6}>
                  <button onClick={this.handleOnclicCancelViewAccess.bind(this)} className="btn btn-default btnTemporalAccess">{t('modalView.btnCancel')}</button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <button onClick={this.handleOnclickAcceptNewAccess.bind(this)} disabled={this.state.boolDisabled} className="btn btn-default btnTemporalAccess">{t('modalView.btnAccept')}</button>
                </Grid>
              </Grid>
            </Fade>
          </Modal>
        }
      </div>
    )
  }
}

const ValueContainer = ({ children, getValue, ...props }) => {
  let maxToShow = 2;
  var length = getValue().length;
  let displayChips = React.Children.toArray(children).slice(0, maxToShow);
  let title = getValue()
    .slice(maxToShow)
    .map((x) => x.label)
    .join(", ");
  let shouldBadgeShow = length > maxToShow;
  let displayLength = length - maxToShow;
  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && displayChips}
      {!!shouldBadgeShow && (
        <div className="root" title={title}>
          {/* {`+ ${displayLength} Opcione${length !== 1 ? "s" : ""} seleccionadas`} */}
          {`+ ${displayLength} `}
        </div>
      )}
    </components.ValueContainer>
  );
};
export default withStyles(styles, { withTheme: true })(ModalView);
// export default ModalView;
