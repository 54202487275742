import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Link, } from "react-tiger-transition";
import DialogComment from './Dialogs/DialogComment';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';


const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,

  },
  pos: {
    marginBottom: 0,
  },
});

function TaskOrderDetail(props) {
  const classes = useStyles();
  const [openDialogComment, setOpenDialogComment] = React.useState(false);
  const selectedOt = JSON.parse(localStorage.getItem("selectedOt"));


  // if (props.selectedOt === false) {
  //   props.selectedOt = props.location.selectedOt;
  // }

  // if (props.location.state === undefined) {
  //   const userDataInfo = localStorage.getItem("userDataInfo");
  //   props.history.replace({
  //     ...props.history.location,
  //     state: {
  //       ...props.history.location.state,
  //       userDataInfo: JSON.parse(userDataInfo),
  //     },
  //   });
  // }
  if (props.location.status !== undefined) {
    props.location.status = undefined;
  }

  //const selectedOt = props.selectedOt === false ? props.location.selectedOt : props.selectedOt;
  const handleCloseDialogComment = () => {
    setOpenDialogComment(false);
  }
  const handleClickOpenDialogComment = () => {
    setOpenDialogComment(true);
  }
  return (
    <div>
      <List style={{ overflowY: "hidden" }} subheader={<ListSubheader>
        {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}

        <ListItem button component={Link} transition='glide-right' to={{
          pathname: '/dashboard/ordenes-trabajo/',

        }} >
          <ListItemIcon>

            <ArrowBackIosRoundedIcon />
          </ListItemIcon>
          <ListItemIcon>

            <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" />

          </ListItemIcon>

        </ListItem>
        <p style={{ position: "absolute", top: "13px", right: "22px" }}> {selectedOt.NumPurchaseOrder}</p>

      </ListSubheader>
      } className={classes.root2}>
      </List>
      <Button component={Link} transition='glide-left' to={{
        pathname: '/dashboard/ordenes-trabajo/detail/Activity',

      }} size="small" color='primary'>Actividades</Button>
      <Button size="small" color='primary' onClick={handleClickOpenDialogComment}>Comentario</Button>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} >
            <label>Asunto:</label> {selectedOt.Subject}
          </Typography>
          <Typography >
            <label>Nombre Proveedor:</label> {selectedOt.ProviderName}
          </Typography>
          <Typography className={classes.pos} >
            <label>Fecha Vencimiento:</label> {selectedOt.ExpirationDate}
          </Typography>

        </CardContent>
      </Card>
      <Card style={{ marginTop: 20 }} className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} >
            <label>Persona de contacto instalaciones:</label> {selectedOt.ContactFacilities}
          </Typography>
          <Typography variant="body2" component="p">
            <label>Teléfono Contacto:</label> {selectedOt.PhoneContact}
          </Typography>
          <Typography className={classes.title} >
            <label>Dirección (Envío):</label> {selectedOt.ShipingAddress}
          </Typography>
          <Typography >
            <label>Provincia (Envío):</label> {selectedOt.ShipingProvince}
          </Typography>
          <Typography className={classes.pos} >
            <label>Número orden compra:</label> {selectedOt.NumPurchaseOrder}
          </Typography>
        </CardContent>
      </Card>
      <Card style={{ marginTop: 20 }} className={classes.root} variant="outlined">
        <CardContent>
          <div className={classes.title} >
            <p style={{ fontWeight: "bold" }}>Descripción</p>
            {selectedOt.Description}
          </div>
        </CardContent>
      </Card>
      {openDialogComment &&
        <DialogComment {...props} selectedOT={selectedOt} handleCloseDialogComment={handleCloseDialogComment} openDialogComment={openDialogComment} handleClickOpenDialogComment={handleClickOpenDialogComment} />
      }

    </div>

  )
}

export default TaskOrderDetail