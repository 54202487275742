import React, { Component } from 'react';
import styled from 'styled-components';
import HeadersTableList from './HeadersTableList';
import { Droppable } from 'react-beautiful-dnd';

const Container = styled.div`
    margin: 8px;
    border: 1px solid lightgrey;
    border-radius: 2px;
    
`

const Title = styled.h3`
    padding 8px;
    font-weight: bold;
    font-family: "roboto";
    font-size: 20px;
    text-align: center;
`

const HeaderContainerList = styled.div`
    padding 8px;
    
    
`
// display: grid;
//     grid-template-columns: 1fr 1fr;
export default class Column extends Component {
    render() {
        return (
            <Container>
                <Title>{this.props.column.title}</Title>
                <Droppable droppableId={this.props.column.id}
                    >
                    {(provided, snapshot) => {
                        return (
                            <HeaderContainerList
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                
                                style={{
                                    background: snapshot.isDraggingOver
                                        ? "#e6e6e6"
                                        : "white",
                                    padding: 4,
                                    width: 250,
                                    minHeight: "92.5%",
                                    zIndex: 1
                                }}
                            >
                                {this.props.headers.map((header, index) => <HeadersTableList key={header.id} headers={header} index={index} />)}
                                {provided.placeholder}
                            </HeaderContainerList>
                        )

                    }}

                </Droppable>


            </Container>
        )
    }
}
